import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import {
  ChangeComponentGlobalData,
  ChangeComponentIntegrationId,
  ChangeDirectoryContent,
  ChangePageItemGlobalData,
  ChangeWebsitePagesStateGlobalData,
  EditApiSourceValidationData,
  ReplaceTemplateComponentsWithEmptyComponents,
  SavePageComponentsInState,
  SetCustomBackgroundColor,
  SwitchComponent,
  ToggleIsPageEmpty,
} from "./types";
import { toggleLoadedWebsitesData } from "../websites/actions";
import { WebsiteDataStatus } from "../../enums/WebsiteDataStatus";
import { LoadStatus } from "../../enums/enums";

const actionsNames = {
  SAVE_WEBSITES_PAGES_IN_STATE: "SAVE_WEBSITES_PAGES_IN_STATE" as string,
  SAVE_PAGE_COMPONENTS_IN_STATE: "SAVE_PAGE_COMPONENTS_IN_STATE",
  ALL_WEBSITES_PAGES_DATA_FETCHED: "ALL_WEBSITES_PAGES_DATA_FETCHED" as string,
  WEBSITES_PAGES_DATA_WITHOUT_COMPONENTS_FETCHED:
    "WEBSITES_PAGES_DATA_WITHOUT_COMPONENTS_FETCHED",
  TOGGLE_WEBSITE_PAGES_CHANGE_IN_PROGRESS: "TOGGLE_WEBSITE_PAGES_CHANGE_IN_PROGRESS" as string,
  REPLACE_WEBSITES_PAGES_IN_STATE: "REPLACE_WEBSITES_PAGES_IN_STATE" as string,
  INCREMENT_REQUESTS_COUNT: "INCREMENT_REQUESTS_COUNT" as string,

  PAGE_URL_UPDATE_WAITING_FOR_SERVER: "PAGE_URL_UPDATE_WAITING_FOR_SERVER" as string,
  PAGE_URL_UPDATE_TOGGLE_INVALID_UPDATE: "PAGE_URL_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  PAGE_URL_UPDATE_ERROR_MESSAGE: "PAGE_URL_UPDATE_ERROR_MESSAGE" as string,

  PAGE_META_TITLE_UPDATE_WAITING_FOR_SERVER: "PAGE_META_TITLE_UPDATE_WAITING_FOR_SERVER" as string,
  PAGE_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE: "PAGE_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  PAGE_META_TITLE_UPDATE_ERROR_MESSAGE: "PAGE_META_TITLE_UPDATE_ERROR_MESSAGE" as string,

  PAGE_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER: "PAGE_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER" as string,
  PAGE_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE: "PAGE_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  PAGE_META_DESCRIPTION_UPDATE_ERROR_MESSAGE: "PAGE_META_DESCRIPTION_UPDATE_ERROR_MESSAGE" as string,

  PUBLISH_PAGE_TOGGLE_WAITING_FOR_SERVER: "PUBLISH_PAGE_TOGGLE_WAITING_FOR_SERVER" as string,

  CREATE_WEBSITE_PAGE_WAITING_FOR_SERVER: "CREATE_WEBSITE_PAGE_WAITING_FOR_SERVER" as string,
  CREATE_WEBSITE_PAGE_ERROR_MESSAGE: "CREATE_WEBSITE_PAGE_ERROR_MESSAGE" as string,

  CLEAR_WEBSITES_PAGES: "CLEAR_WEBSITES_PAGES" as string,

  DELETE_WEBSITE_PAGE_WAITING_FOR_SERVER: "DELETE_WEBSITE_PAGE_WAITING_FOR_SERVER" as string,

  REMOVE_WEBSITE_PAGES_FROM_STORE: "REMOVE_WEBSITE_PAGES_FROM_STORE" as string,

  REMOVE_SINGLE_WEBSITE_PAGE_FROM_STORE: "REMOVE_SINGLE_WEBSITE_PAGE_FROM_STORE" as string,

  CLONE_WEBSITE_PAGE_WAITING_FOR_SERVER: "CLONE_WEBSITE_PAGE_WAITING_FOR_SERVER" as string,

  EDIT_WEBSITE_PAGE_SCHEME_WAITING_FOR_SERVER: "EDIT_WEBSITE_PAGE_SCHEME_WAITING_FOR_SERVER" as string,

  MOVE_WEBSITE_PAGE_COMPONENT: "MOVE_WEBSITE_PAGE_COMPONENT" as string,
  DELETE_WEBSITE_PAGE_COMPONENT: "DELETE_WEBSITE_PAGE_COMPONENT" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_CONTENT: "CHANGE_WEBSITE_PAGE_COMPONENT_CONTENT" as string,
  WEBSITE_PAGE_NEW_EDITIONS_DETECTED: "WEBSITE_PAGE_NEW_EDITIONS_DETECTED" as string,
  TOGGLE_WEBSITE_PAGE_NAV_NEW_EDITIONS_DETECTED: "TOGGLE_WEBSITE_PAGE_NAV_NEW_EDITIONS_DETECTED" as string,
  ADD_WEBSITE_PAGE_COMPONENT: "ADD_WEBSITE_PAGE_COMPONENT" as string,

  CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_COLOR: "CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_COLOR" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE: "CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_ACTIVE_OPTION: "CHANGE_WEBSITE_PAGE_COMPONENT_ACTIVE_OPTION" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_VIDEO: "CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_VIDEO" as string,

  COMMON_WEBSITE_PAGE_UPDATE_WAITING_FOR_SERVER: "COMMON_WEBSITE_PAGE_UPDATE_WAITING_FOR_SERVER" as string,

  CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_TOP: "CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_TOP" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_BOTTOM: "CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_BOTTOM" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_VISIBILITY: "CHANGE_WEBSITE_PAGE_COMPONENT_VISIBILITY" as string,
  PASTE_COPIED_WEBSITE_PAGE_COMPONENT: "PASTE_COPIED_WEBSITE_PAGE_COMPONENT" as string,
  CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE_OVERLAY_OPACITY: "CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE_OVERLAY_OPACITY" as string,
  MOVE_PAGE_TO_ANOTHER_WEBSITE_WAITING_FOR_SERVER: "MOVE_PAGE_TO_ANOTHER_WEBSITE_WAITING_FOR_SERVER" as string,
  TOGGLE_WEBSITE_PAGE_NAV_HIDDEN: "TOGGLE_WEBSITE_PAGE_NAV_HIDDEN" as string,
  TOGGLE_WEBSITE_PAGE_FOOTER_HIDDEN: "TOGGLE_WEBSITE_PAGE_FOOTER_HIDDEN" as string,

  TOGGLE_IS_PAGE_EMPTY: "TOGGLE_IS_PAGE_EMPTY",

  SET_CUSTOM_BACKGROUND_COLOR: "SET_CUSTOM_BACKGROUND_COLOR",

  REPLACE_TEMPLATE_COMPONENTS_WITH_EMPTY_COMPONENTS:
    "REPLACE_TEMPLATE_COMPONENTS_WITH_EMPTY_COMPONENTS",

  CHANGE_COMPONENT_GLOBAL_DATA: "CHANGE_COMPONENT_GLOBAL_DATA",
  CHANGE_WEBSITE_PAGES_STATE_GLOBAL_DATA: "CHANGE_WEBSITE_PAGES_STATE_GLOBAL_DATA",

  SWITCH_COMPONENT: "SWITCH_COMPONENT",

  CHANGE_PAGE_ITEM_GLOBAL_DATA: "CHANGE_PAGE_ITEM_GLOBAL_DATA",

  CHANGE_DIRECTORY_CONTENT: "CHANGE_DIRECTORY_CONTENT",

  EDIT_API_SOURCE_VALIDATION_DATA: "EDIT_API_SOURCE_VALIDATION_DATA",

  CHANGE_COMPONENT_INTEGRATION_ID: "CHANGE_COMPONENT_INTEGRATION_ID",

  SET_PAGE_LOAD_STATUS: "SET_PAGE_LOAD_STATUS",
  SAVE_SINGLE_PAGE_IN_STORE: "SAVE_SINGLE_PAGE_IN_STORE",

  SET_INITIAL_PAGES_LOAD_STATUS: "SET_INITIAL_PAGES_LOAD_STATUS",
  SAVE_INITIAL_PAGES_TO_STORE: "SAVE_INITIAL_PAGES_TO_STORE",
};

export const fetchWebsitePagesWithoutComponents = () => {
  return (dispatch: any, getState: any) => {
    dispatch(toggleWebsitesPagesWithoutComponentsFetched(false));
    dispatch(clearWebsitesPages()); // before fetching new website pages from the server we clear existing store

    let accessToken = getState().auth.accessToken;
    axios
      .get(api.websitePages.getWebsitesPagesWithoutComponents, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(saveWebsitesPagesInState(response.data));
        dispatch(toggleWebsitesPagesWithoutComponentsFetched(true));
      })
      .catch((error) => {
        const errorMessage = `Error message: "${error.message}". Please try to reload the page. If it doesn't help, contact us.`;
        openNotification("Internal error", errorMessage, "OK", "error");
        console.log(error);
      });
  };
};

export const fetchSingleWebsitePages = (subdomain: string, id: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch(toggleAllWebsitePagesFetched(false));

    let accessToken = getState().auth.accessToken;
    await axios
      .get(api.websitePages.fetchWebsitePagesUrl(subdomain), {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(setInitialPagesLoadStatus({
          websiteId: id,
          status: LoadStatus.LOADED
        }))
        response.data.forEach((page: any) => {
          dispatch(setPageLoadStatus({
            pageId: page.id,
            status: LoadStatus.LOADED
          }))
        })
        dispatch(saveWebsitesPagesInState(response.data));
        dispatch(toggleAllWebsitePagesFetched(true));
        dispatch(
          toggleLoadedWebsitesData({
            websiteId: id,
            status: WebsiteDataStatus.loaded,
          })
        );
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const clearWebsitesPages = () => {
  return {
    type: actionsNames.CLEAR_WEBSITES_PAGES as string,
  };
};

export const saveWebsitesPagesInState = (data: any) => {
  return {
    type: actionsNames.SAVE_WEBSITES_PAGES_IN_STATE as string,
    payload: data as string,
  };
};

export const savePageComponentsInState: SavePageComponentsInState = (data) => {
  return {
    type: actionsNames.SAVE_PAGE_COMPONENTS_IN_STATE,
    payload: data,
  };
};

export const replaceWebsitesPagesInState = (data: string) => {
  return {
    type: actionsNames.REPLACE_WEBSITES_PAGES_IN_STATE as string,
    payload: data as string,
  };
};

export const toggleAllWebsitePagesFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.ALL_WEBSITES_PAGES_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleWebsitesPagesWithoutComponentsFetched = (
  isDataFetched: boolean
) => {
  return {
    type: actionsNames.WEBSITES_PAGES_DATA_WITHOUT_COMPONENTS_FETCHED,
    payload: isDataFetched,
  };
};

export const toggleWebsitePageChangeInProgress = (
  pageChangeInProgress: boolean
) => {
  return {
    type: actionsNames.TOGGLE_WEBSITE_PAGES_CHANGE_IN_PROGRESS as string,
    payload: pageChangeInProgress as boolean,
  };
};

export const pageUrlUpdateWaitingForServer = (
  isWaitingForPageUrlUpdateResponse: boolean
) => {
  return {
    type: actionsNames.PAGE_URL_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPageUrlUpdateResponse as boolean,
  };
};
export const pageUrlUpdateToggleInvalidAttempt = (
  isInvalidPageUrlUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.PAGE_URL_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPageUrlUpdateAttempt as boolean,
  };
};
export const pageUrlUpdateErrorMessage = (
  pageUrlUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.PAGE_URL_UPDATE_ERROR_MESSAGE as string,
    payload: pageUrlUpdateErrorMessage as string | undefined,
  };
};

export const pageMetaTitleUpdateWaitingForServer = (
  isWaitingForPageMetaTitleUpdateResponse: boolean
) => {
  return {
    type: actionsNames.PAGE_META_TITLE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPageMetaTitleUpdateResponse as boolean,
  };
};
export const pageMetaTitleUpdateToggleInvalidAttempt = (
  isInvalidPageMetaTitleUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.PAGE_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPageMetaTitleUpdateAttempt as boolean,
  };
};
export const pageMetaTitleUpdateErrorMessage = (
  pageMetaTitleUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.PAGE_META_TITLE_UPDATE_ERROR_MESSAGE as string,
    payload: pageMetaTitleUpdateErrorMessage as string | undefined,
  };
};

export const pageMetaDescriptionUpdateWaitingForServer = (
  isWaitingForPageMetaDescriptionUpdateResponse: boolean
) => {
  return {
    type: actionsNames.PAGE_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPageMetaDescriptionUpdateResponse as boolean,
  };
};
export const pageMetaDescriptionUpdateToggleInvalidAttempt = (
  isInvalidPageMetaDescriptionUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.PAGE_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPageMetaDescriptionUpdateAttempt as boolean,
  };
};
export const pageMetaDescriptionUpdateErrorMessage = (
  pageMetaDescriptionUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.PAGE_META_DESCRIPTION_UPDATE_ERROR_MESSAGE as string,
    payload: pageMetaDescriptionUpdateErrorMessage as string | undefined,
  };
};

export const publishPageToggleWaitingForServer = (
  isWaitingForPublishPageResponse: boolean
) => {
  return {
    type: actionsNames.PUBLISH_PAGE_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPublishPageResponse as boolean,
  };
};

export const createWebsitePageWaitingForServer = (
  isWaitingForCreateWebsitePageResponse: boolean
) => {
  return {
    type: actionsNames.CREATE_WEBSITE_PAGE_WAITING_FOR_SERVER as string,
    payload: isWaitingForCreateWebsitePageResponse as boolean,
  };
};
export const createWebsitePageErrorMessage = (
  createWebsitePageErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.CREATE_WEBSITE_PAGE_ERROR_MESSAGE as string,
    payload: createWebsitePageErrorMessage as string | undefined,
  };
};

export const deleteWebsitePageWaitingForServer = (
  isWaitingForDeleteWebsitePageResponse: boolean
) => {
  return {
    type: actionsNames.DELETE_WEBSITE_PAGE_WAITING_FOR_SERVER as string,
    payload: isWaitingForDeleteWebsitePageResponse as boolean,
  };
};
export const removeWebsitePagesFromStore = (websiteId: number) => {
  return {
    type: actionsNames.REMOVE_WEBSITE_PAGES_FROM_STORE as string,
    payload: websiteId as number,
  };
};

export const removeSingleWebsitePageFromStore = (payload: object) => {
  return {
    type: actionsNames.REMOVE_SINGLE_WEBSITE_PAGE_FROM_STORE as string,
    payload: payload as object,
  };
};

export const cloneWebsitePageWaitingForServer = (
  isWaitingForCloneWebsitePageResponse: boolean
) => {
  return {
    type: actionsNames.CLONE_WEBSITE_PAGE_WAITING_FOR_SERVER as string,
    payload: isWaitingForCloneWebsitePageResponse as boolean,
  };
};

// website page scheme edition
export const editWebsitePageSchemeToggleWaitingForServer = (
  isWaitingForSaveWebsitePageSchemeResponse: boolean
) => {
  return {
    type: actionsNames.EDIT_WEBSITE_PAGE_SCHEME_WAITING_FOR_SERVER as string,
    payload: isWaitingForSaveWebsitePageSchemeResponse as boolean,
  };
};
export const toggleNewWebsitePageEditionsDetected = (
  websitePageNewEditionsDetected: boolean
) => {
  return {
    type: actionsNames.WEBSITE_PAGE_NEW_EDITIONS_DETECTED as string,
    payload: websitePageNewEditionsDetected as boolean,
  };
};
export const toggleNewWebsitePageNavEditionsDetected = (
  websitePageNavNewEditionsDetected: boolean
) => {
  return {
    type: actionsNames.TOGGLE_WEBSITE_PAGE_NAV_NEW_EDITIONS_DETECTED as string,
    payload: websitePageNavNewEditionsDetected as boolean,
  };
};
export const moveWebsitePageComponent = (indexes: object) => {
  return {
    type: actionsNames.MOVE_WEBSITE_PAGE_COMPONENT as string,
    payload: indexes as object,
  };
};
export const deleteWebsitePageComponent = (indexes: object) => {
  return {
    type: actionsNames.DELETE_WEBSITE_PAGE_COMPONENT as string,
    payload: indexes as object,
  };
};
export const changeWebsitePageComponentContent = (content: object) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_CONTENT as string,
    payload: content as object,
  };
};

export const changeWebsitePageComponentBackgroundColor = (data: object) => {
  // see the CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_COLOR in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_COLOR as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentBackgroundImage = (data: object) => {
  // see the CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentBackgroundActiveOption = (
  data: object
) => {
  // see the CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_ACTIVE_OPTION in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_ACTIVE_OPTION as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentBackgroundVideo = (data: object) => {
  // see the CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_VIDEO in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_VIDEO as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentBackgroundImageOverlayOpacity = (
  data: object
) => {
  // see the CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE_OVERLAY_OPACITY in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE_OVERLAY_OPACITY as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentPaddingTop = (data: object) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_TOP as string,
    payload: data as object,
  };
};
export const changeWebsitePageComponentPaddingBottom = (data: object) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_BOTTOM as string,
    payload: data as object,
  };
};

export const changeWebsitePageComponentVisibility = (data: object) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_VISIBILITY as string,
    payload: data as object,
  };
};

export const addWebsitePageComponent = (data: object) => {
  return {
    type: actionsNames.ADD_WEBSITE_PAGE_COMPONENT as string,
    payload: data as object,
  };
};

export const pasteCopiedWebsitePageComponent = (data: object) => {
  return {
    type: actionsNames.PASTE_COPIED_WEBSITE_PAGE_COMPONENT as string,
    payload: data as object,
  };
};

export const toggleWaitingForCommonWebsitePageUpdate = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.COMMON_WEBSITE_PAGE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForMovePageToAnotherWebsite = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.MOVE_PAGE_TO_ANOTHER_WEBSITE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleIsPageNavHidden = (data: object) => {
  return {
    type: actionsNames.TOGGLE_WEBSITE_PAGE_NAV_HIDDEN as string,
    payload: data as object,
  };
};
export const toggleIsPageFooterHidden = (data: object) => {
  return {
    type: actionsNames.TOGGLE_WEBSITE_PAGE_FOOTER_HIDDEN as string,
    payload: data as object,
  };
};
export const toggleIsPageEmpty: ToggleIsPageEmpty = (payload) => {
  return {
    type: actionsNames.TOGGLE_IS_PAGE_EMPTY,
    payload,
  };
};
export const setCustomBackgroundColor: SetCustomBackgroundColor = (payload) => {
  return {
    type: actionsNames.SET_CUSTOM_BACKGROUND_COLOR,
    payload,
  };
};
export const replaceTemplateComponentsWithEmptyComponents: ReplaceTemplateComponentsWithEmptyComponents = (
  payload
) => {
  return {
    type: actionsNames.REPLACE_TEMPLATE_COMPONENTS_WITH_EMPTY_COMPONENTS,
    payload,
  };
};
export const changeComponentGlobalData: ChangeComponentGlobalData = (payload) => {
  return {
    type: actionsNames.CHANGE_COMPONENT_GLOBAL_DATA,
    payload,
  };
};
export const changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData = (payload) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_PAGES_STATE_GLOBAL_DATA,
    payload,
  };
};
export const switchComponent: SwitchComponent = (payload) => {
  return {
    type: actionsNames.SWITCH_COMPONENT,
    payload,
  };
};
export const changePageItemGlobalData: ChangePageItemGlobalData = (payload) => {
  return {
    type: actionsNames.CHANGE_PAGE_ITEM_GLOBAL_DATA,
    payload,
  };
};
export const changeDirectoryContent: ChangeDirectoryContent = (payload) => {
  return {
    type: actionsNames.CHANGE_DIRECTORY_CONTENT,
    payload,
  };
};
export const editApiSourceValidationData: EditApiSourceValidationData = (payload) => {
  return {
    type: actionsNames.EDIT_API_SOURCE_VALIDATION_DATA,
    payload,
  };
}
export const changeComponentIntegrationId: ChangeComponentIntegrationId = (payload) => {
  return {
    type: actionsNames.CHANGE_COMPONENT_INTEGRATION_ID,
    payload,
  };
}
export const setPageLoadStatus = (payload) => {
  return {
    type: actionsNames.SET_PAGE_LOAD_STATUS,
    payload,
  };
}
export const saveSinglePageInStore = (payload) => {
  return {
    type: actionsNames.SAVE_SINGLE_PAGE_IN_STORE,
    payload,
  };
}

export const setInitialPagesLoadStatus = (payload: any) => {
  return {
    type: actionsNames.SET_INITIAL_PAGES_LOAD_STATUS,
    payload,
  };
}
export const saveInitialPagesToStore = (payload: any) => {
  return {
    type: actionsNames.SAVE_INITIAL_PAGES_TO_STORE,
    payload,
  };
}

export default actionsNames;
