import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import { Button, Col, Row } from "antd";
import NavDropdownItems from "./NavDropdownItems";
import EditorScreenPageControls from "./EditorScreenPageControls";
import BlogEditorPostControls from "../blog/BlogEditorPostControls";
import StarterScreenControls from "./StarterScreenControls";
import { ChangePageItemGlobalData } from "../../store/websitePages/types";
import { GetCurrentStoreData } from "../../store/websites/types";

interface Props {
  websites: any;
  blogs: any;
  history: any;
  websitesPages: any;
  blogPosts: any;
  user: any;
  auth: any;
  plans: any;
  logout: any;
  publishPage: any;
  publishPost: any;
  toggleBlogPostChangeInProgress: any;
  publishPostToggleWaitingForServer: any;
  saveBlogPostDataInStore: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  updateActiveDashboardTab: any;
  saveWebsiteDataInStore: any;
  saveWebsitePageDataInStore: any;
  saveBlogDataInStore: any;
  changePageItemGlobalData: ChangePageItemGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
  getPageLoadStatus: any;
  getSinglePostLoadStatus: any;
  getSingleWebsiteLoadStatus: any;
  getSingleBlogLoadStatus: any;
}
interface State {
  settingsDrawerWidth: number | string;
}
class Nav extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      settingsDrawerWidth: 640,
    };
  }
  setDrawerWidth = (width: number | string) => {
    this.setState({
      settingsDrawerWidth: width,
    });
  };
  displayUpgradeButton() {
    if (this.props.plans.dataFetched && this.props.user.isPro === false) {
      // let upgradeButtonTextOptions = ['Upgrade', 'Go pro', 'Buy premium', "Activate Pro"];
      return (
        <Link to="/plan">
          <Button type="default" ghost icon="star">
            Upgrade
          </Button>
        </Link>
      );
    } else {
      return null;
    }
  }

  render() {
    let renderRightColumnButtons = () => {
      return (
        <Switch>
          <Route
            exact
            path="/:website_url/pages/:website_page_id"
            render={(props) => (
              <EditorScreenPageControls
                user={this.props.user}
                getSingleBlogLoadStatus={this.props.getSingleBlogLoadStatus}
                getSingleWebsiteLoadStatus={this.props.getSingleWebsiteLoadStatus}
                getPageLoadStatus={this.props.getPageLoadStatus}
                websites={this.props.websites}
                websitesPages={this.props.websitesPages}
                publishPage={this.props.publishPage}
                history={this.props.history}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                saveWebsitePageDataInStore={
                  this.props.saveWebsitePageDataInStore
                }
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveBlogDataInStore={this.props.saveBlogDataInStore}
                saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                publishPost={this.props.publishPost}
                drawerWidth={this.state.settingsDrawerWidth}
                setDrawerWidth={this.setDrawerWidth}
                changePageItemGlobalData={this.props.changePageItemGlobalData}
                getCurrentStoreData={this.props.getCurrentStoreData}
              />
            )}
          />
          <Route
            exact
            path="/:website_url/blog/:blog_post_id"
            render={(props) => (
              <BlogEditorPostControls
                user={this.props.user}
                getSingleWebsiteLoadStatus={this.props.getSingleWebsiteLoadStatus}
                getSingleBlogLoadStatus={this.props.getSingleBlogLoadStatus}
                getSinglePostLoadStatus={this.props.getSinglePostLoadStatus}
                auth={this.props.auth}
                history={this.props.history}
                websites={this.props.websites}
                blogs={this.props.blogs}
                blogPosts={this.props.blogPosts}
                publishPost={this.props.publishPost}
                routePostUrl={props.match.params.blog_post_url}
                toggleBlogPostChangeInProgress={
                  this.props.toggleBlogPostChangeInProgress
                }
                publishPostToggleWaitingForServer={
                  this.props.publishPostToggleWaitingForServer
                }
                saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                saveWebsitePageDataInStore={
                  this.props.saveWebsitePageDataInStore
                }
                publishPage={this.props.publishPage}
                saveBlogDataInStore={this.props.saveBlogDataInStore}
                drawerWidth={this.state.settingsDrawerWidth}
                setDrawerWidth={this.setDrawerWidth}
                getCurrentStoreData={this.props.getCurrentStoreData}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={() => (
              <StarterScreenControls
                user={this.props.user}
                websites={this.props.websites}
                websitesPages={this.props.websitesPages}
                publishPage={this.props.publishPage}
                history={this.props.history}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                saveWebsitePageDataInStore={
                  this.props.saveWebsitePageDataInStore
                }
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveBlogDataInStore={this.props.saveBlogDataInStore}
                saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                publishPost={this.props.publishPost}
                drawerWidth={this.state.settingsDrawerWidth}
                setDrawerWidth={this.setDrawerWidth}
              />
            )}
          />
        </Switch>
      );
    };

    let isWinterHolidayNow = false;
    let now = new Date();
    if (now.getMonth() == 11 && now.getDate() > 15) {
      //    if 16 December or later
      isWinterHolidayNow = true;
    } else if (now.getMonth() == 0 && now.getDate() < 10) {
      // if not later than 10 Jan
      isWinterHolidayNow = true;
    }

    return (
      <div>
        <Row type="flex" justify="space-between">
          <Col style={{ left: "-6px" }}>
            <NavDropdownItems
              websites={this.props.websites}
              websitesPages={this.props.websitesPages}
              blogs={this.props.blogs}
              blogPosts={this.props.blogPosts}
              updateActiveDashboardTab={this.props.updateActiveDashboardTab}
              publishPage={this.props.publishPage}
              publishPost={this.props.publishPost}
            />
          </Col>
          <Col>
            <div style={{ display: "flex" }}>{renderRightColumnButtons()}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Nav;
