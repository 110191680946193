import axios from "axios";
import { api, apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { ChangeBlogPostData } from "./types";

const actionsNames = {
  SAVE_BLOG_POSTS_IN_STATE: "SAVE_BLOG_POSTS_IN_STATE" as string,
  SAVE_BLOG_POST_CONTENT_IN_STATE: "SAVE_BLOG_POST_CONTENT_IN_STATE" as string,
  ALL_BLOGS_POSTS_DATA_FETCHED: "ALL_BLOGS_POSTS_DATA_FETCHED" as string,
  ALL_BLOGS_POSTS_WITHOUT_CONTENT_DATA_FETCHED: "ALL_BLOGS_POSTS_WITHOUT_CONTENT_DATA_FETCHED" as string,
  TOGGLE_BLOG_POST_CHANGE_IN_PROGRESS: "TOGGLE_BLOG_POST_CHANGE_IN_PROGRESS" as string,
  REPLACE_BLOGS_POSTS_IN_STATE: "REPLACE_BLOGS_POSTS_IN_STATE" as string,
  INCREMENT_REQUESTS_COUNT: "INCREMENT_REQUESTS_COUNT" as string,

  POST_URL_UPDATE_WAITING_FOR_SERVER: "POST_URL_UPDATE_WAITING_FOR_SERVER" as string,
  POST_URL_UPDATE_TOGGLE_INVALID_UPDATE: "POST_URL_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  POST_URL_UPDATE_ERROR_MESSAGE: "POST_URL_UPDATE_ERROR_MESSAGE" as string,

  POST_META_TITLE_UPDATE_WAITING_FOR_SERVER: "POST_META_TITLE_UPDATE_WAITING_FOR_SERVER" as string,
  POST_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE: "POST_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  POST_META_TITLE_UPDATE_ERROR_MESSAGE: "POST_META_TITLE_UPDATE_ERROR_MESSAGE" as string,

  POST_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER: "POST_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER" as string,
  POST_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE: "POST_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  POST_META_DESCRIPTION_UPDATE_ERROR_MESSAGE: "POST_META_DESCRIPTION_UPDATE_ERROR_MESSAGE" as string,

  PUBLISH_POST_TOGGLE_WAITING_FOR_SERVER: "PUBLISH_POST_TOGGLE_WAITING_FOR_SERVER" as string,

  CREATE_BLOG_POST_WAITING_FOR_SERVER: "CREATE_BLOG_POST_WAITING_FOR_SERVER" as string,
  CREATE_BLOG_POST_ERROR_MESSAGE: "CREATE_BLOG_POST_ERROR_MESSAGE" as string,

  CLEAR_BLOGS_POSTS: "CLEAR_BLOGS_POSTS" as string,

  DELETE_BLOG_POST_WAITING_FOR_SERVER: "DELETE_BLOG_POST_WAITING_FOR_SERVER" as string,

  REMOVE_BLOG_POSTS_FROM_STORE: "REMOVE_BLOG_POSTS_FROM_STORE" as string,

  REMOVE_SINGLE_BLOG_POST_FROM_STORE: "REMOVE_SINGLE_BLOG_POST_FROM_STORE" as string,

  CLONE_BLOG_POST_WAITING_FOR_SERVER: "CLONE_BLOG_POST_WAITING_FOR_SERVER" as string,

  EDIT_BLOG_POST_SCHEME_WAITING_FOR_SERVER: "EDIT_BLOG_POST_SCHEME_WAITING_FOR_SERVER" as string,
  EDIT_BLOG_POST_MAIN_TITLE_SCHEME_WAITING_FOR_SERVER: "EDIT_BLOG_POST_MAIN_TITLE_SCHEME_WAITING_FOR_SERVER" as string,

  MOVE_BLOG_POST_COMPONENT: "MOVE_BLOG_POST_COMPONENT" as string,
  DELETE_BLOG_POST_COMPONENT: "DELETE_BLOG_POST_COMPONENT" as string,
  CHANGE_BLOG_POST_BODY: "CHANGE_BLOG_POST_BODY" as string,
  CHANGE_BLOG_POST_TITLE: "CHANGE_BLOG_POST_TITLE" as string,
  BLOG_POST_NEW_EDITIONS_DETECTED: "BLOG_POST_NEW_EDITIONS_DETECTED" as string,
  BLOG_POST_MAIN_TITLE_NEW_EDITIONS_DETECTED: "BLOG_POST_MAIN_TITLE_NEW_EDITIONS_DETECTED" as string,
  ADD_BLOG_POST_COMPONENT: "ADD_BLOG_POST_COMPONENT" as string,

  CHANGE_BLOG_POST_COMPONENT_BACKGROUND_COLOR: "CHANGE_BLOG_POST_COMPONENT_BACKGROUND_COLOR" as string,
  CHANGE_BLOG_POST_COMPONENT_BACKGROUND_IMAGE: "CHANGE_BLOG_POST_COMPONENT_BACKGROUND_IMAGE" as string,

  CHANGE_POST_EDITOR_TYPE: "CHANGE_POST_EDITOR_TYPE" as string,
  CHANGE_POST_EDITOR_TYPE_WAITING_FOR_SERVER: "CHANGE_POST_EDITOR_TYPE_WAITING_FOR_SERVER" as string,

  COMMON_BLOG_POST_UPDATE_WAITING_FOR_SERVER: "COMMON_BLOG_POST_UPDATE_WAITING_FOR_SERVER" as string,

  POST_THUMBNAIL_ALT_UPDATE_WAITING_FOR_SERVER:
    "POST_THUMBNAIL_ALT_UPDATE_WAITING_FOR_SERVER",

  CHANGE_BLOG_POST_DATA: "CHANGE_BLOG_POST_DATA",

  CHANGE_POST_STATE_GLOBAL_DATA: "CHANGE_POST_STATE_GLOBAL_DATA",

  SET_SINGLE_POST_STATUS: "SET_SINGLE_POST_STATUS",
  SAVE_SINGLE_POST_IN_STORE: "SAVE_SINGLE_POST_IN_STORE",

  SET_INITIAL_POSTS_STATUS: "SET_INITIAL_POSTS_STATUS",
  SAVE_INITIAL_POSTS_IN_STORE: "SAVE_INITIAL_POSTS_IN_STORE",
};

export const fetchAllBlogPostsWithoutContent = () => {
  return (dispatch: any, getState: any) => {
    dispatch(toggleAllBlogPostsWithoutContentFetched(false));
    dispatch(clearBlogsPosts()); // before fetching new blog posts from the server we clear existing store

    let accessToken = getState().auth.accessToken;
    axios
      .get(api.blogPosts.getAllUsersBlogsPostsWithoutContent, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(saveBlogsPostsInState(response.data));
      })
      .catch((error) => {})
      .then((response) => {
        dispatch(toggleAllBlogPostsWithoutContentFetched(true));
      });
  };
};

export const fetchSingleBlogsPosts = (subdomain: string) => {
  return (dispatch: any, getState: any) => {
    let accessToken = getState().auth.accessToken;
    const apiUrl = apiUrlBase + api.blogPosts.base + subdomain + "/";
    axios
      .get(apiUrl, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(saveBlogsPostsInState(response.data));
      })
      .catch((error) => {});
  };
};

export const clearBlogsPosts = () => {
  return {
    type: actionsNames.CLEAR_BLOGS_POSTS as string,
  };
};

export const saveBlogsPostsInState = (data: any) => {
  return {
    type: actionsNames.SAVE_BLOG_POSTS_IN_STATE as string,
    payload: data as string,
  };
};

export const replaceBlogsPostsInState = (data: string) => {
  return {
    type: actionsNames.REPLACE_BLOGS_POSTS_IN_STATE as string,
    payload: data as string,
  };
};

export const toggleAllBlogPostsFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.ALL_BLOGS_POSTS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleAllBlogPostsWithoutContentFetched = (
  isDataFetched: boolean
) => {
  return {
    type: actionsNames.ALL_BLOGS_POSTS_WITHOUT_CONTENT_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleBlogPostChangeInProgress = (
  postChangeInProgress: boolean
) => {
  return {
    type: actionsNames.TOGGLE_BLOG_POST_CHANGE_IN_PROGRESS as string,
    payload: postChangeInProgress as boolean,
  };
};

export const postUrlUpdateWaitingForServer = (
  isWaitingForPostUrlUpdateResponse: boolean
) => {
  return {
    type: actionsNames.POST_URL_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPostUrlUpdateResponse as boolean,
  };
};
export const postUrlUpdateToggleInvalidAttempt = (
  isInvalidPostUrlUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.POST_URL_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPostUrlUpdateAttempt as boolean,
  };
};
export const postUrlUpdateErrorMessage = (
  postUrlUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.POST_URL_UPDATE_ERROR_MESSAGE as string,
    payload: postUrlUpdateErrorMessage as string | undefined,
  };
};

export const postMetaTitleUpdateWaitingForServer = (
  isWaitingForPostMetaTitleUpdateResponse: boolean
) => {
  return {
    type: actionsNames.POST_META_TITLE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPostMetaTitleUpdateResponse as boolean,
  };
};
export const postMetaTitleUpdateToggleInvalidAttempt = (
  isInvalidPostMetaTitleUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.POST_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPostMetaTitleUpdateAttempt as boolean,
  };
};
export const postMetaTitleUpdateErrorMessage = (
  postMetaTitleUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.POST_META_TITLE_UPDATE_ERROR_MESSAGE as string,
    payload: postMetaTitleUpdateErrorMessage as string | undefined,
  };
};

export const postMetaDescriptionUpdateWaitingForServer = (
  isWaitingForPostMetaDescriptionUpdateResponse: boolean
) => {
  return {
    type: actionsNames.POST_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPostMetaDescriptionUpdateResponse as boolean,
  };
};
export const postMetaDescriptionUpdateToggleInvalidAttempt = (
  isInvalidPostMetaDescriptionUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.POST_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidPostMetaDescriptionUpdateAttempt as boolean,
  };
};
export const postMetaDescriptionUpdateErrorMessage = (
  postMetaDescriptionUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.POST_META_DESCRIPTION_UPDATE_ERROR_MESSAGE as string,
    payload: postMetaDescriptionUpdateErrorMessage as string | undefined,
  };
};

export const publishPostToggleWaitingForServer = (
  isWaitingForPublishPostResponse: boolean
) => {
  return {
    type: actionsNames.PUBLISH_POST_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaitingForPublishPostResponse as boolean,
  };
};

export const createBlogPostWaitingForServer = (
  isWaitingForCreateBlogPostResponse: boolean
) => {
  return {
    type: actionsNames.CREATE_BLOG_POST_WAITING_FOR_SERVER as string,
    payload: isWaitingForCreateBlogPostResponse as boolean,
  };
};
export const createBlogPostErrorMessage = (
  createBlogPostErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.CREATE_BLOG_POST_ERROR_MESSAGE as string,
    payload: createBlogPostErrorMessage as string | undefined,
  };
};

export const deleteBlogPostWaitingForServer = (
  isWaitingForDeleteBlogPostResponse: boolean
) => {
  return {
    type: actionsNames.DELETE_BLOG_POST_WAITING_FOR_SERVER as string,
    payload: isWaitingForDeleteBlogPostResponse as boolean,
  };
};
export const removeBlogPostsFromStore = (blogId: number) => {
  return {
    type: actionsNames.REMOVE_BLOG_POSTS_FROM_STORE as string,
    payload: blogId as number,
  };
};

export const removeSingleBlogPostFromStore = (payload: object) => {
  return {
    type: actionsNames.REMOVE_SINGLE_BLOG_POST_FROM_STORE as string,
    payload: payload as object,
  };
};

export const cloneBlogPostWaitingForServer = (
  isWaitingForCloneBlogPostResponse: boolean
) => {
  return {
    type: actionsNames.CLONE_BLOG_POST_WAITING_FOR_SERVER as string,
    payload: isWaitingForCloneBlogPostResponse as boolean,
  };
};

// blog post scheme edition
export const editBlogPostSchemeToggleWaitingForServer = (
  isWaitingForSaveBlogPostSchemeResponse: boolean
) => {
  return {
    type: actionsNames.EDIT_BLOG_POST_SCHEME_WAITING_FOR_SERVER as string,
    payload: isWaitingForSaveBlogPostSchemeResponse as boolean,
  };
};
export const toggleNewBlogPostEditionsDetected = (
  blogPostNewEditionsDetected: boolean
) => {
  return {
    type: actionsNames.BLOG_POST_NEW_EDITIONS_DETECTED as string,
    payload: blogPostNewEditionsDetected as boolean,
  };
};

export const editBlogPostMainTitleSchemeToggleWaitingForServer = (
  isWaitingForSaveBlogPostMainTitleSchemeResponse: boolean
) => {
  return {
    type: actionsNames.EDIT_BLOG_POST_MAIN_TITLE_SCHEME_WAITING_FOR_SERVER as string,
    payload: isWaitingForSaveBlogPostMainTitleSchemeResponse as boolean,
  };
};
export const toggleNewBlogPostMainTitleEditionsDetected = (
  blogPostMainTitleNewEditionsDetected: boolean
) => {
  return {
    type: actionsNames.BLOG_POST_MAIN_TITLE_NEW_EDITIONS_DETECTED as string,
    payload: blogPostMainTitleNewEditionsDetected as boolean,
  };
};

export const moveBlogPostComponent = (indexes: object) => {
  return {
    type: actionsNames.MOVE_BLOG_POST_COMPONENT as string,
    payload: indexes as object,
  };
};
export const deleteBlogPostComponent = (indexes: object) => {
  return {
    type: actionsNames.DELETE_BLOG_POST_COMPONENT as string,
    payload: indexes as object,
  };
};
export const changeBlogPostBody = (data: object) => {
  return {
    type: actionsNames.CHANGE_BLOG_POST_BODY as string,
    payload: data as object,
  };
};

export const changeBlogPostTitle = (data: object) => {
  return {
    type: actionsNames.CHANGE_BLOG_POST_TITLE as string,
    payload: data as object,
  };
};

export const changeBlogPostComponentBackgroundColor = (data: object) => {
  // see the CHANGE_BLOG_POST_COMPONENT_BACKGROUND_COLOR in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_BLOG_POST_COMPONENT_BACKGROUND_COLOR as string,
    payload: data as object,
  };
};

export const changeBlogPostComponentBackgroundImage = (data: object) => {
  // see the CHANGE_BLOG_POST_COMPONENT_BACKGROUND_IMAGE in reducers to see what 'data' is.
  return {
    type: actionsNames.CHANGE_BLOG_POST_COMPONENT_BACKGROUND_IMAGE as string,
    payload: data as object,
  };
};

export const addBlogPostComponent = (data: object) => {
  return {
    type: actionsNames.ADD_BLOG_POST_COMPONENT as string,
    payload: data as object,
  };
};

export const changePostEditorType = (data: object) => {
  return {
    type: actionsNames.CHANGE_POST_EDITOR_TYPE as string,
    payload: data as object,
  };
};
export const changePostEditorTypeWaitingForServer = (
  isWaitingForEditorTypeUpdateResponce: boolean
) => {
  return {
    type: actionsNames.CHANGE_POST_EDITOR_TYPE_WAITING_FOR_SERVER as string,
    payload: isWaitingForEditorTypeUpdateResponce as boolean,
  };
};

export const toggleWaitingForCommonBlogPostUpdate = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.COMMON_BLOG_POST_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const postThumbnailAltUpdateWaitingForServer = (payload: boolean) => {
  return {
    type: actionsNames.POST_THUMBNAIL_ALT_UPDATE_WAITING_FOR_SERVER,
    payload,
  };
};

export const saveBlogPostContentInState = (payload) => {
  return {
    type: actionsNames.SAVE_BLOG_POST_CONTENT_IN_STATE,
    payload,
  };
}

export const changeBlogPostData: ChangeBlogPostData = (payload) => {
  return {
    type: actionsNames.CHANGE_BLOG_POST_DATA,
    payload,
  };
}

export const changePostStateGlobalData = (payload) => {
  return {
    type: actionsNames.CHANGE_POST_STATE_GLOBAL_DATA,
    payload,
  };
}

export const setSinglePostStatus = (payload) => {
  return {
    type: actionsNames.SET_SINGLE_POST_STATUS,
    payload,
  };
}
export const saveSinglePostInStore = (payload) => {
  return {
    type: actionsNames.SAVE_SINGLE_POST_IN_STORE,
    payload,
  };
}

export const setInitialPostsStatus = (payload) => {
  return {
    type: actionsNames.SET_INITIAL_POSTS_STATUS,
    payload,
  };
}
export const saveInitialPostsInStore = (payload) => {
  return {
    type: actionsNames.SAVE_INITIAL_POSTS_IN_STORE,
    payload,
  };
}

export default actionsNames;
