import { Button, Drawer, Icon, Spin, Tooltip } from "antd";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { componentsBackgroundColorsOptions } from "../../data/componentsBackgroundColorsOptions";
import { contentEditionDrawerWidth } from "../../data/settings";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { checkIfComponentHasBrightCustomColor } from "../../helpers/editor/checkIfComponentHasBrightCustomColor";
import { checkIfComponentHasDarkBg } from "../../helpers/editor/checkIfComponentHasDarkBg";
import { checkIfCustomColorsActive } from "../../helpers/editor/checkIfCustomColorsActive";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { getCustomBackgroundColorData } from "../../helpers/getCustomBackgroundColorData";
import { openNotification } from "../../helpers/openNotification";
import WebsiteCTA from "./WebsiteCTA";
import WebsiteLogo from "./WebsiteLogo";
import WebsiteSocialNetworkLinks from "./WebsiteSocialNetworkLinks";
import { ReactComponent as MaterialWandSm } from "../../img/icons/material_wand_sm.svg";
import { GptState } from "../../store/gpt/types";
import {
  AbortControllersRef,
  DropdownContainerRef,
  OnGptClick,
  QueueItem,
} from "../../components/editor/ai2/types";
import _ from "lodash";
import InputDropdown from "../../components/editor/ai2/contextual_input/InputDropdown";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { insertIdIntoCode } from "../../helpers/strings/insertIdIntoCode";
import { convertAttributeStringToObject } from "../../helpers/convertAttributeStringToObject";
import { LoadStatus } from "../../enums/enums";

interface Props {
  user?: any;

  currentWebsite: any;
  saveFooterInServer: any;
  isBlogFooterEdited: boolean;

  blogs: any;
  footerEditionFieldsVisible: boolean;

  saveBlogFooterInState: any;
  saveWebsiteFooterInState: any;
  showFooterEditionFieldsDrawer: any;
  hideFooterEditionFieldsDrawer: any;
  displayNavOrFooterEditionBox: any;
  toggleBlogFooterEditionsDetected: any;
  toggleWebsiteFooterEditionsDetected: any;
  onGptClick?: OnGptClick;
  isWebsiteFooter?: boolean;
  gpt?: GptState;
  gptAssistantComponentRef?: any;
  currentWebsitePage?: any;
  currentPageIndex?: number;
  queueDataRef?: React.MutableRefObject<QueueItem[]>;
  intervalRef?: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef?: React.MutableRefObject<boolean>;
  abortControllersRef?: AbortControllersRef;

  getSingleBlogLoadStatus?: any;
}

const WebsiteFooter = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    saveFooterInServer,
    isBlogFooterEdited,
    blogs,
    saveBlogFooterInState,
    saveWebsiteFooterInState,
    showFooterEditionFieldsDrawer,
    hideFooterEditionFieldsDrawer,
    footerEditionFieldsVisible,
    displayNavOrFooterEditionBox,
    toggleBlogFooterEditionsDetected,
    toggleWebsiteFooterEditionsDetected,
    onGptClick,
    isWebsiteFooter,
    gpt,
    gptAssistantComponentRef,
    currentWebsitePage,
    currentPageIndex,
    queueDataRef,
    intervalRef,
    forceStopAllFlagRef,
  } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isInputDropdownVisible, setIsInputDropdownVisible] = useState(false);
  const [componentKey, setComponentKey] = useState(1);

  const gptInputTextAreaRef = useRef(null);
  const dropdownContainerRef: DropdownContainerRef = useRef(null);

  // isBlogFooterEdited is needed to detect when we call displayFooter in the <BlogPostEditor>. We need another "save in state" function and we also need to get the footer object not from website but from blog model.
  // if editing blog Footer - isBlogFooterEdited=true
  // if editing website Footer - isBlogFooterEdited=false
  let currentBlog = getCurrentBlog(blogs.items, currentWebsite);

  if (props.getSingleBlogLoadStatus)  {
    const status = props.getSingleBlogLoadStatus(currentBlog);
    if (status !== LoadStatus.LOADED) {
      return <div>Loading footer for blog...</div>
    }
  }

  const generator = new generateContentDataObject();

  // footerObject contains links, buttons, logo and any other footer-related objects. We have different footers for website and blog.
  let footerObject: any;
  if (isBlogFooterEdited) {
    footerObject = currentBlog.footer;
  } else {
    footerObject = currentWebsite.footer;
  }

  if (
    footerObject === undefined ||
    Object.getOwnPropertyNames(footerObject).length === 0
  ) {
    // if the footerObject is empty ({}) or undefined
    // We set up default footer when a website is create, so this unlikely to happen.

    if (isBlogFooterEdited) {
      if (currentBlog) {
        saveBlogFooterInState({
          footerObject: generator.setUpFooter({}),
          currentBlogId: currentBlog.id,
        });
      } else {
        openNotification(
          "Blog not found",
          "We are sorry, but we failed to find your blog. Could you please report the issue via live chat? We will help.",
          "Meh",
          "error"
        );
      }
    } else {
      saveWebsiteFooterInState({
        footerObject: generator.setUpFooter({}),
        currentWebsiteSubdomain: currentWebsite.subdomain,
      });
    }

    // save this generated footer in server
    saveFooterInServer(currentBlog);

    return null;
  } else {
    let copyrightMarkup =
      footerObject.componentData.text.content.items[0].markup;

    let getFooterBgColorClassesAndIsDarkBg = (
      defaultColor: string,
      defaultIsDarkBg: boolean
    ) => {
      let backgroundColorClassname = defaultColor; // default for this particular footer scheme
      let isDarkBg = defaultIsDarkBg; //default for this particular footer scheme
      let backgroundColorTitle;

      if (footerObject.settings && footerObject.settings.bg_color) {
        backgroundColorTitle = footerObject.settings.bg_color;
        backgroundColorClassname =
          componentsBackgroundColorsOptions[backgroundColorTitle];

        isDarkBg = checkIfComponentHasDarkBg(backgroundColorTitle);
      }

      // If the custom color activated an this custom color is bright, we declare isDarkBg as false;
      let ifComponentHasBrightCustomColor = checkIfComponentHasBrightCustomColor(
        currentWebsite
      );
      // But this only works for the components which has backgroundColorTitle == 'accent' as active.
      if (
        ifComponentHasBrightCustomColor &&
        backgroundColorTitle === "accent"
      ) {
        isDarkBg = false;
      }

      // Because style-yellow-1 accent background is too bright to have white text (need to set isDarkBg to false to enable black text).
      // But only for the default 'style-yellow-1' palette is used (because yellow is too bright)
      // AND custom colors are not activated (because user can use another yellow - more darker - we detect the contrast level by JS and then decide)
      if (
        currentWebsite.color_classname === "style-yellow-1" &&
        backgroundColorTitle === "accent" &&
        checkIfCustomColorsActive(currentWebsite) === false
      ) {
        isDarkBg = false;
      }

      return { classnames: backgroundColorClassname, isDarkBg: isDarkBg };
    };
    let returnFooterMarkup = () => {
      if (footerObject.settings.scheme === 1) {
        {
          /*if 3 columns (groups) then social links go to right, if more cols then social links go to bottom  near CTA*/
        }
        let footerBgColorClassesAndIsDarkBg = getFooterBgColorClassesAndIsDarkBg(
          componentsBackgroundColorsOptions.black,
          true
        );
        let isDarkBg = footerBgColorClassesAndIsDarkBg["isDarkBg"];
        const customBackgroundColorData = getCustomBackgroundColorData(
          footerObject,
          true
        );

        let footerStyleAttr: React.CSSProperties = {};
        if (customBackgroundColorData.isActive) {
          footerStyleAttr = {
            ...footerStyleAttr,
            backgroundColor: `#${customBackgroundColorData.color}`,
          };
          if (customBackgroundColorData.isDark) {
            isDarkBg = true;
          }
        }

        let footerBgColorClassnames =
          footerBgColorClassesAndIsDarkBg["classnames"] +
          " " +
          (isDarkBg ? " text-white " : " ");

        return (
          <div className={footerBgColorClassnames}>
            <footer
              className={
                "footer-02 footer__editor " +
                currentWebsite.active_font_classname
              }
              style={footerStyleAttr}
            >
              <div className="container">
                <div className="footer-02__wrapper">
                  <div className="content-text footer-02__text content_box">
                    &copy; {new Date().getFullYear()}&nbsp;
                    <span
                      style={{ display: "inlineBlock" }}
                      dangerouslySetInnerHTML={{
                        __html: displayMarkupText(copyrightMarkup),
                      }}
                    />
                  </div>
                  <WebsiteSocialNetworkLinks
                    links={footerObject.componentData.socialLinks.content.items}
                    colorType={isDarkBg ? "white" : "black"}
                    direction={"center"}
                  />
                </div>
              </div>
            </footer>
          </div>
        );
      } else if (footerObject.settings.scheme === 2) {
        let footerBgColorClassesAndIsDarkBg = getFooterBgColorClassesAndIsDarkBg(
          componentsBackgroundColorsOptions.light_gray,
          false
        );
        let isDarkBg = footerBgColorClassesAndIsDarkBg["isDarkBg"];
        let footerBgColorClassnames =
          footerBgColorClassesAndIsDarkBg["classnames"];

        let hasButtons = true;
        if (footerObject.componentData.cta.content.buttons.length === 0) {
          hasButtons = false;
        }

        let socialLinksTitle =
          footerObject.componentData.socialLinks.content.title;
        let contacts = footerObject.componentData.contacts.content.items;
        let hasContacts =
          contacts.length === 1 &&
          contacts[0].body === "" &&
          (contacts[0].title === undefined || contacts[0].title === "")
            ? false
            : true; //if has only one contact without or with empty title and with empty body - then there is NO contacts (it is a ghost element).
        let hasSingleItemInBottom =
          (hasContacts === true && hasButtons === false) ||
          (hasContacts === false && hasButtons === true);

        let copyrightMarkup =
          footerObject.componentData.text.content.items[0].markup;
        let logo = footerObject.componentData.logos.content.items[0];

        let listItems = footerObject.componentData.list.content.items;
        let listGroups = footerObject.componentData.list.content.groups;

        let hasFirstColumn =
          (logo &&
            logo.type &&
            logo.type !== "" &&
            logo.uploadedSrc &&
            logo.uploadedSrc !== "") ||
          copyrightMarkup !== ""
            ? true
            : false;

        let isCopyrightMarkupTooLong =
          copyrightMarkup.length > 200 &&
          copyrightMarkup.indexOf('style="') == -1; // if too long, display the content at the bottom of the footer, not on the left column.
        // if copyrightMarkup contains inline css styles, we do not consider it as long.

        const customBackgroundColorData = getCustomBackgroundColorData(
          footerObject,
          true
        );

        let footerStyleAttr: React.CSSProperties = {};
        if (customBackgroundColorData.isActive) {
          footerStyleAttr = {
            ...footerStyleAttr,
            backgroundColor: `#${customBackgroundColorData.color}`,
          };
          if (customBackgroundColorData.isDark) {
            isDarkBg = true;
          }
        }
        return (
          <div className={footerBgColorClassnames}>
            <div
              className={classNames("footer-04", "footer__editor", {
                "footer-04--dark": isDarkBg,
              })}
              style={footerStyleAttr}
            >
              <div className="footer-04__top">
                <div className="container">
                  <div
                    className={
                      "footer-04__top_wrapper " +
                      (isDarkBg ? " text-white " : " ")
                    }
                  >
                    {hasFirstColumn && (
                      <div className="footer-04__col">
                        {logo && logo.uploadedSrc && logo.uploadedSrc !== "" && (
                          <a
                            className="footer-04__logo_link"
                            href="javascript:void(0);"
                          >
                            <WebsiteLogo
                              logoObject={logo}
                              className="footer-04__logo"
                            />
                          </a>
                        )}
                        {isCopyrightMarkupTooLong === false && (
                          <div
                            className="content-text def-14 footer-04__info content_box"
                            dangerouslySetInnerHTML={{
                              __html: displayMarkupText(copyrightMarkup),
                            }}
                          />
                        )}
                      </div>
                    )}

                    {listGroups &&
                      listGroups.map((group: any, groupKey: number) => {
                        if (group.title && group.title !== "") {
                          return (
                            <div
                              className="footer-04__col"
                              key={"footer-links-group-04-" + groupKey}
                            >
                              <h3 className={"def-18_72 title-text--inner footer-04__title "}>
                                {group.title}
                              </h3>
                              <ul className="footer-04__list">
                                {listItems &&
                                  listItems.map(
                                    (listItem: any, listItemKey: number) => {
                                      if (
                                        listItem.title &&
                                        listItem.title !== "" &&
                                        listItem.href &&
                                        listItem.href !== "" &&
                                        //Show only items of this group
                                        listItem.groupIndex === groupKey
                                      ) {
                                        return (
                                          <li
                                            className="footer-04__item"
                                            key={
                                              "footer-links-item-04-" +
                                              listItemKey
                                            }
                                          >
                                            <a
                                              href={returnHref(listItem.href)}
                                              className={"content-text def-14 footer-04__link "}
                                            >
                                              {listItem.title}
                                            </a>
                                          </li>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                              </ul>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    <div className="footer-04__col">
                      {socialLinksTitle && socialLinksTitle !== "" && (
                        <h3 className="def-18_72 title-text--inner footer-04__title">{socialLinksTitle}</h3>
                      )}
                      <div className="footer-04__social_box">
                        <WebsiteSocialNetworkLinks
                          links={
                            footerObject.componentData.socialLinks.content.items
                          }
                          colorType={"color"}
                          direction={"left"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {(hasButtons || hasContacts) && (
                  <div className={"footer-04__bottom "}>
                    <div className="container">
                      <div
                        className={
                          "footer-04__bottom_wrapper " +
                          (hasSingleItemInBottom
                            ? " footer-04__bottom_wrapper--center"
                            : " ")
                        }
                      >
                        <WebsiteCTA componentItem={footerObject} />
                        {hasContacts && (
                          <ul
                            className={
                              "footer-04__bottom_links" +
                              (isDarkBg ? " text-white " : " ")
                            }
                          >
                            {contacts &&
                              contacts.map((item: any, key: number) => {
                                if (
                                  item.body &&
                                  item.body !== "" &&
                                  item.title !== ""
                                ) {
                                  return (
                                    <li
                                      className="footer-04__bottom_links_item"
                                      key={"footer-04-contact-" + key}
                                    >
                                      <a
                                        className="content-text def-12 footer-04__bottom_link footer-04__bottom_item"
                                        target="_blank"
                                        href={returnHref(item.body)}
                                      >
                                        {item.title}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isCopyrightMarkupTooLong && (
                  <div
                    className={"container" + (isDarkBg ? " text-white " : " ")}
                  >
                    <div
                      className={"footer-04__bottom_info content_box "}
                      dangerouslySetInnerHTML={{
                        __html: displayMarkupText(copyrightMarkup),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    };

    const isBeingModifiedByAi = isWebsiteFooter
      ? gpt.currentlyModifiedComponents.includes("footer")
      : false;

    const fillTemplateStatus = _.get(currentWebsite, [
      "fillTemplateData",
      "status",
    ]);
    let isDarkBg = false;
    if (_.get(footerObject, "settings.scheme") === 1) {
      let footerBgColorClassesAndIsDarkBg = getFooterBgColorClassesAndIsDarkBg(
        componentsBackgroundColorsOptions.black,
        true
      );
      isDarkBg = footerBgColorClassesAndIsDarkBg["isDarkBg"];
    }
    if (_.get(footerObject, "settings.scheme") === 2) {
      let footerBgColorClassesAndIsDarkBg = getFooterBgColorClassesAndIsDarkBg(
        componentsBackgroundColorsOptions.light_gray,
        false
      );
      isDarkBg = footerBgColorClassesAndIsDarkBg["isDarkBg"];
    }
    return (
      <>
      <div
        key={componentKey}
        className={classNames("editor__templates_footer highlighted-on-focus", {
          "editor__templates_footer--force-visible-controls": isInputDropdownVisible,
          [footerObject.customClasses]: !!footerObject.customClasses,
          "is-being-edited": footerEditionFieldsVisible
        })}
        id="footer"
        {...convertAttributeStringToObject(footerObject.customAttributes)}
      >
        {footerObject.customJs && (
          <Tooltip
            placement="bottomLeft"
            title={
              "The footer has custom JavaScript logic. Its appearance may be different on a real webpage comparing to the way it looks inside the builder."
            }
            mouseLeaveDelay={0}
            overlayStyle={{maxWidth: "300px"}}
            arrowPointAtCenter
          >
            <Icon type="warning" style={{ 
              color: isDarkBg ? "#fff" : "var(--orange)", 
              fontSize: "16px",
              position: "absolute",
              top: "20px",
              left: "20px"
              }} />
          </Tooltip>
        )}
        {fillTemplateStatus !== "inProgress" && (
          <div className="editor__footer_edit_control">
            <ul className="page-component__component_controls_list">
              {isWebsiteFooter && (
                <li
                  className="page-component__component_controls_item"
                  ref={dropdownContainerRef}
                >
                  <InputDropdown
                    currentWebsitePage={currentWebsitePage}
                    currentPageIndex={currentPageIndex}
                    currentWebsite={currentWebsite}
                    componentIds={["footer"]}
                    textAreaRef={gptInputTextAreaRef}
                    isFooter={true}
                    queueDataRef={queueDataRef}
                    intervalRef={intervalRef}
                    dropdownContainerRef={dropdownContainerRef}
                    isDropdownVisible={isInputDropdownVisible}
                    setIsDropdownVisible={setIsInputDropdownVisible}
                    forceStopAllFlagRef={forceStopAllFlagRef}
                    abortControllersRef={props.abortControllersRef}
                  >
                    <Button
                      size="small"
                      htmlType="button"
                      type="primary"
                      title="Edit the footer with AI"
                      className="page-component__component_control white-border page-component__component_control--ai"
                      onClick={() => {
                        window.posthog.capture(PosthogEvents.CLICK_ASK_AI, {
                          website_id: currentWebsite.id,
                          component: "footer",
                        });
                      }}
                    >
                      <MaterialWandSm className="gpt-assistant__icon" /> Ask AI
                    </Button>
                  </InputDropdown>
                </li>
              )}
              <li className="page-component__component_controls_item">
                <Button
                  onClick={() => {
                    window.posthog.capture(PosthogEvents.CLICK_EDIT, {
                      component: "footer",
                    });
                    showFooterEditionFieldsDrawer();
                  }}
                  title="Edit footer"
                  size="small"
                  htmlType="button"
                  type="primary"
                  icon="edit"
                  className="white-border"
                  disabled={isBeingModifiedByAi}
                >
                  Edit footer
                </Button>
              </li>
            </ul>
          </div>
        )}
        <div className="custom_fonts">
          <div className={currentWebsite.active_font_classname}>
            {returnFooterMarkup()}
          </div>
        </div>
        <Drawer
          title={
            isBlogFooterEdited
              ? "Edit the blog footer"
              : "Edit the website footer"
          }
          placement="right"
          closable={false}
          onClose={hideFooterEditionFieldsDrawer}
          visible={footerEditionFieldsVisible}
          width={contentEditionDrawerWidth}
          maskStyle={{ cursor: "pointer" }}
          afterVisibleChange={(visible) => {
            if (visible) {
              document.body.style.removeProperty("overflow");
              document.body.style.removeProperty("touch-action");
            }
          }}
        >
          {displayNavOrFooterEditionBox(
            currentWebsite,
            footerObject.componentData,
            isBlogFooterEdited
              ? saveBlogFooterInState
              : saveWebsiteFooterInState,
            isBlogFooterEdited
              ? toggleBlogFooterEditionsDetected
              : toggleWebsiteFooterEditionsDetected,
            hideFooterEditionFieldsDrawer,
            "footer",
            undefined,
            isBlogFooterEdited,
            () => {setComponentKey((componentKey) => componentKey + 1)}
          )}
          {/*undefined - is the isFirstComponentBgDark argument which we do not care about in the footer component - needed for the nav component to detect the nav color depending on the first component*/}
        </Drawer>
      </div>
      {footerObject.customCss && (
        <style>
          {insertIdIntoCode(
            footerObject.customCss,
            "#footer"
          )}
        </style>
      )}
      </>
    );
  }
};

export default WebsiteFooter;
