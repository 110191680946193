import { BlogPostsState, ChangeBlogPostDataPayload } from "./types";
import actionsNames from "./actions";
import moveArrayItem from "../../helpers/moveArrayItem";
import _ from "lodash";

let initialState: BlogPostsState = {
  items: [],
  allPostsFetched: false,
  allPostsWithoutContentFetched: false,
  postChangeInProgress: false, // We don't provide a server response indicator for each post. We have only one global for every post change request.

  isWaitingForEditorTypeUpdateResponce: false,

  isWaitingForPostUrlUpdateResponse: false,
  isInvalidPostUrlUpdateAttempt: false,
  postUrlUpdateErrorMessage: undefined,

  isWaitingForPostMetaTitleUpdateResponse: false,
  isInvalidPostMetaTitleUpdateAttempt: false,
  postMetaTitleUpdateErrorMessage: undefined,

  isWaitingForPostMetaDescriptionUpdateResponse: false,
  isInvalidPostMetaDescriptionUpdateAttempt: false,
  postMetaDescriptionUpdateErrorMessage: undefined,

  isWaitingForPublishPostResponse: false,

  isWaitingForCreateBlogPostResponse: false,
  createBlogPostErrorMessage: undefined,

  isWaitingForCloneBlogPostResponse: false,
  isWaitingForDeleteBlogPostResponse: false,

  isWaitingForSaveBlogPostSchemeResponse: false,
  blogPostNewEditionsDetected: false,
  isWaitingForSaveBlogPostMainTitleSchemeResponse: false,
  blogPostMainTitleNewEditionsDetected: false,

  isWaitingForCommonBlogPostUpdateResponse: false, //used when there is no state for a specific blog change

  isWaitingForPostThumbnailAltUpdateResponse: false,

  isWaitingPublishDateUpdateResponse: false,

  singlePostStatusArray: [],
  initialPostsStatusArray: [],
};

export const blogPostsReducer = (
  state: BlogPostsState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_BLOG_POSTS_IN_STATE:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case actionsNames.SAVE_BLOG_POST_CONTENT_IN_STATE: {
      const currentBlogPosts = [...state.items];
      const blogPostsWithContent = [...action.payload];

      const updatedBlogPosts = currentBlogPosts.map((blogPost) => {
        const id = _.get(blogPost, "id");
        const fullPost = _.find(blogPostsWithContent, { id });

        if (!fullPost) return blogPost;

        const fullPostJson = _.get(fullPost, "body_json");
        const fullPostHtml = _.get(fullPost, "body_html");
        _.set(blogPost, "body_json", fullPostJson);
        _.set(blogPost, "body_html", fullPostHtml);

        return blogPost;
      });

      return {
        ...state,
        items: updatedBlogPosts,
      };
    }

    case actionsNames.REPLACE_BLOGS_POSTS_IN_STATE:
      return {
        ...state,
        items: [...action.payload],
      };

    case actionsNames.CLEAR_BLOGS_POSTS:
      // when we fetch blogPosts we clear the array.
      return {
        ...state,
        items: [],
      };

    case actionsNames.ALL_BLOGS_POSTS_DATA_FETCHED:
      return {
        ...state,
        allPostsFetched: action.payload,
      };

    case actionsNames.ALL_BLOGS_POSTS_WITHOUT_CONTENT_DATA_FETCHED:
      return {
        ...state,
        allPostsWithoutContentFetched: action.payload,
      };

    case actionsNames.TOGGLE_BLOG_POST_CHANGE_IN_PROGRESS:
      // Waiting for the server for a response.
      return {
        ...state,
        postChangeInProgress: action.payload,
      };

    case actionsNames.POST_URL_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPostUrlUpdateResponse: action.payload,
      };
    case actionsNames.POST_URL_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPostUrlUpdateAttempt: action.payload,
      };
    case actionsNames.POST_URL_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        postUrlUpdateErrorMessage: action.payload,
      };

    case actionsNames.POST_META_TITLE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPostMetaTitleUpdateResponse: action.payload,
      };
    case actionsNames.POST_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPostMetaTitleUpdateAttempt: action.payload,
      };
    case actionsNames.POST_META_TITLE_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        postMetaTitleUpdateErrorMessage: action.payload,
      };

    case actionsNames.POST_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPostMetaDescriptionUpdateResponse: action.payload,
      };
    case actionsNames.POST_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPostMetaDescriptionUpdateAttempt: action.payload,
      };
    case actionsNames.POST_META_DESCRIPTION_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        postMetaDescriptionUpdateErrorMessage: action.payload,
      };

    case actionsNames.PUBLISH_POST_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPublishPostResponse: action.payload,
      };

    case actionsNames.CREATE_BLOG_POST_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCreateBlogPostResponse: action.payload,
      };
    case actionsNames.CREATE_BLOG_POST_ERROR_MESSAGE:
      return {
        ...state,
        createBlogPostErrorMessage: action.payload,
      };

    case actionsNames.DELETE_BLOG_POST_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForDeleteBlogPostResponse: action.payload,
      };

    case actionsNames.CLONE_BLOG_POST_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCloneBlogPostResponse: action.payload,
      };

    case actionsNames.REMOVE_BLOG_POSTS_FROM_STORE:
      let oldBlogPosts = state.items;
      let newBlogPosts = oldBlogPosts.filter((blogPost: any) => {
        return blogPost.blog !== action.payload;
      });

      return {
        ...state,
        items: newBlogPosts,
      };

    case actionsNames.REMOVE_SINGLE_BLOG_POST_FROM_STORE:
      let allBlogsPosts = state.items;
      let allBlogsPostsExceptTheGivenOne = allBlogsPosts.filter(
        (blogPost: any) => {
          return !(
            blogPost.blog === action.payload.blogId &&
            blogPost.url === action.payload.postUrl
          );
        }
      );

      return {
        ...state,
        items: allBlogsPostsExceptTheGivenOne,
      };

    //    edit scheme
    case actionsNames.EDIT_BLOG_POST_SCHEME_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForSaveBlogPostSchemeResponse: action.payload,
      };
    //    edit main title
    case actionsNames.EDIT_BLOG_POST_MAIN_TITLE_SCHEME_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForSaveBlogPostMainTitleSchemeResponse: action.payload,
      };
    case actionsNames.BLOG_POST_NEW_EDITIONS_DETECTED:
      return {
        ...state,
        blogPostNewEditionsDetected: action.payload,
      };
    case actionsNames.BLOG_POST_MAIN_TITLE_NEW_EDITIONS_DETECTED:
      return {
        ...state,
        blogPostMainTitleNewEditionsDetected: action.payload,
      };

    case actionsNames.MOVE_BLOG_POST_COMPONENT:
      // action.payload.currentPostIndex tells the index of the post under edition (reminder: we store all the posts in the items[])
      // action.payload.oldIndex tells the old index of the component
      // action.payload.newIndex tells the new index of the component

      // get the post components list
      let oldComponentsArray =
        state.items[action.payload.currentPostIndex].post_components.data
          .schema;

      // clone the post components list
      let newComponentsArray = [...oldComponentsArray];
      // perform the movement
      moveArrayItem(
        newComponentsArray,
        action.payload.oldIndex,
        action.payload.newIndex
      );

      //clone state
      let newState = { ...state };
      // change the components list with the new one (with the moving performed)
      newState.items[
        action.payload.currentPostIndex
      ].post_components.data.schema = newComponentsArray;

      return {
        ...newState,
      };
    case actionsNames.DELETE_BLOG_POST_COMPONENT:
      // action.payload.currentPostIndex tells the index of the component to delete

      // get the post components list
      let oldComponentsList =
        state.items[action.payload.currentPostIndex].post_components.data
          .schema;

      // clone the post components list
      let newComponentsList = [...oldComponentsList];
      // perform the delete
      newComponentsList.splice(action.payload.componentIndex, 1);

      // get the posts list
      let blogPostsList = state.items;
      // update the components list in the blog posts list
      blogPostsList[
        action.payload.currentPostIndex
      ].post_components.data.schema = newComponentsList;

      return {
        ...state,
        items: blogPostsList,
      };

    case actionsNames.CHANGE_BLOG_POST_BODY:
      // clone the posts list
      let newPostsList = [...state.items];

      // get current post and save the updated post body fields (body_json and body_html) of the current post
      newPostsList[action.payload.currentPostIndex].body_json =
        action.payload.body_json;
      newPostsList[action.payload.currentPostIndex].body_html =
        action.payload.body_html;

      return {
        ...state,
        items: newPostsList,
      };

    case actionsNames.CHANGE_BLOG_POST_TITLE: {
      // clone the posts list
      let newPostsList = [...state.items];

      // get current post and save the updated title of the current post
      newPostsList[action.payload.currentPostIndex].title =
        action.payload.title;

      return {
        ...state,
        items: newPostsList,
      };
    }

    case actionsNames.CHANGE_BLOG_POST_COMPONENT_BACKGROUND_COLOR: {
      // action.payload.componentKey is the edited component index
      // action.payload.currentPostIndex is the edited post index
      // action.payload.color is the new BG color title. See componentsBackgroundColorsOptions.ts to view them all.

      // get the post components list
      let oldComponents =
        state.items[action.payload.currentPostIndex].post_components.data
          .schema;

      // clone the post components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      updatedComponentItem.settings.background.color = action.payload.color;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the posts list
      let newBlogPostsList = [...state.items];
      // get current post and save the updated components list of the current post
      newBlogPostsList[
        action.payload.currentPostIndex
      ].post_components.data.schema = newComponents;

      return {
        ...state,
        items: newBlogPostsList,
      };
    }

    case actionsNames.CHANGE_BLOG_POST_COMPONENT_BACKGROUND_IMAGE: {
      // action.payload.componentKey is the edited component index
      // action.payload.currentPostIndex is the edited post index
      // action.payload.imageUrl is the new BG image url.
      // action.payload.imageUUID is the new BG image UUID.

      // get the post components list
      let previousComponents =
        state.items[action.payload.currentPostIndex].post_components.data
          .schema;

      // clone the post components list
      let updatedComponents: any = [...previousComponents];
      // get the component which componentData we want to edit
      let updatedComponentSingleItem =
        updatedComponents[action.payload.componentKey];

      // edit the componentData
      updatedComponentSingleItem.settings.background.imageUrl =
        action.payload.imageUrl || "";
      updatedComponentSingleItem.settings.background.imageUUID =
        action.payload.imageUUID || undefined;

      // save this component back to the components list
      updatedComponents[
        action.payload.componentKey
      ] = updatedComponentSingleItem;

      // clone the posts list
      let finalNewBlogPostsList = [...state.items];
      // get current post and save the updated components list of the current post
      finalNewBlogPostsList[
        action.payload.currentPostIndex
      ].post_components.data.schema = updatedComponents;

      return {
        ...state,
        items: finalNewBlogPostsList,
      };
    }

    case actionsNames.CHANGE_POST_EDITOR_TYPE: {
      // clone the posts list
      let newPostsList = [...state.items];

      // get current post and save the updated title of the current post
      newPostsList[action.payload.currentPostIndex].editor_type =
        action.payload.editor_type;

      return {
        ...state,
        items: newPostsList,
      };
    }

    case actionsNames.CHANGE_POST_EDITOR_TYPE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForEditorTypeUpdateResponce: action.payload,
      };

    case actionsNames.COMMON_BLOG_POST_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCommonBlogPostUpdateResponse: action.payload,
      };

    case actionsNames.POST_THUMBNAIL_ALT_UPDATE_WAITING_FOR_SERVER: {
      return {
        ...state,
        isWaitingForPostThumbnailAltUpdateResponse: action.payload,
      };
    }

    case actionsNames.CHANGE_BLOG_POST_DATA: {
      const payload: ChangeBlogPostDataPayload = action.payload;
      const { postId, data } = payload;

      const postsArrayCopy = _.cloneDeep(state.items);
      const currentPost = postsArrayCopy.find((post) => post.id === postId);
      Object.assign(currentPost, data);

      return {
        ...state,
        items: postsArrayCopy,
      };
    }

    case actionsNames.CHANGE_POST_STATE_GLOBAL_DATA: {
      const payload = action.payload;
      const { data } = payload;
      return {
        ...state,
        ...data,
      };
    }

    case actionsNames.SET_SINGLE_POST_STATUS: {
      const payload = action.payload;
      const { postId, status } = payload;
      const currentLoadStatusArray = [...state.singlePostStatusArray];
      const existingStatusIndex = currentLoadStatusArray.findIndex(
        (item) => item.postId === postId
      );
      if (existingStatusIndex === -1) {
        currentLoadStatusArray.push({ postId, status });
      } else {
        currentLoadStatusArray[existingStatusIndex] = { postId, status };
      }
      return {
        ...state,
        singlePostStatusArray: currentLoadStatusArray,
      };
    }
    case actionsNames.SAVE_SINGLE_POST_IN_STORE: {
      const payload = action.payload;
      const { post } = payload;
      const itemsArrayCopy = _.cloneDeep(state.items);
      const existingItemIndex = itemsArrayCopy.findIndex(
        (item) => item.id === post.id
      );
      if (existingItemIndex === -1) {
        itemsArrayCopy.push(post);
      } else {
        itemsArrayCopy[existingItemIndex] = post;
      }
      return {
        ...state,
        items: itemsArrayCopy,
      };
    }

    case actionsNames.SET_INITIAL_POSTS_STATUS: {
      const payload = action.payload;
      const { blogId, status } = payload;
      const currentLoadStatusArray = [...state.initialPostsStatusArray];
      const existingStatusIndex = currentLoadStatusArray.findIndex(
        (item) => item.blogId === blogId
      );
      if (existingStatusIndex === -1) {
        currentLoadStatusArray.push({ blogId, status });
      } else {
        currentLoadStatusArray[existingStatusIndex] = { blogId, status };
      }
      return {
        ...state,
        initialPostsStatusArray: currentLoadStatusArray,
      };
    }
    case actionsNames.SAVE_INITIAL_POSTS_IN_STORE: {
      function mergeArrays(a, b) {
        const map = new Map();
        a.forEach(obj => {
          map.set(obj.id, obj);
        });
        b.forEach(obj => {
          map.set(obj.id, obj);
        });
        return Array.from(map.values());
      }
      const payload = action.payload;
      const { postsArray } = payload;
      const itemsArrayCopy = _.cloneDeep(state.items);
      const mergedArray = mergeArrays(postsArray, itemsArrayCopy);
      return {
        ...state,
        items: mergedArray,
      };
    }


    default:
      return state;
  }
};
