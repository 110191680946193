import _ from "lodash";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import { WebsitesState } from "../websites/types";
import {
  addWebsitePageComponent,
  changeWebsitePageComponentContent,
  changeWebsitePagesStateGlobalData,
  editApiSourceValidationData,
  saveWebsitesPagesInState,
  setPageLoadStatus,
  toggleNewWebsitePageEditionsDetected,
} from "./actions";
import { WebsitePagesState } from "./types";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { createPageAsync } from "../../helpers/async/createPageAsync";
import { getActualComponentName } from "../../helpers/getActualComponentName";
import { api } from "../../data/urls";
import axios from "axios";
import { openNotification } from "../../helpers/openNotification";
import {
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../../data/constants";
import { checkIfGoogleSheetUrl } from "../../helpers/checkIfGoogleSheetUrl";
import { getCurrentStoreData } from "../websites/thunks";
import { validateGoogleSheetAsync } from "../../helpers/async/validateGoogleSheetAsync";
import { AuthState } from "../auth/types";
import { LoadStatus } from "../../enums/enums";

const generator = new generateContentDataObject();

export const addAndReturnWebsitePageComponent = (payload) => {
  return (dispatch, getState) => {
    dispatch(addWebsitePageComponent(payload));
    const { items } = getState().websitesPages;
    const { currentPageIndex, indexToPlace } = payload;
    const currentPage = items[currentPageIndex];
    const componentsArray = currentPage.page_components.data.schema;
    const newComponent = componentsArray[indexToPlace];
    return newComponent;
  };
};

export const clearWebsitePage = () => {
  return (dispatch, getState) => {
    const websites: WebsitesState = getState().websites;
    const websitesPages: WebsitePagesState = getState().websitesPages;

    const currentWebsite = getCurrentWebsite(websites.items);
    const currentPageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      websitesPages.items
    );
    const currentWebsitePage = getCurrentWebsitePage(
      currentPageUrl,
      websitesPages.items,
      currentWebsite
    );
    const currentPageIndex = websitesPages.items.indexOf(currentWebsitePage);
    const pageComponents = currentWebsitePage.page_components.data.schema;

    pageComponents.forEach((component, index) => {
      const componentName = getActualComponentName(component);
      const componentCopy = _.cloneDeep(component);
      let { componentData } = componentCopy;
      Object.keys(componentData).forEach((contentType) => {
        if (contentType === "title") {
          componentData.title.content.markup = "";
        }
        if (contentType === "subtitleText") {
          componentData.subtitleText.content.markup = "";
        }
        if (contentType === "cta") {
          componentData.cta.content.form.button.title = "";
          componentData.cta.content.form.fields.items = [];
          componentData.cta.content.buttons = [];
          componentData.cta.ctaBottomInfo.markup = "";
        }
        if (contentType === "features") {
          componentData.features.content.items = [];
        }
        if (contentType === "list") {
          componentData.list.content.items = [];
          componentData.list.content.groups = [];
        }
        if (contentType === "posts") {
          if (!componentName.startsWith("photos-01")) {
            componentData.posts.content.items = [];
          }
        }
        if (contentType === "gallery") {
          if (!componentName.startsWith("slider")) {
            componentData.gallery.content.items = [];
          }
        }
        if (contentType === "pricing") {
          if (componentName.startsWith("pricing-03")) {
            componentData.pricing.content.items[0].title = "";
            componentData.pricing.content.items[0].primaryInfo = "";
            componentData.pricing.content.items[0].monthlyPrice = "";
            componentData.pricing.content.items[0].yearlyPrice = "";
            componentData.pricing.content.items[0].monthlyPeriod = "";
            componentData.pricing.content.items[0].yearlyPeriod = "";
            componentData.pricing.content.items[0].currencySymbol = "";
            componentData.pricing.content.items[0].secondaryInfo = "";
            componentData.pricing.content.items[0].button.title = "";
            componentData.pricing.content.items[0].button.href = "";
            componentData.pricing.content.items[0].detailsSet = [];
            componentData = generator.addEmptyPricingDetailIfNeeded(
              componentData,
              0
            );
          } else {
            componentData.pricing.content.items = [];
          }
        }
        if (contentType === "steps") {
          componentData.steps.content.items = [];
        }
        if (contentType === "text") {
          componentData.text.content.items.forEach((item) => {
            item.markup = "";
          });
        }
        if (contentType === "team") {
          if (!componentName.startsWith("team-01")) {
            componentData.team.content.items = [];
          }
        }
        if (contentType === "faq") {
          componentData.faq.content.items = [];
          componentData = generator.addEmptyFaqIfNeeded(componentData);
        }
        if (contentType === "contacts") {
          componentData.contacts.content.items = [];
          componentData.contacts.content.map.mapTitle = "";
          componentData.contacts.content.map.url = "";
          componentData = generator.addEmptyContactIfNeeded(componentData);
        }
        if (contentType === "socialLinks") {
          componentData.socialLinks.content.items = [];
          componentData.socialLinks.content.title = "";
          componentData = generator.addEmptySocialLinkIfNeeded(componentData);
        }
        if (contentType === "code") {
          if (!componentName.startsWith("other-01")) {
            componentData.code.content.codeSnippet = "";
            componentData.code.content.gistUrl = "";
            componentData.code.content.subtitle = "";
            componentData.code.content.title = "";
          }
        }
      });
      dispatch(
        changeWebsitePageComponentContent({
          currentPageIndex,
          componentKey: index,
          componentData,
        })
      );
      dispatch(toggleNewWebsitePageEditionsDetected(true));
    });
  };
};

export const createAndSavePageAsync = (
  pageUrl: string,
  templateId?: number
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const websites = getState().websites;
      const auth = getState().auth;
      const subdomain = getCurrentWebsite(websites.items).subdomain;
      createPageAsync({
        accessToken: auth.accessToken,
        subdomain,
        pageUrl,
        templateId,
      })
        .then((response) => {
          dispatch(setPageLoadStatus({
            pageId: response.data.id,
            status: LoadStatus.LOADED,
          }));
          dispatch(saveWebsitesPagesInState([response.data]));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const fetchAndSaveApiValidationData = (
  api_source_url: string,
  cancelTokenSourceRef,
  successMessage?: string
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { currentWebsitePage } = dispatch(getCurrentStoreData());
      const auth: AuthState = getState().auth;

      if (!checkIfGoogleSheetUrl(api_source_url)) {
        if (
          typeof api_source_url === "string" &&
          api_source_url.startsWith("https://docs.google.com/spreadsheets")
        ) {
          dispatch(
            editApiSourceValidationData({
              pageId: currentWebsitePage.id,
              newData: {
                data: {
                  status: "error",
                  type: "front:generic_url",
                },
              },
            })
          );
        }
        return;
      }
      dispatch(
        editApiSourceValidationData({
          pageId: currentWebsitePage.id,
          newData: {
            isLoading: true,
          },
        })
      );

      if (!cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current = axios.CancelToken.source();
      }
      const purgeCacheParameter = currentWebsitePage.api_cache_purge_parameter
        ? `&purge_cache=${currentWebsitePage.api_cache_purge_parameter}`
        : "";
      validateGoogleSheetAsync({
        accessToken: auth.accessToken,
        sheetUrl: api_source_url,
        cancelToken: cancelTokenSourceRef.current.token,
        purgeCacheParameter,
      })
        .then(({ data }) => {
          dispatch(
            editApiSourceValidationData({
              pageId: currentWebsitePage.id,
              newData: {
                data,
              },
            })
          );
          if (successMessage) {
            openNotification(
              "Success",
              successMessage,
              "OK",
              "success",
              notificationDuration.short
            );
          }
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          const responseData = _.get(error, "response.data");
          console.log("responseData: ", responseData);
          if (responseData) {
            dispatch(
              editApiSourceValidationData({
                pageId: currentWebsitePage.id,
                newData: {
                  data: responseData,
                },
              })
            );
          } else {
            dispatch(
              editApiSourceValidationData({
                pageId: currentWebsitePage.id,
                newData: {
                  data: {
                    status: "error",
                    type: "unknown_request_error",
                    details: error.message,
                  },
                },
              })
            );
          }
          reject(error);
        })
        .finally(() => {
          dispatch(
            editApiSourceValidationData({
              pageId: currentWebsitePage.id,
              newData: {
                isLoading: false,
              },
            })
          );
        });
    });
  };
};

export const fetchAndSavePageTemplatesAsync = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(
        changeWebsitePagesStateGlobalData({
          data: {
            pageTemplatesLoadedStatus: "loading",
          },
        })
      );
      const url = api.websitePages.fetchPageTemplates;
      axios
        .get(url)
        .then((response) => {
          dispatch(
            changeWebsitePagesStateGlobalData({
              data: {
                pageTemplatesLoadedStatus: "loaded",
                pageTemplatesArray: response.data,
              },
            })
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            changeWebsitePagesStateGlobalData({
              data: {
                pageTemplatesLoadedStatus: "error",
              },
            })
          );
          console.error(error.response);
          openNotification(
            DEFAULT_ERROR_TITLE,
            "Couldn't load page templates. Please reload the page. If it didn't help, contact us.",
            "OK",
            "error",
            notificationDuration.medium
          );
          reject(error);
        });
    });
  };
};

export const getPageLoadStatus = (pageId) => {
  return (dispatch, getState) => {
    if (!pageId) {
      return LoadStatus.NO_DATA;
    }
    const websitesPages = getState().websitesPages;
    const currentStatus = websitesPages.pageLoadStatusArray.find((item) => {
      return item.pageId === pageId;
    })
    return _.get(currentStatus, "status", LoadStatus.NOT_LOADED);
  };
};

export const getWebsiteInitialPagesLoadStatus = (website) => {
  return (dispatch, getState) => {
    if (!website) {
      return LoadStatus.NO_DATA;
    }
    const websitesPages = getState().websitesPages;
    const currentStatus = websitesPages.initialPagesLoadStatusArray.find((item) => {
      return item.websiteId === website.id;
    })
    return _.get(currentStatus, "status", LoadStatus.NOT_LOADED);
  };
};
