import React, { Component } from "react";
import { Checkbox, Divider, Icon, Popconfirm, Popover, Tooltip } from "antd";
import { projectTitle } from "../../data/constants";
import "./WebsiteSEOsettings.css";
import { api } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { openNotification } from "../../helpers/openNotification";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import WebsiteRedirects from "./WebsiteRedirects";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import WebsiteLanguageSettings from "./WebsiteLanguageSettings";
import _ from "lodash";

interface Props {
  auth: any;
  user: any;
  websites: any;
  currentWebsite: any;
  fullWebsiteUrl: any;
  toggleWaitingForCommonWebsiteUpdate: any;
  saveWebsiteDataInStore: any;
  toggleWaitingForWebsiteLanguagesUpdate: any;
}

class WebsiteSEOsettings extends Component<Props> {
  confirmDisallow = (checked: boolean) => {
    this.props.toggleWaitingForCommonWebsiteUpdate(true);

    let accessToken = this.props.auth.accessToken;

    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        { is_disallow_crawling_bots: checked },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            is_disallow_crawling_bots: _.get(response, "data.is_disallow_crawling_bots")
          }
        }
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsite.subdomain
        );
        // Tell a user that the operation is successful.
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWaitingForCommonWebsiteUpdate(false);
      });
  };
  render() {
    let isWaitingForServerResponse = this.props.websites
      .isWaitingForCommonWebsiteUpdateResponse;

    let isDisallowAllActive = this.props.currentWebsite
      .is_disallow_crawling_bots;

    let confirmDisallowButtonText = "Yes, disallow all";
    let confirmDisallowDetailsMessage =
      "Are you sure you want to disallow all bots (including search engines) to crawl your website? This may damage your SEO positions.";
    if (isDisallowAllActive) {
      confirmDisallowButtonText = "Allow all robots";
      confirmDisallowDetailsMessage =
        "Do you want to allow robots to crawl your website?";
    }

    return (
      <div>
        {
          <WebsiteLanguageSettings
            auth={this.props.auth}
            user={this.props.user}
            currentWebsite={this.props.currentWebsite}
            websites={this.props.websites}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            toggleWaitingForWebsiteLanguagesUpdate={
              this.props.toggleWaitingForWebsiteLanguagesUpdate
            }
          />
        }
        <Divider className="settings__divider" />
        {
          <WebsiteRedirects
            auth={this.props.auth}
            user={this.props.user}
            currentWebsite={this.props.currentWebsite}
            websites={this.props.websites}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            fullWebsiteUrl={this.props.fullWebsiteUrl}
            toggleWaitingForCommonWebsiteUpdate={
              this.props.toggleWaitingForCommonWebsiteUpdate
            }
          />
        }

        <Divider className="settings__divider" />

        <div className="settings-box__input">
          <Popconfirm
            placement="bottomLeft"
            title={
              <div style={{ width: 350 }}>{confirmDisallowDetailsMessage}</div>
            }
            onConfirm={() => {
              this.confirmDisallow(!isDisallowAllActive);
            }}
            okText={confirmDisallowButtonText}
            cancelText="Cancel"
          >
            <Checkbox
              className="website-seo-settings__disallow"
              disabled={isWaitingForServerResponse}
              checked={!isDisallowAllActive}
            >
              Allow robots (including Google&trade;) to crawl your website.{" "}
            </Checkbox>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

export default WebsiteSEOsettings;
