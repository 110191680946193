import React, { Component, useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Dropdown,
  Empty,
  Icon,
  Input,
  Menu,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import "./NavDropdown.scss";
import "./PageTemplate.scss";
import { connect } from "react-redux";
import { AuthState } from "../../store/auth/types";
import {
  cloneWebsitePageWaitingForServer,
  createWebsitePageWaitingForServer,
  deleteWebsitePageWaitingForServer,
  fetchSingleWebsitePages,
  pageUrlUpdateErrorMessage,
  removeSingleWebsitePageFromStore,
  removeWebsitePagesFromStore,
  saveWebsitesPagesInState,
  setPageLoadStatus,
  toggleWaitingForMovePageToAnotherWebsite,
} from "../../store/websitePages/actions";
import _ from "lodash";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  PATH_HISTORY_LS_KEY,
  notificationDuration,
  projectUrl,
} from "../../data/constants";
import {
  cloneBlogPostWaitingForServer,
  createBlogPostWaitingForServer,
  deleteBlogPostWaitingForServer,
  removeSingleBlogPostFromStore,
  saveBlogsPostsInState,
  setSinglePostStatus,
} from "../../store/blogPosts/actions";
import { createWebsiteFromTemplate } from "../../store/websites/thunks";
import generateRandomSubdomain from "../../helpers/strings/generateRandomSubdomain";
import CreateNewWebsiteModal from "./CreateNewWebsiteModal";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { cloneWebsiteAsync } from "../../helpers/async/cloneWebsiteAsync";
import {
  cloneWebsiteWaitingForServer,
  deleteWebsiteWaitingForServer,
  removeWebsiteFromStore,
  saveSingleWebsiteInState,
  setFullWebsiteLoadStatus,
} from "../../store/websites/actions";
import DeleteButtons from "./DeleteButtons";
import { deleteWebsiteAsync } from "../../helpers/async/deleteWebsiteAsync";
import { clonePageAsync } from "../../helpers/async/clonePageAsync";
import { WebsitesItem, WebsitesState } from "../../store/websites/types";
import {
  WebsitePagesItem,
  WebsitePagesState,
} from "../../store/websitePages/types";
import { BlogsState } from "../../store/blogs/types";
import { BlogPostItem, BlogPostsState } from "../../store/blogPosts/types";
import { movePageAsync } from "../../helpers/async/movePageAsync";
import { deletePageAsync } from "../../helpers/async/deletePageAsync";
import { clonePostAsync } from "../../helpers/async/clonePostAsync";
import { deletePostAsync } from "../../helpers/async/deletePostAsync";
import { saveSingleBlogInState } from "../../store/blogs/actions";
import { fuzzySearch } from "../../helpers/fuzzySearch";
import {
  createAndSavePageAsync,
  getWebsiteInitialPagesLoadStatus,
} from "../../store/websitePages/thunks";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import classNames from "classnames";
import { openNotification } from "../../helpers/openNotification";
import { UserState } from "../../store/user/types";
import { createAndSavePost } from "../../store/blogPosts/thunks";
import { generateUniqueUrl } from "../../helpers/generateUniqueUrl";
import { checkIsWebsitePublished } from "../../helpers/checkIsWebsitePublished";
import { sortByDateFn } from "../../helpers/sortByDateFn";
import { sortByStartWithSearchValueFn } from "../../helpers/sortByStartWithSearchValueFn";
import { checkIfGoogleSheetUrl } from "../../helpers/checkIfGoogleSheetUrl";
import { InputProps } from "antd/lib/input";
import PageTemplatesList from "./PageTemplatesList";
import { MetaState } from "../../store/meta/types";
import { LoadStatus } from "../../enums/enums";

interface Props extends RouteComponentProps {
  // redux start
  meta: MetaState;
  user: UserState;
  auth: AuthState;
  websites: WebsitesState;
  websitesPages: WebsitePagesState;
  blogs: BlogsState;
  blogPosts: BlogPostsState;
  saveWebsitesPagesInState: any;
  removeSingleWebsitePageFromStore: any;
  saveBlogsPostsInState: any;
  createWebsiteFromTemplate: any;
  saveSingleWebsiteInState: any;
  fetchSingleWebsitePages: any;
  removeWebsiteFromStore: any;
  removeWebsitePagesFromStore: any;
  removeSingleBlogPostFromStore: any;
  saveSingleBlogInState: any;
  createAndSavePageAsync: any;
  createWebsitePageWaitingForServer: any;
  createBlogPostWaitingForServer: any;
  cloneWebsitePageWaitingForServer: any;
  toggleWaitingForMovePageToAnotherWebsite: any;
  deleteWebsitePageWaitingForServer: any;
  cloneBlogPostWaitingForServer: any;
  deleteBlogPostWaitingForServer: any;
  cloneWebsiteWaitingForServer: any;
  deleteWebsiteWaitingForServer: any;
  createAndSavePost: () => any;
  pageUrlUpdateErrorMessage: (message: string) => any;
  getWebsiteInitialPagesLoadStatus: any;
  setPageLoadStatus: any;
  setFullWebsiteLoadStatus: any;
  setSinglePostStatus: any;
  // redux end

  navType: "websites" | "pages";
  currentWebsite: any;
  currentWebsitePage: any;
  currentBlogPost: any;
  publishPage: any;
  publishPost: any;
}

interface WebsiteContextDropdownProps extends Props {
  children: React.ReactNode;
  website: WebsitesItem;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const WebsiteContextDropdown = (props: WebsiteContextDropdownProps) => {
  const {
    children,
    auth,
    saveSingleWebsiteInState,
    website,
    fetchSingleWebsitePages,
    removeWebsiteFromStore,
    removeWebsitePagesFromStore,
    history,
    currentWebsite,
    websites,
    cloneWebsiteWaitingForServer,
    deleteWebsiteWaitingForServer,
    dropdownContainerRef,
  } = props;
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    isWaitingForCloneWebsiteResponse,
    isWaitingForDeleteWebsiteResponse,
  } = websites;
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
    if (!flag) {
      setIsDeleteConfirmVisible(false);
    }
  };
  const handleOnMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };
  const handleOnDeleteClick = (e) => {
    if (!isDeleteConfirmVisible) {
      setIsDeleteConfirmVisible(true);
    }
  };

  const duplicateWebsite = () => {
    cloneWebsiteWaitingForServer(true);
    cloneWebsiteAsync({
      subdomain: website.subdomain,
      accessToken: auth.accessToken,
    })
      .then(async (response) => {
        const { subdomain, id } = response.data;
        props.setFullWebsiteLoadStatus({
          websiteId: id,
          status: LoadStatus.LOADED
        })
        await fetchSingleWebsitePages(subdomain, id);
        saveSingleWebsiteInState([response.data]);
        history.push(`/${subdomain}`);
        window.scrollTo(0, 0);
        setVisible(false);
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        cloneWebsiteWaitingForServer(false);
      });
  };

  const deleteWebsite = () => {
    deleteWebsiteWaitingForServer(true);
    deleteWebsiteAsync({
      accessToken: auth.accessToken,
      subdomain: website.subdomain,
    })
      .then((response) => {
        props.setFullWebsiteLoadStatus({
          websiteId: response.data.id,
          status: LoadStatus.DELETED
        })
        removeWebsiteFromStore(website.subdomain);
        removeWebsitePagesFromStore(response.data.id);
        if (website === currentWebsite) {
          localStorage.removeItem(PATH_HISTORY_LS_KEY);
          history.push("/");
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error(error.response);
        const sharedWarningMessage = _.get(error, "response.data.not_owned");
        if (sharedWarningMessage) {
          openNotification(
            "Error",
            sharedWarningMessage,
            "OK",
            "warning",
            notificationDuration.medium
          );
          return;
        }
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        deleteWebsiteWaitingForServer(false);
      });
  };

  const menu = (
    <Menu onClick={handleOnMenuClick}>
      <Menu.Item
        onClick={duplicateWebsite}
        key="duplicate"
        disabled={isWaitingForCloneWebsiteResponse}
      >
        <Icon type={isWaitingForCloneWebsiteResponse ? "loading" : "block"} />
        Duplicate
      </Menu.Item>
      <Menu.Item key="delete" onClick={handleOnDeleteClick}>
        {!isDeleteConfirmVisible && (
          <>
            <Icon type="delete" />
            Delete website
          </>
        )}
        {isDeleteConfirmVisible && (
          <DeleteButtons
            loading={isWaitingForDeleteWebsiteResponse}
            deleteAction={deleteWebsite}
            deleteItemTitle={website.subdomain}
            cancelAction={() => {
              setIsDeleteConfirmVisible(false);
            }}
          />
        )}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayClassName="nav-dropdown__dropdown nav-dropdown__context-dropdown"
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

interface PageContextDropdownProps extends Props {
  children: React.ReactNode;
  page: WebsitePagesItem;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const PageContextDropdown = (props: PageContextDropdownProps) => {
  const {
    children,
    auth,
    history,
    websites,
    currentWebsite,
    saveWebsitesPagesInState,
    page,
    removeSingleWebsitePageFromStore,
    publishPage,
    currentWebsitePage,
    websitesPages,
    cloneWebsitePageWaitingForServer,
    toggleWaitingForMovePageToAnotherWebsite,
    deleteWebsitePageWaitingForServer,
    dropdownContainerRef,
  } = props;
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    isWaitingForCloneWebsitePageResponse,
    isWaitingForMoveWebsitePageResponse,
    isWaitingForPublishPageResponse,
    isWaitingForDeleteWebsitePageResponse,
  } = websitesPages;
  const currentPageWebsite = websites.items.find(
    (website) => website.id === page.website
  );
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
    if (!flag) {
      setIsDeleteConfirmVisible(false);
    }
  };
  const handleOnMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };
  const handleOnDeleteClick = (e) => {
    if (!isDeleteConfirmVisible) {
      setIsDeleteConfirmVisible(true);
    }
  };

  const duplicatePage = () => {
    cloneWebsitePageWaitingForServer(true);
    clonePageAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      pageUrl: page.url || "home",
    })
      .then((response) => {
        props.setPageLoadStatus({
          pageId: response.data.id,
          status: LoadStatus.LOADED,
        })
        saveWebsitesPagesInState([response.data]);
        history.push(`/${currentWebsite.subdomain}/pages/${response.data.id}`);
        window.scrollTo(0, 0);
        setVisible(false);
      })
      .catch((error) => {
        console.error(error.response);
        const warningMessage = _.get(error, "response.data.limit");
        if (warningMessage) {
          openNotification(
            "Error",
            warningMessage,
            "OK",
            "warning",
            notificationDuration.medium
          );
          return;
        }
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        cloneWebsitePageWaitingForServer(false);
      });
  };

  const moveToSubmenuContentArray = websites.items
    .filter((website) => {
      return website.id !== currentWebsite.id;
    })
    .map((website) => {
      const movePage = () => {
        toggleWaitingForMovePageToAnotherWebsite(true);
        movePageAsync({
          accessToken: auth.accessToken,
          newWebsiteId: website.id,
          pageId: page.id,
          subdomain: currentPageWebsite.subdomain,
        })
          .then((response) => {
            props.setPageLoadStatus({
              pageId: page.id,
              status: LoadStatus.LOADED
            })
            saveWebsitesPagesInState([response.data]);
            removeSingleWebsitePageFromStore({
              websiteId: currentPageWebsite.id,
              pageUrl: page.url,
            });
            if (currentWebsitePage === page) {
              history.push(`/${currentWebsite.subdomain}/pages`);
              window.scrollTo(0, 0);
            }
          })
          .catch((error) => {
            console.error(error.response);
            openNotification(
              DEFAULT_ERROR_TITLE,
              DEFAULT_ERROR_MESSAGE,
              "OK",
              "error",
              notificationDuration.medium
            );
          })
          .finally(() => {
            toggleWaitingForMovePageToAnotherWebsite(false);
          });
      };
      return (
        <Menu.Item
          key={website.subdomain}
          onClick={movePage}
          className="force-gray"
          disabled={isWaitingForMoveWebsitePageResponse}
        >
          <span>{getActiveDomain(website)}</span>
        </Menu.Item>
      );
    });

  const changeIsPagePublished = () => {
    publishPage(!page.is_published, page.url, currentPageWebsite);
  };

  const deletePage = () => {
    deleteWebsitePageWaitingForServer(true);
    deletePageAsync({
      accessToken: auth.accessToken,
      subdomain: currentPageWebsite.subdomain,
      pageUrl: page.url || "home",
    })
      .then(() => {
        props.setPageLoadStatus({
          pageId: page.id,
          status: LoadStatus.DELETED,
        })
        removeSingleWebsitePageFromStore({
          websiteId: currentPageWebsite.id,
          pageUrl: page.url,
        });
        if (currentWebsitePage === page) {
          history.push(`/${currentWebsite.subdomain}/pages`);
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        deleteWebsitePageWaitingForServer(false);
      });
  };

  const menu = (
    <Menu onClick={handleOnMenuClick}>
      <Menu.Item
        key="duplicate"
        onClick={duplicatePage}
        disabled={isWaitingForCloneWebsitePageResponse}
      >
        <Icon
          type={isWaitingForCloneWebsitePageResponse ? "loading" : "block"}
        />
        Duplicate
      </Menu.Item>
      <Menu.SubMenu
        onTitleClick={(e) => {
          e.domEvent.stopPropagation();
        }}
        popupClassName="nav-dropdown__submenu--fixed"
        title={
          <>
            <Icon
              type={isWaitingForMoveWebsitePageResponse ? "loading" : "drag"}
            />
            Move to
          </>
        }
        disabled={isWaitingForMoveWebsitePageResponse}
      >
        {moveToSubmenuContentArray}
      </Menu.SubMenu>
      <Menu.Item key="delete" onClick={handleOnDeleteClick}>
        {!isDeleteConfirmVisible && (
          <>
            <Icon type="delete" />
            Delete page
          </>
        )}
        {isDeleteConfirmVisible && (
          <DeleteButtons
            loading={isWaitingForDeleteWebsitePageResponse}
            deleteAction={deletePage}
            deleteItemTitle={page.url}
            cancelAction={() => {
              setIsDeleteConfirmVisible(false);
            }}
          />
        )}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayClassName="nav-dropdown__dropdown nav-dropdown__context-dropdown"
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

interface BloPostContextDropdownProps extends Props {
  children: React.ReactNode;
  blogPost: BlogPostItem;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const BlogPostContextDropdown = (props: BloPostContextDropdownProps) => {
  const {
    children,
    auth,
    history,
    websites,
    blogPost,
    blogs,
    saveBlogsPostsInState,
    removeSingleBlogPostFromStore,
    currentBlogPost,
    blogPosts,
    cloneBlogPostWaitingForServer,
    deleteBlogPostWaitingForServer,
    dropdownContainerRef,
  } = props;
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    isWaitingForCloneBlogPostResponse,
    isWaitingForDeleteBlogPostResponse,
  } = blogPosts;
  const currentPostBlog = blogs.items.find((blog) => blog.id === blogPost.blog);
  const currentPostWebsite = websites.items.find(
    (website) => website.id === currentPostBlog.website
  );
  const { status } = blogPost;
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
    if (!flag) {
      setIsDeleteConfirmVisible(false);
    }
  };
  const handleOnMenuClick = (e) => {
    e.domEvent.stopPropagation();
  };
  const handleOnDeleteClick = (e) => {
    if (!isDeleteConfirmVisible) {
      setIsDeleteConfirmVisible(true);
    }
  };

  const duplicatePost = () => {
    cloneBlogPostWaitingForServer(true);
    clonePostAsync({
      accessToken: auth.accessToken,
      postUrl: blogPost.url,
      subdomain: currentPostWebsite.subdomain,
    })
      .then(({ data }) => {
        props.setSinglePostStatus({
          postId: data.id,
          status: LoadStatus.LOADED,
        })
        saveBlogsPostsInState([data]);
        history.push(`/${currentPostWebsite.subdomain}/blog/${data.id}`);
        window.scrollTo(0, 0);
        setVisible(false);
      })
      .catch((error) => {
        console.error(error.response);
        const warningMessage = _.get(error, "response.data.limit");
        if (warningMessage) {
          openNotification(
            "Error",
            warningMessage,
            "OK",
            "warning",
            notificationDuration.medium
          );
          return;
        }
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        cloneBlogPostWaitingForServer(false);
      });
  };

  const deletePost = () => {
    deleteBlogPostWaitingForServer(true);
    deletePostAsync({
      accessToken: auth.accessToken,
      subdomain: currentPostWebsite.subdomain,
      postUrl: blogPost.url,
    })
      .then(() => {
        props.setSinglePostStatus({
          postId: blogPost.id,
          status: LoadStatus.DELETED,
        })
        removeSingleBlogPostFromStore({
          blogId: currentPostBlog.id,
          postUrl: blogPost.url,
        });
        if (currentBlogPost === blogPost) {
          history.push(`/${currentPostWebsite.subdomain}/blog`);
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        deleteBlogPostWaitingForServer(false);
      });
  };

  const menu = (
    <Menu onClick={handleOnMenuClick} selectedKeys={[status]}>
      <Menu.Item
        key="duplicate"
        onClick={duplicatePost}
        disabled={isWaitingForCloneBlogPostResponse}
      >
        <Icon type={isWaitingForCloneBlogPostResponse ? "loading" : "block"} />
        Duplicate
      </Menu.Item>
      <Menu.Item key="delete" onClick={handleOnDeleteClick}>
        {!isDeleteConfirmVisible && (
          <>
            <Icon type="delete" />
            Delete post
          </>
        )}
        {isDeleteConfirmVisible && (
          <DeleteButtons
            loading={isWaitingForDeleteBlogPostResponse}
            deleteAction={deletePost}
            deleteItemTitle={blogPost.url}
            cancelAction={() => {
              setIsDeleteConfirmVisible(false);
            }}
          />
        )}
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayClassName="nav-dropdown__dropdown nav-dropdown__context-dropdown"
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

interface WebsitesDropdownProps extends Props {
  children: React.ReactNode;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const WebsitesDropdown = (props: WebsitesDropdownProps) => {
  const {
    children,
    websites,
    history,
    currentWebsite,
    createWebsiteFromTemplate,
    websitesPages,
    blogs,
    blogPosts,
    user,
    dropdownContainerRef,
  } = props;
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isWebsiteModalVisible, setIsWebsiteModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<"myWebsites" | "sharedWebsites">(
    "myWebsites"
  );
  const inputRef = useRef<Input>(null);
  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [activeTab]);

  const userId = _.get(user, "data.id");
  const onSubmit = (event, templateSubdomain?, templateWebsiteId?) => {
    createWebsiteFromTemplate({
      event,
      templateSubdomain,
      templateWebsiteId,
      newWebsiteSubdomain: generateRandomSubdomain().toString(),
      hideForm: () => {
        setIsWebsiteModalVisible(false);
      },
      history,
    });
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
    if (flag) {
      setTimeout(() => {
        if (inputRef && inputRef.current && inputRef.current.select) {
          inputRef.current.select();
        }
      }, 0);
    }
    if (flag && currentWebsite) {
      setActiveTab(
        currentWebsite.owner !== userId ? "sharedWebsites" : "myWebsites"
      );
    }
  };
  const handleOnClick = (website) => {
    history.push(`/${website.subdomain}`);
    window.scrollTo(0, 0);
  };

  const menuItemsArray = [...websites.items]
    .filter((website) => {
      return fuzzySearch(searchValue, getActiveDomain(website));
    })
    .filter((website) => {
      if (activeTab === "myWebsites") {
        return website.owner === userId;
      }
      if (activeTab === "sharedWebsites") {
        return website.owner !== userId;
      }
    })
    .sort(sortByDateFn)
    .sort((a, b) =>
      sortByStartWithSearchValueFn(
        getActiveDomain(a),
        getActiveDomain(b),
        searchValue
      )
    )
    .map((website) => {
      const faviconUrl = _.get(website, "favicon_16x16_url");
      const initialPagesStatus =
        props.getWebsiteInitialPagesLoadStatus(website);
      const isPublished =
        initialPagesStatus !== LoadStatus.LOADED
          ? website.is_published
          : checkIsWebsitePublished(website, props.websitesPages.items);
      return (
        <Menu.Item
          key={website.subdomain}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0,
          }}
          onClick={(e) => {
            handleOnClick(website);
          }}
          className="nav-dropdown__navigation-item"
        >
          <Tooltip
            overlayStyle={{ position: "fixed" }}
            placement="right"
            mouseLeaveDelay={0}
            align={{ offset: [50, 0] }}
            title={
              <div style={{ maxWidth: "250px" }}>
                {getActiveDomain(website)}
                <div style={{ color: "rgba(255,255,255,0.65" }}>
                  {isPublished ? "Published" : "Not published"}
                </div>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexGrow: 1,
                padding: "5px 0",
              }}
            >
              {!!faviconUrl && (
                <img
                  src={formatImageUrl(faviconUrl)}
                  style={{
                    width: "14px",
                    height: "14px",
                    objectFit: "contain",
                    opacity: isPublished ? 1 : 0.25,
                  }}
                />
              )}
              {!faviconUrl && (
                <Icon
                  type="global"
                  style={{
                    color: isPublished ? "inherit" : "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              <span className="nav-dropdown__item-name">
                {getActiveDomain(website)}
              </span>
            </div>
          </Tooltip>
          <WebsiteContextDropdown {...props} website={website}>
            <button
              className="nav-dropdown__more-button"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon type="more" />
            </button>
          </WebsiteContextDropdown>
        </Menu.Item>
      );
    });
  const selectedKey = _.get(currentWebsite, "subdomain");
  const menu = (
    <Menu selectedKeys={[selectedKey]}>
      <Menu.Item disabled className="nav-dropdown__tab-container">
        <div className="nav-dropdown__tab-wrapper">
          <button
            className={classNames("nav-dropdown__tab-button", {
              "is-active": activeTab === "myWebsites",
            })}
            onClick={() => setActiveTab("myWebsites")}
          >
            My websites
          </button>
          <button
            className={classNames("nav-dropdown__tab-button", {
              "is-active": activeTab === "sharedWebsites",
            })}
            onClick={() => setActiveTab("sharedWebsites")}
          >
            Shared
          </button>
        </div>
      </Menu.Item>
      <Menu.Item className="nav-dropdown__search-box">
        <Input
          placeholder="Search websites"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          prefix={<Icon type="search" />}
          ref={inputRef}
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.ItemGroup className="nav-dropdown__item-group">
        {menuItemsArray}
        {menuItemsArray.length === 0 && (
          <Menu.Item disabled>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No websites found"
              style={{ margin: "12px 0 8px" }}
            />
          </Menu.Item>
        )}
        <Menu.Item disabled className="nav-dropdown__cta-container">
          <Button
            type="primary"
            block
            onClick={() => {
              setIsWebsiteModalVisible(true);
              setVisible(false);
            }}
          >
            Create website
          </Button>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        overlayClassName="nav-dropdown__dropdown"
        getPopupContainer={() => dropdownContainerRef.current}
      >
        {children}
      </Dropdown>
      <CreateNewWebsiteModal
        isFormVisible={isWebsiteModalVisible}
        onSubmit={onSubmit}
        hideForm={() => {
          setIsWebsiteModalVisible(false);
        }}
        title={"Create a new website 🦄"}
        name={"subdomain"}
        labelText={"Enter a subdomain:"}
        addonAfter={"." + projectUrl}
        placeholder={"pied-piper"}
        errors={websites.createWebsiteErrorMessage}
        isLoading={websites.isWaitingForCreateWebsiteResponse}
        classNameForCreateButton={"js-tour__create-new-site__button"}
      />
    </>
  );
};

interface PagesDropdownProps extends Props {
  children: React.ReactNode;
  showPagesModal: () => void;
  isPageModalVisible: boolean;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const PagesDropdown = (props: PagesDropdownProps) => {
  const {
    children,
    websitesPages,
    history,
    blogs,
    blogPosts,
    websites,
    auth,
    currentWebsite,
    saveSingleBlogInState,
    createAndSavePageAsync,
    createWebsitePageWaitingForServer,
    createBlogPostWaitingForServer,
    createAndSavePost,
    showPagesModal,
    isPageModalVisible,
    meta,
    dropdownContainerRef,
  } = props;

  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState<
    "pages" | "articles" | "extensions"
  >("pages");

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [activeTab]);

  const { isWaitingForCreateWebsitePageResponse } = websitesPages;
  const { isWaitingForCreateBlogPostResponse } = blogPosts;

  const extensionItems = _.get(meta, "items.0.extensions.items", []);
  const extensionCategories = _.get(meta, "items.0.extensions.categories", []);
  const extensionsHasDot = _.get(meta, "items.0.extensions.config.dot", false);

  const currentWebsitePages = websitesPages.items.filter((page) => {
    return page.website === _.get(currentWebsite, "id");
  });

  let currentBlog = blogs.items.find((blog) => {
    return blog.website === _.get(currentWebsite, "id");
  });
  const activeWebsitePage = props.currentWebsitePage || props.currentBlogPost;

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
    if (flag) {
      setTimeout(() => {
        if (inputRef && inputRef.current && inputRef.current.select) {
          inputRef.current.select();
        }
      }, 0);
      const pathArray = history.location.pathname.split("/").filter((i) => !!i);
      if (pathArray[1] === "blog") {
        setActiveTab("articles");
      } else {
        setActiveTab("pages");
      }
    }
  };
  const handleOnClickPage = (pageId: number) => {
    history.push(`/${currentWebsite.subdomain}/pages/${pageId}`);
    window.scrollTo(0, 0);
  };
  const handleOnClickBlogPost = (postId: number) => {
    history.push(`/${currentWebsite.subdomain}/blog/${postId}`);
    window.scrollTo(0, 0);
  };

  const createPost = async () => {
    createAndSavePost().then(({ postData, currentBlog }) => {
      history.push(`/${currentWebsite.subdomain}/blog/${postData.id}`);
      window.scrollTo(0, 0);
    });
  };

  const menuItemsArrayPages = [...websitesPages.items]
    .filter((page) => {
      return page.website === _.get(currentWebsite, "id");
    })
    .filter((page) => {
      return fuzzySearch(searchValue, page.url || "home");
    })
    .sort(sortByDateFn)
    .sort((a, b) => {
      if (a.url === "") return -1;
      if (b.url === "") return 1;
      return 0;
    })
    .sort((a, b) =>
      sortByStartWithSearchValueFn(
        a.url || "home",
        b.url || "home",
        searchValue
      )
    )
    .map((page) => {
      const pageUrl = page.url || "home";
      const hasGoogleSheet = checkIfGoogleSheetUrl(page.api_source_url);
      return (
        <Menu.Item
          key={pageUrl}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0,
          }}
          onClick={(e) => {
            handleOnClickPage(page.id);
          }}
          className="nav-dropdown__navigation-item"
        >
          <Tooltip
            overlayStyle={{ position: "fixed" }}
            placement="right"
            mouseLeaveDelay={0}
            align={{ offset: [50, 0] }}
            title={
              <div style={{ maxWidth: "250px" }}>
                {pageUrl !== "home" && "/"}
                {pageUrl === "home"
                  ? hasGoogleSheet
                    ? ""
                    : "Home page"
                  : pageUrl}
                {hasGoogleSheet && <span>{"/{{pageUrl}}"}</span>}
                {hasGoogleSheet && (
                  <div style={{ margin: "6px 0" }}>
                    This page can be accessed with a dynamic path. Available
                    dynamic paths are generated automatically from your source
                    Google Sheet.
                  </div>
                )}
                <div style={{ color: "rgba(255,255,255,0.65" }}>
                  {page.is_published ? "Published" : "Not published"}
                </div>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexGrow: 1,
                padding: "5px 0",
              }}
            >
              {pageUrl !== "home" && (
                <Icon
                  type="file"
                  style={{
                    color: page.is_published ? "inherit" : "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              {pageUrl === "home" && (
                <Icon
                  type="home"
                  style={{
                    color: page.is_published ? "inherit" : "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              <span
                className="nav-dropdown__item-name"
                style={{ maxWidth: hasGoogleSheet ? 129 : 204 }}
              >
                {pageUrl !== "home" && "/"}
                {pageUrl === "home" ? (hasGoogleSheet ? "" : "Home") : pageUrl}
              </span>
              {hasGoogleSheet && (
                <div
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    fontWeight: "normal",
                    marginLeft: -8,
                  }}
                >
                  {"/{{pageUrl}}"}
                </div>
              )}
            </div>
          </Tooltip>
          <PageContextDropdown {...props} page={page}>
            <button
              className="nav-dropdown__more-button"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon type="more" />
            </button>
          </PageContextDropdown>
        </Menu.Item>
      );
    });

  const menuItemsArrayBlogPosts = [...blogPosts.items]
    .filter((blogPost) => {
      if (!currentBlog) return false;
      return blogPost.blog === currentBlog.id;
    })
    .filter((blogPost) => {
      return fuzzySearch(searchValue, blogPost.title || blogPost.url);
    })
    .sort(sortByDateFn)
    .sort((a, b) =>
      sortByStartWithSearchValueFn(
        a.title || a.url,
        b.title || b.url,
        searchValue
      )
    )
    .map((blogPost) => {
      const displayedTitle = blogPost.title || `/${blogPost.url}`;
      return (
        <Menu.Item
          key={blogPost.url}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0,
          }}
          onClick={(e) => {
            handleOnClickBlogPost(blogPost.id);
          }}
          className="nav-dropdown__navigation-item"
        >
          <Tooltip
            overlayStyle={{ position: "fixed" }}
            placement="right"
            mouseLeaveDelay={0}
            align={{ offset: [50, 0] }}
            title={
              <div style={{ maxWidth: "250px" }}>
                {displayedTitle}
                <div style={{ color: "rgba(255,255,255,0.65" }}>
                  Visibility:{" "}
                  {blogPost.status === "public"
                    ? "Published"
                    : blogPost.status === "direct_url"
                    ? "Direct URL"
                    : "Draft"}
                </div>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexGrow: 1,
                padding: "5px 0",
              }}
            >
              <Icon
                type="profile"
                style={{
                  color:
                    blogPost.status === "public"
                      ? "inherit"
                      : "rgba(0,0,0,0.25)",
                }}
              />
              <span className="nav-dropdown__item-name">{displayedTitle}</span>
            </div>
          </Tooltip>
          <BlogPostContextDropdown {...props} blogPost={blogPost}>
            <button
              className="nav-dropdown__more-button"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon type="more" />
            </button>
          </BlogPostContextDropdown>
        </Menu.Item>
      );
    });

  let activePageKey;
  if (activeWebsitePage) {
    activePageKey =
      activeWebsitePage.url === "" ? "home" : activeWebsitePage.url;
  }
  const menu = (
    <Menu selectedKeys={[activePageKey]}>
      <Menu.Item disabled className="nav-dropdown__tab-container">
        <div className="nav-dropdown__tab-wrapper">
          <button
            className={classNames("nav-dropdown__tab-button", {
              "is-active": activeTab === "pages",
            })}
            onClick={() => setActiveTab("pages")}
          >
            Pages
          </button>
          <button
            className={classNames("nav-dropdown__tab-button", {
              "is-active": activeTab === "articles",
            })}
            onClick={() => setActiveTab("articles")}
          >
            Blog
          </button>
          <Badge
            dot
            style={{
              background: "#f5222d",
              top: "10px",
              right: "1px",
              display: extensionsHasDot ? "block" : "none",
            }}
          >
            <button
              className={classNames("nav-dropdown__tab-button", {
                "is-active": activeTab === "extensions",
              })}
              onClick={() => setActiveTab("extensions")}
            >
              Extensions
            </button>
          </Badge>
        </div>
      </Menu.Item>
      {activeTab !== "extensions" && (
        <Menu.Item className="nav-dropdown__search-box">
          <Input
            placeholder={
              activeTab === "pages"
                ? "Search pages"
                : activeTab === "articles"
                ? "Search blog posts"
                : activeTab === "extensions"
                ? "Search extensions"
                : "Search"
            }
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            prefix={<Icon type="search" />}
            ref={inputRef}
          />
        </Menu.Item>
      )}
      {activeTab !== "extensions" && <Menu.Divider />}
      {activeTab === "pages" && (
        <Menu.ItemGroup className="nav-dropdown__item-group">
          {menuItemsArrayPages}
          {menuItemsArrayPages.length === 0 && (
            <Menu.Item disabled>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No pages found"
                style={{ margin: "12px 0 8px" }}
              />
            </Menu.Item>
          )}
          <Menu.Item disabled className="nav-dropdown__cta-container">
            <Button
              type="primary"
              block
              loading={isWaitingForCreateWebsitePageResponse}
              onClick={showPagesModal}
            >
              Add page
            </Button>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {activeTab === "articles" && (
        <Menu.ItemGroup className="nav-dropdown__item-group">
          {menuItemsArrayBlogPosts}
          {menuItemsArrayBlogPosts.length === 0 && (
            <Menu.Item disabled>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No blog posts found"
                style={{ margin: "12px 0 8px" }}
              />
            </Menu.Item>
          )}
          <Menu.Item disabled className="nav-dropdown__cta-container">
            <Button
              type="primary"
              block
              loading={isWaitingForCreateBlogPostResponse}
              onClick={createPost}
            >
              Add blog post
            </Button>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {activeTab === "extensions" && (
        <Menu.ItemGroup className="nav-dropdown__item-group">
          {extensionItems
            .sort((a, b) => {
              const aPriority = a.priority || 0;
              const bPriority = b.priority || 0;
              return bPriority - aPriority;
            })
            .map((item, i) => {
              return (
                <Menu.Item style={{ padding: 0 }} key={i}>
                  <Tooltip
                    title={
                      <div
                        dangerouslySetInnerHTML={{ __html: item.tooltipHtml }}
                      />
                    }
                    placement="rightTop"
                    mouseLeaveDelay={0}
                    overlayStyle={{ position: "fixed" }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.itemHtml }} />
                  </Tooltip>
                </Menu.Item>
              );
            })}
          {extensionCategories.map((category, i) => {
            return (
              <Menu.Item disabled style={{ padding: 0 }} key={i}>
                <Tooltip
                  title="Coming soon"
                  placement="right"
                  mouseLeaveDelay={0}
                  overlayStyle={{ position: "fixed" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      padding: "5px 12px",
                    }}
                  >
                    <Icon type={category.icon} />
                    <span
                      className="nav-dropdown__item-name"
                      dangerouslySetInnerHTML={{ __html: category.title }}
                    />
                  </div>
                </Tooltip>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayClassName="nav-dropdown__dropdown"
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

interface DropdownMainProps extends Props {
  children: React.ReactNode;
  showPagesModal: () => void;
  isPageModalVisible: boolean;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
}
const DropdownMain = (props: DropdownMainProps) => {
  const {
    navType,
    children,
    showPagesModal,
    isPageModalVisible,
    dropdownContainerRef,
  } = props;
  if (navType === "websites") {
    return <WebsitesDropdown {...props}>{children}</WebsitesDropdown>;
  }
  if (navType === "pages") {
    return (
      <PagesDropdown
        isPageModalVisible={isPageModalVisible}
        showPagesModal={showPagesModal}
        {...props}
      >
        {children}
      </PagesDropdown>
    );
  }
  return <>{children}</>;
};

interface UrlInputProps extends InputProps {
  urlInputValue: string;
  setUrlInputValue: (value: string) => void;
  currentWebsitePages: any;
  websitesPages: any;
  currentWebsite: any;
  pageUrlUpdateErrorMessage: (message: string) => void;
}
const UrlInput = (props: UrlInputProps) => {
  const {
    urlInputValue,
    setUrlInputValue,
    currentWebsitePages,
    websitesPages,
    currentWebsite,
    pageUrlUpdateErrorMessage,
    ...nativeProps
  } = props;
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    let pageUrl = generateUniqueUrl("untitled", currentWebsitePages);
    const currentHomePage = websitesPages.items
      .filter((page) => {
        return page.website === _.get(currentWebsite, "id");
      })
      .find((page) => {
        return page.url === "";
      });
    if (!currentHomePage) {
      pageUrl = "";
    }

    setUrlInputValue(pageUrl);

    if (inputRef && inputRef.current && inputRef.current.select) {
      setTimeout(() => {
        inputRef.current.select();
      }, 0);
    }

    const handlePressEnter = (e) => {
      if (e.key === "Enter") {
        if (inputRef && inputRef.current && inputRef.current.blur) {
          inputRef.current.blur();
        }
      }
    };
    window.addEventListener("keypress", handlePressEnter);
    return () => {
      window.removeEventListener("keypress", handlePressEnter);
    };
  }, []);

  useEffect(() => {
    pageUrlUpdateErrorMessage("");
    return () => {
      pageUrlUpdateErrorMessage("");
    };
  }, [urlInputValue]);

  return (
    <Input
      {...nativeProps}
      ref={inputRef}
      value={urlInputValue}
      onChange={(e) => {
        setUrlInputValue(e.target.value);
      }}
    ></Input>
  );
};

interface NavDropdownState {
  isPageModalVisible: boolean;
  urlInputValue: string;
}
class NavDropdown extends Component<Props, NavDropdownState> {
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isPageModalVisible: false,
      urlInputValue: "",
    };
    this.dropdownContainerRef = React.createRef();
  }
  setUrlInputValue = (value: string) => {
    this.setState({
      urlInputValue: value,
    });
  };
  showPagesModal = () => {
    this.setState({
      isPageModalVisible: true,
    });
  };

  handlePagesModalCancel = (e) => {
    this.setState({
      isPageModalVisible: false,
    });
  };
  render() {
    const { isPageModalVisible } = this.state;
    const {
      currentWebsite,
      currentWebsitePage,
      currentBlogPost,
      navType,
      websitesPages,
      createAndSavePageAsync,
      websites,
      history,
      createWebsitePageWaitingForServer,
      pageUrlUpdateErrorMessage,
    } = this.props;
    const currentWebsitePages = websitesPages.items.filter((page) => {
      return page.website === _.get(currentWebsite, "id");
    });
    const pathArray = history.location.pathname.split("/").filter((i) => !!i);
    let activeItemTitle =
      navType === "websites"
        ? "My websites"
        : pathArray[1] === "blog"
        ? "Blog"
        : "Pages";

    if (navType === "websites" && currentWebsite) {
      activeItemTitle = getActiveDomain(currentWebsite);
    }
    if (navType === "pages") {
      if (currentBlogPost) {
        activeItemTitle = currentBlogPost.title || currentBlogPost.url;
      }
      if (currentWebsitePage) {
        activeItemTitle = currentWebsitePage.url || "Home";
      }
    }

    return (
      <div className="nav-dropdown" ref={this.dropdownContainerRef}>
        <DropdownMain
          isPageModalVisible={isPageModalVisible}
          showPagesModal={this.showPagesModal}
          dropdownContainerRef={this.dropdownContainerRef}
          {...this.props}
        >
          <a
            className="nav-dropdown__link nav-dropdown__item ant-dropdown-link"
            href="#"
          >
            <span className="nav-dropdown__item-name">{activeItemTitle}</span>{" "}
            <Icon type="down" style={{ color: "white" }} />
          </a>
        </DropdownMain>
        <Modal
          visible={isPageModalVisible}
          onCancel={this.handlePagesModalCancel}
          centered
          title="Add new page"
          footer={null}
          width={"100%"}
          className="page-templates"
          destroyOnClose
          style={{ maxWidth: 1200, padding: "0 16px" }}
        >
          <div>
            <div style={{ marginBottom: 24, maxWidth: 460, width: "100%" }}>
              <UrlInput
                addonBefore={
                  <div
                    style={{
                      maxWidth: "150px",
                      direction: "rtl",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {getActiveDomain(currentWebsite)}/&lrm;
                  </div>
                }
                style={{ width: "100%" }}
                defaultValue="untitled"
                placeholder="about-us"
                urlInputValue={this.state.urlInputValue}
                setUrlInputValue={this.setUrlInputValue}
                currentWebsitePages={currentWebsitePages}
                currentWebsite={currentWebsite}
                websitesPages={websitesPages}
                pageUrlUpdateErrorMessage={pageUrlUpdateErrorMessage}
              />
              <div
                className="settings-box__error_message"
                style={{
                  opacity:
                    websitesPages.pageUrlUpdateErrorMessage === undefined
                      ? 0
                      : 1,
                }}
              >
                {websitesPages.pageUrlUpdateErrorMessage}
              </div>
            </div>
            <PageTemplatesList
              onSuccess={this.handlePagesModalCancel}
              currentPageUrl={this.state.urlInputValue}
              setUrlInputValue={this.setUrlInputValue}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    websites: state.websites,
    websitesPages: state.websitesPages,
    blogs: state.blogs,
    blogPosts: state.blogPosts,
    user: state.user,
    meta: state.meta,
  };
};
export default connect(mapStateToProps, {
  saveWebsitesPagesInState,
  removeSingleWebsitePageFromStore,
  saveBlogsPostsInState,
  createWebsiteFromTemplate,
  saveSingleWebsiteInState,
  fetchSingleWebsitePages,
  removeWebsiteFromStore,
  removeWebsitePagesFromStore,
  removeSingleBlogPostFromStore,
  saveSingleBlogInState,
  createAndSavePageAsync,
  createWebsitePageWaitingForServer,
  createBlogPostWaitingForServer,
  cloneWebsitePageWaitingForServer,
  toggleWaitingForMovePageToAnotherWebsite,
  deleteWebsitePageWaitingForServer,
  cloneBlogPostWaitingForServer,
  deleteBlogPostWaitingForServer,
  cloneWebsiteWaitingForServer,
  deleteWebsiteWaitingForServer,
  createAndSavePost,
  pageUrlUpdateErrorMessage,
  getWebsiteInitialPagesLoadStatus,
  setPageLoadStatus,
  setFullWebsiteLoadStatus,
  setSinglePostStatus,
})(withRouter(NavDropdown));
