import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NavDropdown from "./NavDropdown";
import "./NavDropdownItems.css";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";
import { getWebsiteInitialPagesLoadStatus } from "../../store/websitePages/thunks";
import { LoadStatus } from "../../enums/enums";
import { getInitialPostsStatus } from "../../store/blogPosts/thunks";
import SkeletonLoader from "./SkeletonLoader";

interface Props extends RouteComponentProps {
  websites: any;
  blogs: any;
  websitesPages: any;
  blogPosts: any;
  updateActiveDashboardTab: any;
  publishPage: any;
  publishPost: any;

  getCurrentStoreData: GetCurrentStoreData;
  getWebsiteInitialPagesLoadStatus: any;
  getInitialPostsStatus: any;
}

class NavDropdownItems extends Component<Props> {
  displayWebsitesNav(
    currentWebsite: any,
    currentWebsitePage: any,
    currentBlogPost: any
  ) {
    const websitesArray = _.get(this.props.websites, "items", []);
    if (websitesArray.length === 0) return null;
    return (
      <span>
        <NavDropdown
          navType="websites"
          currentWebsitePage={currentWebsitePage}
          currentWebsite={currentWebsite}
          currentBlogPost={currentBlogPost}
          publishPage={this.props.publishPage}
          publishPost={this.props.publishPost}
        />
      </span>
    );
  }

  displayWebsitePagesNav(
    currentWebsite: any,
    currentWebsitePage: any,
    currentBlogPost: any
  ) {
    if (!currentWebsite) return null;
    return (
      <span>
        <span className="nav-dropdown-items__nav_divider">/</span>
        <NavDropdown
          navType="pages"
          currentWebsitePage={currentWebsitePage}
          currentWebsite={currentWebsite}
          currentBlogPost={currentBlogPost}
          publishPage={this.props.publishPage}
          publishPost={this.props.publishPost}
        />
      </span>
    );
  }
  
  render() {
    if (!this.props.websites.dataFetched) {
      return (
        <span style={{ color: "#fff" }}>
          <SkeletonLoader width="88px" />{" "}
          <span className="nav-dropdown-items__nav_divider">/</span> <SkeletonLoader width="88px" />
        </span>
      );
    }

    const { currentWebsite, currentWebsitePage, currentBlogPost, currentBlog } =
      this.props.getCurrentStoreData();

    const fillTemplateProgress = _.get(
      currentWebsite,
      "fillTemplateData.status"
    );

    if (fillTemplateProgress === "inProgress") return null;

    const pagesStatus =
      this.props.getWebsiteInitialPagesLoadStatus(currentWebsite);
    const postsStatus = this.props.getInitialPostsStatus(currentBlog);
    const isPageNavVisible =
      pagesStatus === LoadStatus.LOADED && postsStatus === LoadStatus.LOADED;

    return (
      <div className="nav-dropdown-items">
        {this.displayWebsitesNav(
          currentWebsite,
          currentWebsitePage,
          currentBlogPost
        )}
        {isPageNavVisible &&
          this.displayWebsitePagesNav(
            currentWebsite,
            currentWebsitePage,
            currentBlogPost
          )}
        {!isPageNavVisible && !!currentWebsite && (
          <span style={{ color: "#fff" }}>
            {" "}
            <span className="nav-dropdown-items__nav_divider">/</span> <SkeletonLoader width="88px" />
          </span>
        )}
      </div>
    );
  }
}

export default connect(null, {
  getCurrentStoreData,
  getWebsiteInitialPagesLoadStatus,
  getInitialPostsStatus,
})(withRouter(NavDropdownItems));
