import axios from "axios";
import { api, apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { ChangeBlogNavIntegrationId } from "./types";
import { DEFAULT_ERROR_TITLE, notificationDuration } from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";

const actionsNames = {
  SAVE_BLOGS_DATA: "SAVE_BLOGS_DATA" as string,
  BLOGS_DATA_FETCHED: "BLOGS_DATA_FETCHED" as string,

  CREATE_BLOG_WAITING_FOR_SERVER: "CREATE_BLOG_WAITING_FOR_SERVER" as string,
  CREATE_BLOG_ERROR_MESSAGE: "CREATE_BLOG_ERROR_MESSAGE" as string,
  ADD_SINGLE_BLOG_DATA: "ADD_SINGLE_BLOG_DATA" as string,

  TOGGLE_DETECTED_NAV_CHANGES: "TOGGLE_DETECTED_NAV_CHANGES" as string,
  TOGGLE_DETECTED_FOOTER_CHANGES: "TOGGLE_DETECTED_FOOTER_CHANGES" as string,

  NAV_CHANGES_WAITING_FOR_SERVER: "NAV_CHANGES_WAITING_FOR_SERVER" as string,
  FOOTER_CHANGES_WAITING_FOR_SERVER: "FOOTER_CHANGES_WAITING_FOR_SERVER" as string,

  CLONE_BLOG_WAITING_FOR_SERVER: "CLONE_BLOG_WAITING_FOR_SERVER" as string,

  DELETE_BLOG_WAITING_FOR_SERVER: "DELETE_BLOG_WAITING_FOR_SERVER" as string,

  REMOVE_BLOG_FROM_STORE: "REMOVE_BLOG_FROM_STORE" as string,

  SAVE_BLOG_NAV_IN_STATE: "SAVE_BLOG_NAV_IN_STATE" as string,
  SAVE_BLOG_FOOTER_IN_STATE: "SAVE_BLOG_FOOTER_IN_STATE" as string,

  CHANGE_BLOG_NAV_COLOR: "CHANGE_BLOG_NAV_COLOR" as string,

  COMMON_BLOG_UPDATE_WAITING_FOR_SERVER: "COMMON_BLOG_UPDATE_WAITING_FOR_SERVER" as string,

  URL_UPDATE_WAITING_FOR_SERVER: "URL_UPDATE_WAITING_FOR_SERVER" as string,
  URL_UPDATE_TOGGLE_INVALID_UPDATE: "URL_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  URL_UPDATE_ERROR_MESSAGE: "URL_UPDATE_ERROR_MESSAGE" as string,

  BLOG_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER: "BLOG_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_CUSTOM_CODE_UPDATE_ERROR_MESSAGE: "BLOG_CUSTOM_CODE_UPDATE_ERROR_MESSAGE" as string,

  BLOG_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER: "BLOG_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE: "BLOG_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE" as string,

  BLOG_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER: "BLOG_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE: "BLOG_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE" as string,

  BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER: "BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE: "BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE" as string,

  BLOG_META_TITLE_UPDATE_WAITING_FOR_SERVER: "BLOG_META_TITLE_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_META_TITLE_UPDATE_ERROR_MESSAGE: "BLOG_META_TITLE_UPDATE_ERROR_MESSAGE" as string,

  BLOG_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER: "BLOG_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER" as string,
  BLOG_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE: "BLOG_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  BLOG_META_DESCRIPTION_UPDATE_ERROR_MESSAGE: "BLOG_META_DESCRIPTION_UPDATE_ERROR_MESSAGE" as string,

  EDIT_LOADED_BLOGS_DATA: "EDIT_LOADED_BLOGS_DATA",

  CHANGE_BLOG_NAV_INTEGRATION_ID: "CHANGE_BLOG_NAV_INTEGRATION_ID",

  SET_SINGLE_BLOG_LOAD_STATUS: "SET_SINGLE_BLOG_LOAD_STATUS",
  SAVE_SINGLE_FULL_BLOG_IN_STATE: "SAVE_SINGLE_FULL_BLOG_IN_STATE",
};

export const fetchBlogs = (successCallback?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(toggleBlogsFetched(false));
    let accessToken = getState().auth.accessToken;
    axios
      .get(`${apiUrlBase}/api/v1/blogs/fetch_initial_blogs`, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        dispatch(saveBlogsInState(response.data));
        dispatch(toggleBlogsFetched(true));

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          "Couldn't fetch the list of blogs. Please contact us.",
          "OK",
          "error",
          notificationDuration.long
        );
      })
      .then((response) => {});
  };
};

export const saveBlogsInState = (data: string) => {
  return {
    type: actionsNames.SAVE_BLOGS_DATA as string,
    payload: data as string,
  };
};
export const saveSingleBlogInState = (data: any) => {
  return {
    type: actionsNames.ADD_SINGLE_BLOG_DATA as string,
    payload: data as string,
  };
};

export const toggleBlogsFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.BLOGS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const createBlogWaitingForServer = (
  isWaitingForCreateBlogResponse: boolean
) => {
  return {
    type: actionsNames.CREATE_BLOG_WAITING_FOR_SERVER as string,
    payload: isWaitingForCreateBlogResponse as boolean,
  };
};
export const createBlogErrorMessage = (
  createBlogErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.CREATE_BLOG_ERROR_MESSAGE as string,
    payload: createBlogErrorMessage as string | undefined,
  };
};

export const cloneBlogWaitingForServer = (
  isWaitingForCloneBlogResponse: boolean
) => {
  return {
    type: actionsNames.CLONE_BLOG_WAITING_FOR_SERVER as string,
    payload: isWaitingForCloneBlogResponse as boolean,
  };
};
export const deleteBlogWaitingForServer = (
  isWaitingForDeleteBlogResponse: boolean
) => {
  return {
    type: actionsNames.DELETE_BLOG_WAITING_FOR_SERVER as string,
    payload: isWaitingForDeleteBlogResponse as boolean,
  };
};

export const removeBlogFromStore = (websiteId: string) => {
  return {
    type: actionsNames.REMOVE_BLOG_FROM_STORE as string,
    payload: websiteId as string,
  };
};

export const saveBlogNavInState = (settings: any) => {
  return {
    type: actionsNames.SAVE_BLOG_NAV_IN_STATE as string,
    payload: settings as any,
  };
};
export const changeBlogNavColor = (settings: any) => {
  return {
    type: actionsNames.CHANGE_BLOG_NAV_COLOR as string,
    payload: settings as any,
  };
};
export const saveBlogFooterInState = (settings: any) => {
  return {
    type: actionsNames.SAVE_BLOG_FOOTER_IN_STATE as string,
    payload: settings as any,
  };
};

export const toggleBlogNavEditionsDetected = (
  blogNavEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_NAV_CHANGES as string,
    payload: blogNavEditionsExist as boolean,
  };
};
export const toggleBlogFooterEditionsDetected = (
  blogFooterEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_FOOTER_CHANGES as string,
    payload: blogFooterEditionsExist as boolean,
  };
};

export const toggleWaitingForResponseOnBlogNavEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.NAV_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForResponseOnBlogFooterEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.FOOTER_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const toggleWaitingForCommonBlogUpdate = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.COMMON_BLOG_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const urlUpdateWaitingForServer = (
  isWaitingForUrlUpdateResponse: boolean
) => {
  return {
    type: actionsNames.URL_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForUrlUpdateResponse as boolean,
  };
};
export const urlUpdateToggleInvalidAttempt = (
  isInvalidUrlUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.URL_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidUrlUpdateAttempt as boolean,
  };
};
export const urlUpdateErrorMessage = (
  UrlUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.URL_UPDATE_ERROR_MESSAGE as string,
    payload: UrlUpdateErrorMessage as string | undefined,
  };
};

export const blogCustomCodeUpdateWaitingForServer = (
  isWaitingForBlogCustomCodeUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogCustomCodeUpdateResponse as boolean,
  };
};
export const blogCustomCodeUpdateToggleInvalidAttempt = (
  isInvalidBlogCustomCodeUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogCustomCodeUpdateAttempt as boolean,
  };
};
export const blogCustomCodeUpdateErrorMessage = (
  blogCustomCodeUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_UPDATE_ERROR_MESSAGE as string,
    payload: blogCustomCodeUpdateErrorMessage as string | undefined,
  };
};

export const blogExtraCustomCodeUpdateWaitingForServer = (
  isWaitingForBlogExtraCustomCodeUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogExtraCustomCodeUpdateResponse as boolean,
  };
};
export const blogExtraCustomCodeUpdateToggleInvalidAttempt = (
  isInvalidBlogCustomCodeUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogCustomCodeUpdateAttempt as boolean,
  };
};
export const blogExtraCustomCodeUpdateErrorMessage = (
  blogCustomCodeUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE as string,
    payload: blogCustomCodeUpdateErrorMessage as string | undefined,
  };
};

export const blogCustomCodePostsUpdateWaitingForServer = (
  isWaitingForBlogCustomCodePostsUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogCustomCodePostsUpdateResponse as boolean,
  };
};
export const blogCustomCodePostsUpdateToggleInvalidAttempt = (
  isInvalidBlogCustomCodePostsUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogCustomCodePostsUpdateAttempt as boolean,
  };
};
export const blogCustomCodePostsUpdateErrorMessage = (
  blogCustomCodePostsUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE as string,
    payload: blogCustomCodePostsUpdateErrorMessage as string | undefined,
  };
};

export const blogExtraCustomCodePostsUpdateWaitingForServer = (
  isWaitingForBlogExtraCustomCodePostsUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogExtraCustomCodePostsUpdateResponse as boolean,
  };
};
export const blogExtraCustomCodePostsUpdateToggleInvalidAttempt = (
  isInvalidBlogCustomCodePostsUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogCustomCodePostsUpdateAttempt as boolean,
  };
};
export const blogExtraCustomCodePostsUpdateErrorMessage = (
  blogCustomCodePostsUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_ERROR_MESSAGE as string,
    payload: blogCustomCodePostsUpdateErrorMessage as string | undefined,
  };
};

export const blogMetaTitleUpdateWaitingForServer = (
  isWaitingForBlogMetaTitleUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_META_TITLE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogMetaTitleUpdateResponse as boolean,
  };
};
export const blogMetaTitleUpdateToggleInvalidAttempt = (
  isInvalidBlogMetaTitleUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogMetaTitleUpdateAttempt as boolean,
  };
};
export const blogMetaTitleUpdateErrorMessage = (
  blogMetaTitleUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_META_TITLE_UPDATE_ERROR_MESSAGE as string,
    payload: blogMetaTitleUpdateErrorMessage as string | undefined,
  };
};

export const blogMetaDescriptionUpdateWaitingForServer = (
  isWaitingForBlogMetaDescriptionUpdateResponse: boolean
) => {
  return {
    type: actionsNames.BLOG_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForBlogMetaDescriptionUpdateResponse as boolean,
  };
};
export const blogMetaDescriptionUpdateToggleInvalidAttempt = (
  isInvalidBlogMetaDescriptionUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.BLOG_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidBlogMetaDescriptionUpdateAttempt as boolean,
  };
};
export const blogMetaDescriptionUpdateErrorMessage = (
  blogMetaDescriptionUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.BLOG_META_DESCRIPTION_UPDATE_ERROR_MESSAGE as string,
    payload: blogMetaDescriptionUpdateErrorMessage as string | undefined,
  };
};
export const editLoadedBlogsData = (payload) => {
  return {
    type: actionsNames.EDIT_LOADED_BLOGS_DATA,
    payload,
  };
};
export const changeBlogNavIntegrationId: ChangeBlogNavIntegrationId = (
  payload
) => {
  return {
    type: actionsNames.CHANGE_BLOG_NAV_INTEGRATION_ID,
    payload,
  };
};

export const setSingleBlogLoadStatus = (payload) => {
  return {
    type: actionsNames.SET_SINGLE_BLOG_LOAD_STATUS,
    payload,
  };
}
export const saveSingleFullBlogInState = (payload) => {
  return {
    type: actionsNames.SAVE_SINGLE_FULL_BLOG_IN_STATE,
    payload,
  };
}

export default actionsNames;
