import React from "react";
import trimStringTo from "../../../../helpers/strings/trimStringTo";
import NoTitleWarning from "../../NoTitleWarning";

interface Props {
  item: any;
  blogHomePageURL?: string;
}

const PageTitle = (props: Props): JSX.Element => {
  const { item, blogHomePageURL } = props;
  let prefix;
  
  if (item.url === "") {
    prefix = (
      <span className="html-export__page_title_prefix">Home page</span>
    );
  } else if (item.url === "blog") {
    prefix = (
      <span className="html-export__page_title_prefix">Blog home page</span>
    );
  } else {
    let pageUrl = item.url;
    if (blogHomePageURL && blogHomePageURL !== "") {
      pageUrl = blogHomePageURL + "/" + pageUrl;
    }
    prefix = (
      <span className="html-export__page_title_prefix">
        /{pageUrl}
      </span>
    );
  }

  return (
    <span>
      {prefix}
    </span>
  );
};

export default PageTitle;
