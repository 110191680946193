import React, { Component } from "react";
import "./EditorScreenPageControls.css";
import { Button, Drawer, Icon } from "antd";
import SettingsDrawerContent from "./SettingsDrawerContent";
import SkeletonLoader from "./SkeletonLoader";

interface Props {
  websites: any;
  websitesPages: any;
  publishPage: any;
  user: any;
  history: any;
  saveWebsiteDataInStore: any;
  saveWebsitePageDataInStore: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveBlogDataInStore: any;
  saveBlogPostDataInStore: any;
  publishPost: any;
  drawerWidth: number | string;
  setDrawerWidth: any;
}
interface MyState {
  openPageUrl: "";
  isSettingsDrawerVisible: boolean;
}

class StarterScreenControls extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      openPageUrl: "",
      isSettingsDrawerVisible: false,
    });
  }

  showDrawer = () => {
    this.setState({
      isSettingsDrawerVisible: true,
    });
  };
  hideDrawer = () => {
    this.setState({
      isSettingsDrawerVisible: false,
    });
  };

  render() {
    if (!this.props.websites.dataFetched) {
      return <div><SkeletonLoader width="128px" /></div>
    }
    if (!this.props.user.dataFetched) {
      return <div><SkeletonLoader width="128px" /></div>
    }
    return (
      <>
        <ul className="editor-screen-page-controls">
          <li className="editor-screen-page-controls__item">
            <Button
              ghost
              size="small"
              type="default"
              htmlType="button"
              onClick={this.showDrawer}
            >
              <Icon type="setting" />
              Settings
            </Button>
          </li>
        </ul>
        <Drawer
          title="Settings"
          visible={this.state.isSettingsDrawerVisible}
          onClose={this.hideDrawer}
          className="settings-drawer"
          zIndex={1000}
          width={this.props.drawerWidth}
          afterVisibleChange={(visible) => {
            if (visible) {
              document.body.style.removeProperty("overflow");
              document.body.style.removeProperty("touch-action");
            }
          }}
          destroyOnClose
        >
          <SettingsDrawerContent
            history={this.props.history}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            saveWebsitePageDataInStore={this.props.saveWebsitePageDataInStore}
            publishPage={this.props.publishPage}
            saveImageInfoInDB={this.props.saveImageInfoInDB}
            removeImageFromDB={this.props.removeImageFromDB}
            type="starter"
            saveBlogDataInStore={this.props.saveBlogDataInStore}
            saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
            publishPost={this.props.publishPost}
            setDrawerWidth={this.props.setDrawerWidth}
          />
        </Drawer>
      </>
    );
  }
}

export default StarterScreenControls;
