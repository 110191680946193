import _ from "lodash";
import { LoadStatus } from "../../enums/enums";

export const getSingleBlogLoadStatus = (blog) => {
  return (dispatch, getState) => {
    const blogs = getState().blogs;
    if (!blog && blogs.dataFetched) {
      return LoadStatus.LOADED
    }
    if (!blog) {
      return LoadStatus.NO_DATA;
    }
    const currentStatus = blogs.singleBlogLoadStatusArray.find((item) => {
      return item.blogId === blog.id;
    })
    return _.get(currentStatus, "status", LoadStatus.NOT_LOADED);
  };
};
