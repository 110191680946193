import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  GetCurrentStoreData,
  SavePartialWebsiteData,
  ToggleIsCreateWebhookWaitingForServer,
  Webhooks,
  WebsitesState,
} from "../../store/websites/types";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import _ from "lodash";
import { Input, Button, message } from "antd";
import { AuthState } from "../../store/auth/types";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../store/websites/thunks";
import ClipboardJS from "clipboard";
import { toggleIsCreateWebhookWaitingForServer } from "../../store/websites/actions";
import { manageWebhooksAsync } from "../../helpers/async/manageWebhooksAsync";
import { createBlogAsync } from "../../helpers/async/createBlogAsync";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  defaultBlogUrl,
  notificationDuration,
} from "../../data/constants";
import { saveSingleBlogInState, setSingleBlogLoadStatus } from "../../store/blogs/actions";
import { openNotification } from "../../helpers/openNotification";
import { LoadStatus } from "../../enums/enums";
import { setInitialPostsStatus } from "../../store/blogPosts/actions";

interface Props {
  websites: WebsitesState;
  auth: AuthState;
  savePartialWebsiteData: SavePartialWebsiteData;
  toggleIsCreateWebhookWaitingForServer: ToggleIsCreateWebhookWaitingForServer;
  getCurrentStoreData: GetCurrentStoreData;
  saveSingleBlogInState: any;
  setInitialPostsStatus: any;
  setSingleBlogLoadStatus: any;

  webhook: keyof Webhooks;
  setBlogUrl: (blog_url: string) => void;
}

const WebhookMenu = (props: Props): JSX.Element => {
  const {
    websites,
    webhook,
    auth,
    savePartialWebsiteData,
    toggleIsCreateWebhookWaitingForServer,
    getCurrentStoreData,
    saveSingleBlogInState,
    setBlogUrl,
  } = props;
  const currentWebsite = getCurrentWebsite(websites.items);
  const webhookToken = _.get(currentWebsite, ["webhooks", webhook]);

  const inputId = `webhook-url-input-${webhook}`;
  const buttonId = `webhook-url-copy-button-${webhook}`;

  useEffect(() => {
    const clipboard = new ClipboardJS(`#${buttonId}`);
    return () => {
      clipboard.destroy();
    };
  }, []);

  const handleOnCreateWebhook = async () => {
    toggleIsCreateWebhookWaitingForServer(true);

    let currentBlog = getCurrentStoreData().currentBlog;

    const createBlog = async () => {
      return await createBlogAsync({
        accessToken: auth.accessToken,
        subdomain: currentWebsite.subdomain,
        newBlogUrl: defaultBlogUrl,
        websiteId: currentWebsite.id,
      })
        .then(({ data }) => {
          props.setSingleBlogLoadStatus({
            blogId: data.id,
            status: LoadStatus.LOADED,
          })
          props.setInitialPostsStatus({
            blogId: data.id,
            status: LoadStatus.LOADED,
          })
          saveSingleBlogInState([data]);
          currentBlog = data;
          if (currentBlog) {
            setBlogUrl(currentBlog.url);
          }
          return data;
        })
        .catch((error) => {
          console.error(error.response);
          openNotification(
            DEFAULT_ERROR_TITLE,
            DEFAULT_ERROR_MESSAGE,
            "OK",
            "error",
            notificationDuration.medium
          );
        });
    };

    if (!currentBlog) {
      const response = await createBlog();
      if (!response) {
        toggleIsCreateWebhookWaitingForServer(false);
        return;
      }
    }

    manageWebhooksAsync({
      webhook_name: webhook,
      accessToken: auth.accessToken,
      websiteId: currentWebsite.id,
    })
      .then((response) => {
        savePartialWebsiteData({
          websiteId: currentWebsite.id,
          data: {
            webhooks: {
              ...currentWebsite.webhooks,
              [webhook]: response.data.webhook_token,
            },
          },
        });
        message.success("API key generated successfully", 2);
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = _.get(
          error,
          ["response", "data", "message"],
          "Could not create webhook. Please contact us."
        );
        message.error(`Error: ${errorMessage}`);
      })
      .finally(() => {
        toggleIsCreateWebhookWaitingForServer(false);
      });
  };

  if (!webhookToken) {
    return (
      <div>
        <Button
          type="primary"
          onClick={handleOnCreateWebhook}
          loading={websites.isCreateWebhookWaitingForServer}
        >
          Generate API key
        </Button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Input id={inputId} value={webhookToken} readOnly />
      <Button
        id={buttonId}
        icon="copy"
        data-clipboard-target={`#${inputId}`}
        onClick={() => {
          message.success("API key copied to clipboard", 2);
        }}
      >
        Copy API key
      </Button>
    </div>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websites: state.websites,
    auth: state.auth,
  };
};
export const mapActionsToProps = {
  savePartialWebsiteData,
  toggleIsCreateWebhookWaitingForServer,
  getCurrentStoreData,
  saveSingleBlogInState,
  setSingleBlogLoadStatus,
  setInitialPostsStatus,
};
export default connect(mapStateToProps, mapActionsToProps)(WebhookMenu);
