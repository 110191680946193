import actionsNames from "./actions";
import _ from "lodash";
import {
  ChangePopupCustomBackgroundColorPayload,
  ChangePopupDataPayload,
  ChangeWebsiteItemGlobalDataPayload,
  ChangeWebsiteNavIntegrationIdPayload,
  ChangeWebsitesStateGlobalDataPayload,
  EditCustomStylesPayload,
  EditFillTemplateDataPayload,
  WebsitesState,
} from "./types";

let initialState: WebsitesState = {
  items: [],
  dataFetched: false,
  isWaitingForSubdomainUpdateResponse: false,
  isInvalidSubdomainUpdateAttempt: false,
  subdomainUpdateErrorMessage: undefined,

  isWaitingForCustomDomainUpdateResponse: false,
  isInvalidCustomDomainUpdateAttempt: false,
  customDomainUpdateErrorMessage: undefined,

  isWaitingForEnableBrandingActivationResponse: false,

  isWaitingForCustomDomainActivationResponse: false,
  isWaitingForEnableBrandingResponse: false,

  isWaitingForWebsiteCustomCodeUpdateResponse: false,
  isInvalidWebsiteCustomCodeUpdateAttempt: false,
  websiteCustomCodeUpdateErrorMessage: undefined,

  isWaitingForWebsiteExtraCustomCodeUpdateResponse: false,
  isInvalidWebsiteExtraCustomCodeUpdateAttempt: false,
  websiteExtraCustomCodeUpdateErrorMessage: undefined,

  isWaitingForSSLActivationResponse: false,
  isWaitingForProtectedWithCloudflareResponse: false,

  isWaitingForCreateWebsiteResponse: false,
  createWebsiteErrorMessage: undefined,

  isWaitingForCloneWebsiteResponse: false,

  isWaitingForChangeColorPaletteServerResponse: false,
  isWaitingForChangeFontServerResponse: false,

  isWaitingForDeleteWebsiteResponse: false,
  isNavEditionsDetected: false,
  isFooterEditionsDetected: false,
  isCaptchaEditionsDetected: false,
  isPopupEditionsDetected: false,

  isWaitingForNavEditionsServerResponse: false,
  isWaitingForFooterEditionsServerResponse: false,
  isWaitingForCaptchaEditionsServerResponse: false,
  isWaitingForPopupEditionsServerResponse: false,

  isWaitingForFormIntegrationsServerResponse: false,

  isWaitingForConnectPaymentIntegrationServerResponse: false, //when press "Connect". Currently we do not have any products manipulations. We had it, but removed (see commit on 30 Jan 2020 and earlier to restore) when moved to client-only Stripe checkout.
  isWaitingForChangePaymentIntegrationProductArrayServerResponse: false, //when apply a product to a button, delete a product from the products array or add a new product. Currently we do not have any products manipulations. We had it, but removed (see commit on 30 Jan 2020 and earlier to restore) when moved to client-only Stripe checkout.
  isWaitingForEditPaymentIntegrationProductDetailsServerResponse: false, // when edit price, title etc. Currently we do not have any products manipulations. We had it, but removed (see commit on 30 Jan 2020 and earlier to restore) when moved to client-only Stripe checkout.
  isWaitingForConfigurePaymentIntegrationServerResponse: false, // when edit integration details such as API keys. Currently we do not store APIs - we use client-only Stripe Checkout.  We had it, but removed (see commit on 30 Jan 2020 and earlier to restore) when moved to client-only Stripe checkout. But we use this state to edit product ID and success/cancel URLs.

  isWaitingForCommonWebsiteUpdateResponse: false, //used when there is no state for a specific website change
  activeDashboardTab: "1", // by default tab №1: website pages
  isWaitingForLanguagesUpdateResponse: false,

  isWaitingForEnableCaptchaServerResponse: false,
  isWaitingForCaptchaSitekeyServerResponse: false,

  loadedWebsites: [],

  isWebsiteFromTemplateCreated: false,
  isCreateWebhookWaitingForServer: false,
  isWebsiteChangesWaitingForServer: false,
  isCustomSitemapWaitingForServer: false,
  isCustomStylesWaitingForServer: false,

  fullWebsiteLoadStatusArray: [],
};

export const websitesReducer = (
  state: WebsitesState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_WEBSITES_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.ADD_SINGLE_WEBSITE_DATA:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case actionsNames.WEBSITES_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    case actionsNames.SUBDOMAIN_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForSubdomainUpdateResponse: action.payload,
      };
    case actionsNames.SUBDOMAIN_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidSubdomainUpdateAttempt: action.payload,
      };
    case actionsNames.SUBDOMAIN_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        subdomainUpdateErrorMessage: action.payload,
      };

    case actionsNames.CUSTOM_DOMAIN_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCustomDomainUpdateResponse: action.payload,
      };
    case actionsNames.CUSTOM_DOMAIN_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidCustomDomainUpdateAttempt: action.payload,
      };
    case actionsNames.CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        customDomainUpdateErrorMessage: action.payload,
      };

    case actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForWebsiteCustomCodeUpdateResponse: action.payload,
      };
    case actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidWebsiteCustomCodeUpdateAttempt: action.payload,
      };
    case actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        websiteCustomCodeUpdateErrorMessage: action.payload,
      };

    case actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForWebsiteExtraCustomCodeUpdateResponse: action.payload,
      };
    case actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidWebsiteExtraCustomCodeUpdateAttempt: action.payload,
      };
    case actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        websiteExtraCustomCodeUpdateErrorMessage: action.payload,
      };

    case actionsNames.SSL_ACTIVATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForSSLActivationResponse: action.payload,
      };
    case actionsNames.CLOUDFLARE_PROTECTION__WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForProtectedWithCloudflareResponse: action.payload,
      };

    case actionsNames.CUSTOM_DOMAIN_ACTIVATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCustomDomainActivationResponse: action.payload,
      };

    case actionsNames.ENABLE_BRANDING_ACTIVATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForEnableBrandingResponse: action.payload,
      };

    case actionsNames.TOGGLE_ENABLE_CAPTCHA_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForEnableCaptchaServerResponse: action.payload,
      };

    case actionsNames.TOGGLE_CAPTCHA_SITEKEY_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCaptchaSitekeyServerResponse: action.payload,
      };

    case actionsNames.CREATE_WEBSITE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCreateWebsiteResponse: action.payload,
      };
    case actionsNames.CREATE_WEBSITE_ERROR_MESSAGE:
      return {
        ...state,
        createWebsiteErrorMessage: action.payload,
      };

    case actionsNames.CLONE_WEBSITE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCloneWebsiteResponse: action.payload,
      };

    case actionsNames.DELETE_WEBSITE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForDeleteWebsiteResponse: action.payload,
      };

    case actionsNames.REMOVE_WEBSITE_FROM_STORE:
      let oldWebsites = state.items;
      let newWebsites = oldWebsites.filter((website: any) => {
        return website.subdomain !== action.payload;
      });

      return {
        ...state,
        items: newWebsites,
      };

    case actionsNames.SAVE_WEBSITE_NAV_IN_STATE:
      let updatedWebsites = state.items;

      for (let i = 0; i < updatedWebsites.length; i++) {
        if (
          updatedWebsites[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          updatedWebsites[i].nav = action.payload.navObject;
        }
      }

      return {
        ...state,
        items: updatedWebsites,
      };

    case actionsNames.CHANGE_WEBSITE_NAV_COLOR:
      let updatedWebsitesArray = state.items;

      for (let i = 0; i < updatedWebsitesArray.length; i++) {
        if (
          updatedWebsitesArray[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          updatedWebsitesArray[i].nav.settings.style.color =
            action.payload.navColor;
        }
      }

      return {
        ...state,
        items: updatedWebsitesArray,
      };

    case actionsNames.SAVE_WEBSITE_FOOTER_IN_STATE:
      let newWebsitesList = state.items;

      for (let i = 0; i < newWebsitesList.length; i++) {
        if (
          newWebsitesList[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          newWebsitesList[i].footer = action.payload.footerObject;
        }
      }

      return {
        ...state,
        items: newWebsitesList,
      };

    case actionsNames.SAVE_WEBSITE_CAPTCHA_IN_STATE: {
      let newWebsitesList = state.items;

      for (let i = 0; i < newWebsitesList.length; i++) {
        if (
          newWebsitesList[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          newWebsitesList[i].recaptcha = action.payload.captchaObject;
        }
      }

      return {
        ...state,
        items: newWebsitesList,
      };
    }

    case actionsNames.TOGGLE_DETECTED_FOOTER_CHANGES:
      return {
        ...state,
        isFooterEditionsDetected: action.payload,
      };
    case actionsNames.TOGGLE_DETECTED_NAV_CHANGES:
      return {
        ...state,
        isNavEditionsDetected: action.payload,
      };
    case actionsNames.TOGGLE_DETECTED_CAPTCHA_CHANGES:
      return {
        ...state,
        isCaptchaEditionsDetected: action.payload,
      };
    case actionsNames.TOGGLE_DETECTED_POPUP_CHANGES:
      return {
        ...state,
        isPopupEditionsDetected: action.payload,
      };

    case actionsNames.FOOTER_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForFooterEditionsServerResponse: action.payload,
      };
    case actionsNames.NAV_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForNavEditionsServerResponse: action.payload,
      };
    case actionsNames.CAPTCHA_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCaptchaEditionsServerResponse: action.payload,
      };
    case actionsNames.POPUP_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPopupEditionsServerResponse: action.payload,
      };

    case actionsNames.TOGGLE_ADD_NEW_FORM_INTEGRATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForFormIntegrationsServerResponse: action.payload,
      };

    // START OF: payment integrations =====
    case actionsNames.TOGGLE_CONNECT_PAYMENT_INTEGRATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForConnectPaymentIntegrationServerResponse: action.payload,
      };
    case actionsNames.TOGGLE_CHANGE_PAYMENT_INTEGRATION_PRODUCT_ARRAY_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForChangePaymentIntegrationProductArrayServerResponse:
          action.payload,
      };
    case actionsNames.TOGGLE_EDIT_PAYMENT_INTEGRATION_PRODUCT_DETAILS_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForEditPaymentIntegrationProductDetailsServerResponse:
          action.payload,
      };
    case actionsNames.TOGGLE_CONFIGURE_PAYMENT_INTEGRATION_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForConfigurePaymentIntegrationServerResponse: action.payload,
      };
    // ===== END OF: payment integrations

    case actionsNames.ADD_NEW_FORM_INTEGRATION:
      let updatedWebsitesList = state.items;

      for (let i = 0; i < updatedWebsitesList.length; i++) {
        if (
          updatedWebsitesList[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          let currentlyConnectedList =
            updatedWebsitesList[i].connected_form_integrations.connected;
          // if the connected_form_integrations[] list of the currentWebsite doesn't exist, create it.
          if (currentlyConnectedList === undefined) {
            currentlyConnectedList = [
              action.payload.newConnectedIntegrationObject,
            ];
          }
          // else if the list exists (we have already added some integrations), add new item to the current list.
          else {
            currentlyConnectedList = [
              ...currentlyConnectedList,
              action.payload.newConnectedIntegrationObject,
            ];
          }

          // Update the website with the newly generated integrations list:
          updatedWebsitesList[i].connected_form_integrations.connected =
            currentlyConnectedList;
        }
      }

      return {
        ...state,
        items: updatedWebsitesList,
      };

    case actionsNames.REMOVE_FORM_INTEGRATION:
      let changedWebsitesList = state.items;
      let itemToDeleteId = action.payload.id;
      for (let i = 0; i < changedWebsitesList.length; i++) {
        if (
          changedWebsitesList[i].subdomain ===
          action.payload.currentWebsiteSubdomain
        ) {
          let currentlyConnectedList =
            changedWebsitesList[i].connected_form_integrations.connected;

          //delete from the array
          for (let i = 0; i < currentlyConnectedList.length; i++) {
            if (currentlyConnectedList[i].id === itemToDeleteId) {
              currentlyConnectedList.splice(i, 1);
              break;
            }
          }

          changedWebsitesList[i].connected_form_integrations.connected =
            currentlyConnectedList;
        }
      }

      return {
        ...state,
        items: changedWebsitesList,
      };

    // START OF: Stripe actions =====
    case actionsNames.CHANGE_STRIPE_SECRET_KEY:
      return {
        ...state,
      };
    case actionsNames.CHANGE_STRIPE_PUBLIC_KEY:
      return {
        ...state,
      };
    case actionsNames.CHANGE_STRIPE_CHECKOUT_PRODUCT_ARRAY:
      return {
        ...state,
      };
    // ===== END OF: Stripe actions

    case actionsNames.TOGGLE_CHANGE_COLOR_PALETTE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForChangeColorPaletteServerResponse: action.payload,
      };

    case actionsNames.TOGGLE_CHANGE_FONT_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForChangeFontServerResponse: action.payload,
      };

    case actionsNames.COMMON_WEBSITE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCommonWebsiteUpdateResponse: action.payload,
      };

    // START OF: UI state
    case actionsNames.UPDATE_ACTIVE_DASHBOARD_TAB:
      return {
        ...state,
        activeDashboardTab: action.payload,
      };
    // END OF: UI state

    case actionsNames.TOGGLE_CHANGE_LANGUAGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForLanguagesUpdateResponse: action.payload,
      };

    case actionsNames.ADD_NEW_POPUP: {
      let newWebsitesList = [...state.items];
      newWebsitesList.forEach((website) => {
        if (website.subdomain === action.payload.currentWebsiteSubdomain) {
          let currentPopupsList = website.popups.items;
          if (!currentPopupsList) {
            currentPopupsList = [action.payload.newPopupObject];
          } else {
            currentPopupsList = [
              ...currentPopupsList,
              action.payload.newPopupObject,
            ];
          }
          website.popups.items = currentPopupsList;
        }
      });
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.DELETE_POPUP: {
      let newWebsitesList = [...state.items];
      const deletedItemId = action.payload.id;
      newWebsitesList.forEach((website) => {
        if (website.subdomain === action.payload.currentWebsiteSubdomain) {
          let currentPopupList = website.popups.items;
          currentPopupList.forEach((popupItem, popupIndex) => {
            if (popupItem.id === deletedItemId) {
              currentPopupList.splice(popupIndex, 1);
            }
          });
          website.popups.items = currentPopupList;
        }
      });
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_CONTENT: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.componentData = {
        ...currentPopup.componentData,
        ...action.payload.componentData,
      };
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_BACKGROUND_COLOR: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.settings.background.color = action.payload.color;
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_BACKGROUND_IMAGE: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.settings.background.imageUrl = action.payload.imageUrl || "";
      currentPopup.settings.background.imageUUID =
        action.payload.imageUUID || undefined;
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_BACKGROUND_ACTIVE_OPTION: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.settings.background.activeOption =
        action.payload.activeOption;
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_BACKGROUND_VIDEO: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.settings.background.backgroundVideoUrl =
        action.payload.backgroundVideoUrl;
      return {
        ...state,
        items: newWebsitesList,
      };
    }
    case actionsNames.CHANGE_POPUP_BACKGROUND_OVERLAY_OPACITY: {
      const newWebsitesList = [...state.items];
      const currentWebsite = newWebsitesList.find(
        (website) =>
          website.subdomain === action.payload.currentWebsiteSubdomain
      );
      const popupId = action.payload.popupId;
      const currentPopupList = currentWebsite.popups.items;
      const currentPopup = currentPopupList.find(
        (popup) => popup.id === popupId
      );
      currentPopup.settings.background.overlayOpacity =
        action.payload.newOverlayOpacity;
      return {
        ...state,
        items: newWebsitesList,
      };
    }

    case actionsNames.CHANGE_POPUP_CUSTOM_BACKGROUND_COLOR: {
      const payload: ChangePopupCustomBackgroundColorPayload = action.payload;
      const { data, currentWebsiteSubdomain, popupId } = payload;

      const currentWebsitesList = [...state.items];
      const currentWebsite = currentWebsitesList.find((website) => {
        return website.subdomain === currentWebsiteSubdomain;
      });
      const currentPopup = currentWebsite.popups.items.find((popup) => {
        return popup.id === popupId;
      });
      currentPopup.settings.background.customBackgroundColor = {
        ...currentPopup.settings.background.customBackgroundColor,
        ...data,
      };
      return {
        ...state,
        items: currentWebsitesList,
      };
    }

    case actionsNames.CHANGE_POPUP_DATA: {
      const payload: ChangePopupDataPayload = action.payload;
      const { data, currentWebsiteSubdomain, popupId } = payload;

      const currentWebsitesList = _.cloneDeep(state.items);
      const currentWebsite = currentWebsitesList.find((website) => {
        return website.subdomain === currentWebsiteSubdomain;
      });
      const currentPopup = currentWebsite.popups.items.find((popup) => {
        return popup.id === popupId;
      });
      Object.assign(currentPopup, data);
      return {
        ...state,
        items: currentWebsitesList,
      };
    }

    case actionsNames.TOGGLE_LOADED_WEBSITES_DATA: {
      const { websiteId } = action.payload;
      const currentLoadedWebsites = [...state.loadedWebsites];
      const currentItemIndex = _.findIndex(currentLoadedWebsites, {
        websiteId,
      });

      if (currentItemIndex === -1) {
        currentLoadedWebsites.push(action.payload);
      } else {
        currentLoadedWebsites[currentItemIndex] = action.payload;
      }

      return {
        ...state,
        loadedWebsites: currentLoadedWebsites,
      };
    }

    case actionsNames.EDIT_FILL_TEMPLATE_DATA: {
      const { websiteId, newData } =
        action.payload as EditFillTemplateDataPayload;
      const currentWebsites = [...state.items];
      const currentWebsite = currentWebsites.find(
        (website) => website.id === websiteId
      );
      if (!currentWebsite) return state;
      currentWebsite.fillTemplateData = {
        ...currentWebsite.fillTemplateData,
        ...newData,
      };
      return {
        ...state,
        items: currentWebsites,
      };
    }

    case actionsNames.TOGGLE_IS_WEBSITE_FROM_TEMPLATE_CREATED: {
      return {
        ...state,
        isWebsiteFromTemplateCreated: action.payload,
      };
    }

    case actionsNames.TOGGLE_IS_CREATE_WEBHOOK_WAITING_FOR_SERVER:
      return {
        ...state,
        isCreateWebhookWaitingForServer: action.payload,
      };

    case actionsNames.CHANGE_WEBSITES_STATE_GLOBAL_DATA: {
      const payload: ChangeWebsitesStateGlobalDataPayload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }

    case actionsNames.EDIT_CUSTOM_STYLES: {
      const { websiteId, newData } = action.payload as EditCustomStylesPayload;
      const currentWebsites = _.cloneDeep(state.items);
      const currentWebsite = currentWebsites.find(
        (website) => website.id === websiteId
      );
      if (!currentWebsite) return state;
      currentWebsite.custom_styles = {
        ...currentWebsite.custom_styles,
        ...newData,
      };
      return {
        ...state,
        items: currentWebsites,
      };
    }

    case actionsNames.CHANGE_WEBSITE_ITEM_GLOBAL_DATA: {
      const payload = action.payload as ChangeWebsiteItemGlobalDataPayload;
      const { websiteId, data } = payload;
      const websitesArrayCopy = _.cloneDeep(state.items);
      const currentWebsite = websitesArrayCopy.find((website) => website.id === websiteId);
      Object.assign(currentWebsite, data);

      return {
        ...state,
        items: websitesArrayCopy,
      };
    }

    case actionsNames.CHANGE_WEBSITE_NAV_INTEGRATION_ID: {
      const payload: ChangeWebsiteNavIntegrationIdPayload = action.payload;
      const { websiteId, integrationId } = payload;
      const websitesArrayCopy = _.cloneDeep(state.items);
      const currentWebsite = websitesArrayCopy.find((website) => website.id === websiteId);
      if (!currentWebsite) return state;
      _.set(currentWebsite, "nav.componentData.cta.content.form.connectedIntegrationsIds", [integrationId]);
      return {
        ...state,
        items: websitesArrayCopy,
      };
    }

    case actionsNames.SET_FULL_WEBSITE_LOAD_STATUS: {
      const payload = action.payload;
      const { websiteId, status } = payload;
      const currentLoadStatusArray = [...state.fullWebsiteLoadStatusArray];
      const existingStatusIndex = currentLoadStatusArray.findIndex(
        (item) => item.websiteId === websiteId
      );
      if (existingStatusIndex === -1) {
        currentLoadStatusArray.push({ websiteId, status });
      } else {
        currentLoadStatusArray[existingStatusIndex] = { websiteId, status };
      }
      return {
        ...state,
        fullWebsiteLoadStatusArray: currentLoadStatusArray,
      };
    }

    case actionsNames.SAVE_FULL_WEBSITE_TO_STORE: {
      const payload = action.payload;
      const { website } = payload;
      const websiteArrayCopy = _.cloneDeep(state.items);
      const index = websiteArrayCopy.findIndex(
        (item) => item.id === website.id
      );
      if (index === -1) {
        websiteArrayCopy.push(website);
      } else {
        websiteArrayCopy[index] = website;
      }
      return {
        ...state,
        items: websiteArrayCopy,
      };
    }

    default:
      return state;
  }
};
