import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";
import AddPageComponent from "../editor/AddPageComponent";
import { Badge, Button, Empty, Icon, Modal } from "antd";
import "./Editor.css";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import PageComponent from "../../pageComponents/PageComponent";
import SpinnerBox from "./SpinnerBox";
import { api, apiUrlBase } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import {
  PATH_HISTORY_LS_KEY,
  copiedComponentDataSessionStorageKey,
  indexWebsitePageDashboardUrl,
  notificationDuration,
} from "../../data/constants";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { checkIfPageHasComponents } from "../../helpers/editor/checkIfPageHasComponents";
import { checkIfComponentHasDarkBg } from "../../helpers/editor/checkIfComponentHasDarkBg";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { applyCustomColorsClassnames } from "../../helpers/editor/applyCustomColorsClassnames";
import CustomColorsStyles from "../editor/CustomColorsStyles";
import CustomFontsStyles from "../editor/CustomFontsStyles";
// import useMousetrap from "react-hook-mousetrap";
import DisplayBadWebsiteOverlay from "../../helpers/DisplayBadWebsiteOverlay";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import PopupComponents from "../editor/popups/PopupComponents";
import PopupMask from "../editor/popups/PopupMask";
import _ from "lodash";
import {
  ChangeWebsitesStateGlobalData,
  EditFillTemplateData,
  GetCurrentStoreData,
  SaveWebsiteBackup,
  ToggleLoadedWebsitesData,
} from "../../store/websites/types";
import {
  ChangeComponentGlobalData,
  ChangePageItemGlobalData,
  ChangeWebsitePagesStateGlobalData,
  SavePageComponentsInState,
  ToggleIsPageEmpty,
} from "../../store/websitePages/types";
import { CrispEvents, PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import { SaveUserEventsInStore } from "../../store/user/types";
import WebsiteNav from "../../pageComponents/component_molecules/WebsiteNav";
import WebsiteFooter from "../../pageComponents/component_molecules/WebsiteFooter";
import {
  AbortControllersRef,
  GptInputObject,
  GptPopoverObject,
  OnGptClick,
  QueueItem,
  SetValueAndResizeTextArea,
} from "../editor/ai2/types";
import {
  GetCurrentChatData,
  GptState,
  HandleOnUnmount,
  SetMode,
} from "../../store/gpt/types";
import FillTemplateModalContent from "./fill_template_modal_content/FillTemplateModalContent";
import FillTemplateProgressIndicator from "./fill_template_modal_content/FillTemplateProgressIndicator";
import GenerateButton from "./fill_template_modal_content/GenerateButton";
import { getMetaDataFromComponentsArray } from "../../helpers/getMetaDataFromComponentsArray";
import CustomStyles from "../CustomStyles";
import { getCustomStyleClassNames } from "../../helpers/editor/getCustomStyleClassNames";
import { LoadStatus } from "../../enums/enums";
import { getCurrentPageId } from "../../helpers/getCurrentPageId";
import { getCurrentSubdomain } from "../../helpers/getCurrentSubdomain";

interface State {
  addPageComponentWindowVisible: boolean;
  newComponentPosition: number;
  showSaveButtonSuccess: boolean;
  doesComponentClipboardHaveItem: boolean;
  firstComponentWasEdited: boolean;
  gptInputValue: string;
  gptIsPopoverVisible: boolean;
}
interface Props {
  pageComponents: any;
  pageComponentCategories: any;
  user: any;
  auth: any;
  websitesPages: any;
  websites: any;
  formIntegrations: any;
  history: any;
  gpt: GptState;

  editWebsitePageSchemeToggleWaitingForServer: any;
  toggleNewWebsitePageEditionsDetected: any;
  moveWebsitePageComponent: any;
  deleteWebsitePageComponent: any;
  changeWebsitePageComponentContent: any;
  addWebsitePageComponent: any;
  pasteCopiedWebsitePageComponent: any;

  saveWebsiteNavInState: any;
  saveWebsiteFooterInState: any;

  toggleWebsiteNavEditionsDetected: any;
  toggleWebsiteFooterEditionsDetected: any;

  toggleWaitingForResponseOnWebsiteFooterEditions: any;
  toggleWaitingForResponseOnWebsiteNavEditions: any;

  saveWebsiteNewFormIntegrationInState: any;
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd: any;
  changeWebsitePageComponentBackgroundColor: any;
  changeWebsitePageComponentBackgroundImage: any;
  changeWebsitePageComponentBackgroundImageOverlayOpacity: any;
  changeWebsitePageComponentPaddingTop: any;
  changeWebsitePageComponentPaddingBottom: any;
  removeWebsiteFormIntegration: any;

  toggleWaitingForConnectPaymentIntegration: any;
  toggleWaitingForChangePaymentIntegrationProductsArray: any;
  toggleWaitingForEditPaymentIntegrationProductDetails: any;
  toggleWaitingForConfigurePaymentIntegration: any;

  toggleWaitingForCommonWebsiteUpdate: any;

  changeWebsiteNavColor: any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;

  changeStripeSecretKey: any;
  changeStripePublicKey: any;
  changeStripeCheckoutProductsArray: any;

  saveWebsiteDataInStore: any;

  changeWebsitePageComponentVisibility: any;

  toggleIsPageNavHidden: any;
  toggleIsPageFooterHidden: any;
  toggleNewWebsitePageNavEditionsDetected: any;

  toggleWebsiteCaptchaEditionsDetected: any;
  toggleWaitingForResponseOnWebsiteCaptchaEditions: any;

  toggleWebsitePopupEditionsDetected: any;
  toggleWaitingForResponseOnWebsitePopupEditions: any;

  toggleLoadedWebsitesData: ToggleLoadedWebsitesData;
  savePageComponentsInState: SavePageComponentsInState;
  toggleIsPageEmpty: ToggleIsPageEmpty;
  saveUserEventsInStore: SaveUserEventsInStore;

  navEditionFieldsVisible: boolean;
  showNavEditionFieldsDrawer: () => void;
  hideNavEditionFieldsDrawer: () => void;
  displayNavOrFooterEditionBox: any;
  blogs: any;
  saveBlogNavInState: any;
  toggleBlogNavEditionsDetected: any;

  footerEditionFieldsVisible: boolean;
  saveBlogFooterInState: any;
  showFooterEditionFieldsDrawer: any;
  hideFooterEditionFieldsDrawer: any;
  toggleBlogFooterEditionsDetected: any;
  setMode: SetMode;
  getCurrentChatData: GetCurrentChatData;
  editFillTemplateData: EditFillTemplateData;

  currentWebsite: any;
  currentWebsitePageUrl: any;
  currentWebsitePage: any;
  handleOnUnmount: HandleOnUnmount;
  changeComponentGlobalData: ChangeComponentGlobalData;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
  changePageItemGlobalData: ChangePageItemGlobalData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
  saveWebsiteBackup: SaveWebsiteBackup;
  getPageLoadStatus: any;
  setPageLoadStatus: any;
  saveSinglePageInStore: any;
  getSingleWebsiteLoadStatus: any;
}
class Editor extends Component<Props> {
  gptTextAreaRef: React.RefObject<HTMLTextAreaElement>;
  isForceDontOpenPresetsRef: React.MutableRefObject<boolean>;
  gptAssistantComponentRef: React.MutableRefObject<any>;
  queueDataRef: React.MutableRefObject<QueueItem[]>;
  intervalRef: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef: React.MutableRefObject<boolean>;
  abortControllersRef: AbortControllersRef;
  constructor(props: Props) {
    super(props);
    this.gptTextAreaRef = React.createRef();
    this.isForceDontOpenPresetsRef = React.createRef();
    this.gptAssistantComponentRef = React.createRef();
    this.queueDataRef = React.createRef();
    this.queueDataRef.current = [];
    this.intervalRef = React.createRef();
    this.forceStopAllFlagRef = React.createRef();
    this.forceStopAllFlagRef.current = false;
    this.abortControllersRef = React.createRef();
    this.abortControllersRef.current = [];
  }
  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    // Need to recolor nav in black/white depending on the bg color of the first component.
    const pageId = getCurrentPageId();
    const singlePageStatus = this.props.getPageLoadStatus(pageId);
    const singleWebsiteStatus = this.props.getSingleWebsiteLoadStatus(
      this.props.currentWebsite
    );
    if (
      (singlePageStatus === LoadStatus.LOADED || singlePageStatus === LoadStatus.ERROR) &&
      singleWebsiteStatus === LoadStatus.LOADED
    ) {
      if (
        this.currentPageComponents &&
        this.currentPageComponents.data &&
        this.currentPageComponents.data.schema.length > 0 &&
        this.currentWebsite &&
        this.currentWebsite.nav &&
        this.currentWebsite.nav.settings &&
        this.currentWebsite.nav.settings.style &&
        this.currentWebsite.nav.settings.style.color
      ) {
        // These conditions are no more undefined when the component has been rendered and any component added.

        let currentNavColor = this.currentWebsite.nav.settings.style.color; //'black' or 'white'
        let currentFirstComponentBgTitle =
          this.currentPageComponents.data.schema[0].settings.background.color;
        let isFirstComponentBgDark = checkIfComponentHasDarkBg(
          currentFirstComponentBgTitle
        );

        let newNavColor = isFirstComponentBgDark ? "white" : "black";

        if (
          (isFirstComponentBgDark === true && currentNavColor === "black") ||
          (isFirstComponentBgDark === false && currentNavColor === "white")
        ) {
          // need to recolor the nav:
          this.props.changeWebsiteNavColor({
            currentWebsiteSubdomain: this.currentWebsite.subdomain,
            navColor: newNavColor,
          });
          this.props.toggleWebsiteNavEditionsDetected(true);
        }
      }
    }
  }

  componentWillUnmount(): void {
    if (
      !this.props.currentWebsite ||
      !this.props.currentWebsitePage ||
      this.currentPageIndex === undefined
    ) {
      return;
    }

    this.props.handleOnUnmount({
      intervalRef: this.intervalRef,
      forceStopAllFlagRef: this.forceStopAllFlagRef,
      queueDataRef: this.queueDataRef,
      currentWebsitePage: this.props.currentWebsitePage,
      currentWebsite: this.props.currentWebsite,
      currentPageIndex: this.currentPageIndex,
      abortControllersRef: this.abortControllersRef,
    });

    this.props.editFillTemplateData({
      websiteId: this.props.currentWebsite.id,
      newData: {
        status: "finished",
        isModalVisible: false,
        skipFooter: false,
        skipNav: false,
      },
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    this.fetchSinglePage();
    let isSaveButtonActive: boolean = false;
    isSaveButtonActive =
      this.props.websitesPages.websitePageNewEditionsDetected ||
      this.props.websites.isFooterEditionsDetected ||
      this.props.websites.isNavEditionsDetected ||
      this.props.websites.isCaptchaEditionsDetected ||
      this.props.websites.isPopupEditionsDetected;

    if (isSaveButtonActive) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    const { currentWebsite } = this.props.getCurrentStoreData();
    const isModalVisible = _.get(currentWebsite, [
      "fillTemplateData",
      "isModalVisible",
    ]);
    const showPageModalFlag = _.get(currentWebsite, [
      "fillTemplateData",
      "showPageModalFlag",
    ]);
    if (isModalVisible && showPageModalFlag) {
      this.showFillTemplateModal();
      this.props.editFillTemplateData({
        websiteId: currentWebsite.id,
        newData: {
          showPageModalFlag: false,
          modalPage: "pickContent",
        },
      });
    }
  }

  state = {
    addPageComponentWindowVisible: false,
    newComponentPosition: 0,
    showSaveButtonSuccess: false,
    doesComponentClipboardHaveItem: false,
    firstComponentWasEdited: false, // mark as true to give the server a signal to make a fresh screenshot of the website page. search for markFirstComponentWasEditedAsTrue() uses to see when we mark the page as needs_screenshot=True (when a user changes content, bg options etc).
    gptInputValue: "",
    gptIsPopoverVisible: false,
    isFillTemplateModalVisible: false,
    isFillTemplateFormLoading: false,
    navHeight: 0,
  };

  setNavHeight = (height: number) => {
    this.setState({ navHeight: height });
  };

  setIsFillTemplateFormLoading = (isLoading: boolean) => {
    this.setState({ isFillTemplateFormLoading: isLoading });
  };

  showFillTemplateModal = () => {
    this.setState({
      isFillTemplateModalVisible: true,
    });
  };

  handleFillTemplateOk = () => {
    this.setState({
      isFillTemplateModalVisible: false,
    });
  };

  handleFillTemplateCancel = () => {
    this.setState({
      isFillTemplateModalVisible: false,
    });
    this.props.editFillTemplateData({
      websiteId: this.props.currentWebsite.id,
      newData: {
        isModalVisible: false,
      },
    });
  };

  gptSetValueAndResizeTextArea: SetValueAndResizeTextArea = (
    newValue,
    method
  ) => {
    const textAreaNode = this.gptTextAreaRef.current;
    const currentChat = this.props.getCurrentChatData(
      this.props.currentWebsitePage.id
    );
    if (currentChat.isLoading !== "none") return;
    if (textAreaNode) {
      textAreaNode.value =
        method === "replace" || !method
          ? newValue
          : textAreaNode.value + newValue;
      textAreaNode.style.height = "0";
      textAreaNode.style.height = textAreaNode.scrollHeight.toString() + "px";
    }
    if (method === "append") {
      this.setState((prevState: State) => ({
        gptInputValue: prevState.gptInputValue + newValue,
      }));
      return;
    }
    if (method === "prepend") {
      this.setState((prevState: State) => ({
        gptInputValue: newValue + prevState.gptInputValue,
      }));
      return;
    }
    this.setState({ gptInputValue: newValue });
  };

  gptFocusTextArea = (dontOpenPresets?: boolean) => {
    const textAreaNode = this.gptTextAreaRef.current;
    if (!textAreaNode) return;
    const value = textAreaNode.value;
    if (dontOpenPresets) {
      this.isForceDontOpenPresetsRef.current = true;
    }
    textAreaNode.value = "";
    textAreaNode.focus();
    textAreaNode.value = value;
    this.isForceDontOpenPresetsRef.current = false;
  };

  gptSetIsPopoverVisible = (isVisible: boolean) => {
    this.setState({ gptIsPopoverVisible: isVisible });
  };

  fetchSinglePage = () => {
    const pageId = getCurrentPageId();
    const subdomain = getCurrentSubdomain();
    const status = this.props.getPageLoadStatus(pageId);
    if (status !== LoadStatus.NOT_LOADED) return;
    this.props.setPageLoadStatus({
      pageId,
      status: LoadStatus.IN_PROGRESS,
    });
    const accessToken = this.props.auth.accessToken;
    axios
      .get(`${apiUrlBase}/api/v1/website_pages/${subdomain}/get_single_page?page_id=${pageId}`, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        const status = this.props.getPageLoadStatus(pageId);
        if (status !== LoadStatus.IN_PROGRESS) {
          return;
        }
        const websiteStatus = this.props.getSingleWebsiteLoadStatus({id: response.data.website});
        if (websiteStatus === LoadStatus.DELETED) {
          return;
        }
        this.props.saveSinglePageInStore({
          page: response.data,
        });
        this.props.setPageLoadStatus({
          pageId,
          status: LoadStatus.LOADED,
        });
      })
      .catch((error) => {
        this.props.setPageLoadStatus({
          pageId,
          status: LoadStatus.ERROR,
        });
        console.error(error.response);
        openNotification(
          "Error",
          "Couldn't fetch the page data.",
          "OK",
          "error",
          notificationDuration.long
        );
      });
  };

  componentDidMount(): void {
    const path = this.props.history.location.pathname;
    localStorage.setItem(PATH_HISTORY_LS_KEY, path);
    this.fetchSinglePage();
    const pageId = getCurrentPageId();
    const singlePageStatus = this.props.getPageLoadStatus(pageId);
    const singleWebsiteStatus = this.props.getSingleWebsiteLoadStatus(
      this.props.currentWebsite
    );
    if (
      (singlePageStatus === LoadStatus.LOADED || singlePageStatus === LoadStatus.ERROR) &&
      singleWebsiteStatus === LoadStatus.LOADED
    ) {
      // If a user opens Editor screen from a direct URL it will render before the data is fetched, so this analytics even will not fire.
      // It's not a big deal, and OK now. But better to track each visit.
      let currentWebsite = getCurrentWebsite(this.props.websites.items);

      const isModalVisible = _.get(currentWebsite, [
        "fillTemplateData",
        "isModalVisible",
      ]);
      if (isModalVisible) {
        this.showFillTemplateModal();
      }

      let websitesPagesArray = this.props.websitesPages.items;
      let currentWebsitePageUrl = getCurrentWebsitePageUrl(
        currentWebsite,
        websitesPagesArray
      );
      if (currentWebsite) {
        // We can face "TypeError: Cannot read property 'subdomain' of undefined" if press the browser back button after changing subdomain in website settings.
        fireAnalyticsEvent.fireCrisp(CrispEvents.openEditorScreen, {
          subdomain: currentWebsite.subdomain,
          page_url: currentWebsitePageUrl,
        });
      }
    }

    // The interval is used to set user event only when the user data is loaded.
    const interval = setInterval(() => {
      const { user, auth } = this.props;
      if (user.data) {
        setUserEventsAsync(
          {
            [UserEvents.VisitedEditor]: new Date(),
          },
          auth.accessToken,
          user.data.events
        ).then((response) => {
          if (response) {
            this.props.saveUserEventsInStore(response.data);
          }
        });
        clearInterval(interval);
      }
    }, 1000);
  }

  markFirstComponentWasEditedAsTrue = (firstComponentWasEdited: boolean) => {
    // read the comment at 'state = {' to learn about this function
    if (firstComponentWasEdited) {
      this.setState({
        firstComponentWasEdited: true,
      });
    }
  };

  saveAllChanges = async (
    currentWebsiteSubdomain: any,
    currentWebsitePageUrl: any
  ) => {
    const { websites, websitesPages } = this.props;
    this.props.changeWebsitesStateGlobalData({
      isWebsiteChangesWaitingForServer: true,
    });
    const { currentWebsitePage } = this.props.getCurrentStoreData();

    const dataToBackup = {};

    if (websitesPages.websitePageNewEditionsDetected) {
      dataToBackup["has_page_components"] = true;
      dataToBackup["page_id"] = _.get(currentWebsitePage, "id");
    }
    if (websitesPages.websitePageNewNavEditionsDetected) {
      dataToBackup["has_page_settings"] = true;
      dataToBackup["page_id"] = _.get(currentWebsitePage, "id");
    }
    if (websites.isNavEditionsDetected) {
      dataToBackup["has_website_nav"] = true;
    }
    if (websites.isFooterEditionsDetected) {
      dataToBackup["has_website_footer"] = true;
    }
    if (websites.isCaptchaEditionsDetected) {
      dataToBackup["has_recaptcha"] = true;
    }
    if (websites.isPopupEditionsDetected) {
      dataToBackup["has_popups"] = true;
    }
    // try {
    //   await this.props.saveWebsiteBackup(dataToBackup);
    // } catch (e) {
    //   console.error(_.get(e, "response.data"));
    // }

    if (
      websitesPages.websitePageNewEditionsDetected ||
      websitesPages.websitePageNewNavEditionsDetected
    ) {
      await this.savePageData();
    }

    if (
      websites.isNavEditionsDetected ||
      websites.isFooterEditionsDetected ||
      websites.isCaptchaEditionsDetected ||
      websites.isPopupEditionsDetected
    ) {
      await this.saveWebsiteData();
    }

    this.props.changeWebsitesStateGlobalData({
      isWebsiteChangesWaitingForServer: false,
    });

    this.launchSuccessfulSaveAnimation();
  };

  savePageData = async () => {
    const { currentWebsite, currentWebsitePage } =
      this.props.getCurrentStoreData();
    const currentWebsiteSubdomain = currentWebsite.subdomain;
    const currentWebsitePageUrl = currentWebsitePage.url;

    let websitePageUrlToServer = currentWebsitePageUrl;
    if (websitePageUrlToServer === "") {
      websitePageUrlToServer = indexWebsitePageDashboardUrl;
    }

    let newPageComponentsScheme =
      this.props.websitesPages.items[this.currentPageIndex].page_components;

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websitePages.updateWebsitePage.prefix +
      currentWebsiteSubdomain +
      "/" +
      websitePageUrlToServer +
      api.websitePages.updateWebsitePage.postfix;

    const dataToServer = {
      subdomain: currentWebsiteSubdomain,
      needs_screenshot: this.state.firstComponentWasEdited,
    };

    if (this.props.websitesPages.websitePageNewEditionsDetected) {
      const {
        first_page_title,
        first_page_subtitle,
        hero_image_url,
        hero_image_uuid,
      } = getMetaDataFromComponentsArray(newPageComponentsScheme);
      dataToServer["page_components"] = newPageComponentsScheme;
      dataToServer["first_page_title"] = first_page_title;
      dataToServer["first_page_subtitle"] = first_page_subtitle;
      dataToServer["hero_image_url"] = hero_image_url;
      dataToServer["hero_image_uuid"] = hero_image_uuid;
    }

    if (this.props.websitesPages.websitePageNewNavEditionsDetected) {
      dataToServer["is_nav_hidden"] = currentWebsitePage.is_nav_hidden;
    }

    await axios
      .patch(apiUrl, dataToServer, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Page data",
          website: response.data.website,
          page_url: response.data.url,
        });
        const newIsEmpty: boolean = _.get(response.data, "is_empty");
        const pageId: number = _.get(response.data, "id");
        this.props.toggleIsPageEmpty({
          pageId,
          newIsEmpty,
        });
        if (this.props.websitesPages.websitePageNewEditionsDetected) {
          this.props.changePageItemGlobalData({
            pageId: this.props.currentWebsitePage.id,
            data: {
              first_page_title: dataToServer["first_page_title"],
              first_page_subtitle: dataToServer["first_page_subtitle"],
              hero_image_url: dataToServer["hero_image_url"],
              hero_image_uuid: dataToServer["hero_image_uuid"],
            },
          });
        }
        Object.keys(dataToServer).forEach((key) => {
          if (key === "page_components") {
            this.props.toggleNewWebsitePageEditionsDetected(false);
          }
          if (key === "is_nav_hidden") {
            this.props.toggleNewWebsitePageNavEditionsDetected(false);
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          let errorData = error.response.data;
          handleBadWebsiteError(errorData);
          if (errorData.page_components !== undefined) {
            let message = errorData.page_components;
            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.savePageEditionsError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                type: "Page data",
                website: currentWebsiteSubdomain,
              },
              true
            );
            openNotification(
              "Internal error",
              'Error message: "' +
                message +
                '". This should not have happened. Sorry. Please contact us.',
              "OK",
              "error"
            );
          }
          if (errorData.detail !== undefined) {
            let message = errorData.detail;
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }
        } else {
          openNotification(
            "Error :(",
            "There was an error during saving the changes. Please re-check your Internet connection and try again.",
            "OK",
            "warn",
            999
          );
          openNotification(
            "If this doesn't help",
            "To save your changes, open the browser console.",
            "Thanks",
            "warn",
            999
          );
          console.info(
            "This log contains your changes. Kindly email it to us if you want to save the changes you made."
          );
          console.log(JSON.stringify(newPageComponentsScheme));
          console.info("We are sorry for the incident! 🙏");
        }
      });
  };

  saveWebsiteData = async () => {
    let currentWebsite = getCurrentWebsite(this.props.websites.items);
    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    const dataToServer = {};
    if (this.props.websites.isNavEditionsDetected) {
      dataToServer["nav"] = currentWebsite.nav;
    }
    if (this.props.websites.isFooterEditionsDetected) {
      dataToServer["footer"] = currentWebsite.footer;
    }
    if (this.props.websites.isCaptchaEditionsDetected) {
      dataToServer["recaptcha"] = currentWebsite.recaptcha;
    }
    if (this.props.websites.isPopupEditionsDetected) {
      dataToServer["popups"] = currentWebsite.popups;
    }

    await axios
      .patch(apiUrl, dataToServer, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Website data",
          website: response.data.subdomain,
        });
        this.launchSuccessfulSaveAnimation();
        Object.keys(dataToServer).forEach((key) => {
          if (key === "nav") {
            this.props.toggleWebsiteNavEditionsDetected(false);
          }
          if (key === "footer") {
            this.props.toggleWebsiteFooterEditionsDetected(false);
          }
          if (key === "recaptcha") {
            this.props.toggleWebsiteCaptchaEditionsDetected(false);
          }
          if (key === "popups") {
            this.props.toggleWebsitePopupEditionsDetected(false);
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          let errorData = error.response.data;
          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              type: "Website data",
              website: currentWebsite.subdomain,
            },
            true
          );
          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }
          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }
          if (errorData.detail !== undefined) {
            let message = errorData.detail;
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      });
  };

  launchSuccessfulSaveAnimation = () => {
    this.setState({
      showSaveButtonSuccess: true,
    });

    setTimeout(() => {
      this.setState({
        showSaveButtonSuccess: false,
      });
    }, 1000);
  };

  handleCloseAddComponent = () => {
    this.setState({
      addPageComponentWindowVisible: false,
    });
  };
  openAddNewComponent = (position: number) => {
    let copiedComponent = sessionStorage.getItem(
      copiedComponentDataSessionStorageKey
    );
    if (copiedComponent === null) {
      this.setState({
        doesComponentClipboardHaveItem: false,
      });
    } else {
      this.setState({
        doesComponentClipboardHaveItem: true,
      });
    }

    this.setState({
      addPageComponentWindowVisible: true,
      newComponentPosition: position,
    });
  };
  generator = new generateContentDataObject();

  saveFooterInServer = () => {
    this.props.toggleWaitingForResponseOnWebsiteFooterEditions(true);

    let currentWebsite = getCurrentWebsite(this.props.websites.items);

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    axios
      .patch(
        apiUrl,
        { footer: currentWebsite.footer },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Website footer has been successfully saved.';
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Website footer",
          website: response.data.subdomain,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              type: "Website footer",
              website: currentWebsite.subdomain,
            },
            true
          );

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWebsiteFooterEditionsDetected(false);
        this.props.toggleWaitingForResponseOnWebsiteFooterEditions(false);
      });
  };

  saveNavInServer = () => {
    this.props.toggleWaitingForResponseOnWebsiteNavEditions(true);

    let currentWebsite = getCurrentWebsite(this.props.websites.items);

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    axios
      .patch(
        apiUrl,
        { nav: currentWebsite.nav },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Successfully saved website navigation.';

        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Website navigation",
          website: response.data.subdomain,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              type: "Website navigation",
              website: currentWebsite.subdomain,
            },
            true
          );

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWebsiteNavEditionsDetected(false);
        this.props.toggleWaitingForResponseOnWebsiteNavEditions(false);
      });
  };

  currentPageIndex: number; //needed to change the redux store when adding a new component. at the beginning it is undefined (we use 0). after the page is rendered, we find out this index and save here.

  // declare currentWebsite and currentPageComponents to use later.
  currentWebsite: any = undefined;
  currentPageComponents: any = undefined;

  render() {
    let currentWebsiteSubdomain = getCurrentWebsiteSubdomain(
      this.props.websites.items
    ); // used below for saving data in the server
    let currentWebsitePageUrl: string; // used below for saving data in the server
    // let currentPageComponents: any; // used below for display "Add component"

    const gptInputObject: GptInputObject = {
      inputValue: this.state.gptInputValue,
      textAreaRef: this.gptTextAreaRef,
      setInputValue: this.gptSetValueAndResizeTextArea,
      focusTextArea: this.gptFocusTextArea,
      isForceDontOpenPresetsRef: this.isForceDontOpenPresetsRef,
    };

    const gptPopoverObject: GptPopoverObject = {
      isPopoverVisible: this.state.gptIsPopoverVisible,
      setIsPopoverVisible: this.gptSetIsPopoverVisible,
    };

    const onGptClick: OnGptClick = (componentId, setValue, mode) => {
      if (mode) {
        this.props.setMode({
          pageId: this.props.currentWebsitePage.id,
          mode,
        });
      }
      const { setIsPopoverVisible } = gptPopoverObject;
      const { setInputValue } = gptInputObject;
      setIsPopoverVisible(true);
      if (setValue) {
        setInputValue(`${componentId}: `);
      }
    };

    let displayPageScheme = () => {
      const pageId = getCurrentPageId();

      const singlePageStatus = this.props.getPageLoadStatus(pageId);
      if (singlePageStatus !== LoadStatus.LOADED && singlePageStatus !== LoadStatus.ERROR) {
        return (
          <div style={{ paddingTop: "10px", paddingBottom: "40px" }}>
            <SpinnerBox text="Rendering page components..." />
          </div>
        );
      }

      const singleWebsiteStatus = this.props.getSingleWebsiteLoadStatus(
        this.props.currentWebsite
      );
      if (singleWebsiteStatus !== LoadStatus.LOADED) {
        return (
          <div style={{ paddingTop: "10px", paddingBottom: "40px" }}>
            <SpinnerBox text="Loading global website data..." />
          </div>
        );
      }

      if (!this.props.pageComponentCategories.dataFetched) {
        return (
          <div style={{ paddingTop: "10px", paddingBottom: "40px" }}>
            <SpinnerBox text="Loading additional data..." />
          </div>
        );
      } else {
        this.currentWebsite = getCurrentWebsite(this.props.websites.items);

        if (this.currentWebsite === undefined) {
          // If no such website, offer to go to the main dashboard screen.
          return (
            <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              <Empty description={<span>Website not found</span>}>
                <Link to="/">
                  <Button type="primary">Back to your websites</Button>
                </Link>
              </Empty>
            </div>
          );
        }

        // After we check the website, check the page:
        let websitesPagesArray = this.props.websitesPages.items;
        currentWebsitePageUrl = getCurrentWebsitePageUrl(
          this.currentWebsite,
          websitesPagesArray
        );
        let currentWebsitePage = websitesPagesArray.find(
          (websitePage: any) =>
            websitePage.url === currentWebsitePageUrl &&
            websitePage.website === this.currentWebsite.id
        );

        if (currentWebsitePage === undefined) {
          // If no such website page, offer to go to the website pages list.
          return (
            <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              <Empty description={<span>Website page not found</span>}>
                <Link to={"/" + this.currentWebsite.subdomain + "/pages"}>
                  <Button type="primary">Back to pages</Button>
                </Link>
              </Empty>
            </div>
          );
        }
        this.currentPageIndex = websitesPagesArray.indexOf(currentWebsitePage);
        this.currentPageComponents = currentWebsitePage.page_components; //an object containing everything about the page content.

        if (checkIfPageHasComponents(this.currentPageComponents) === false) {
          return (
            <div className="editor__add_button">
              <div className="editor__add_button_box">
                <Button
                  onClick={() => {
                    window.posthog.capture(PosthogEvents.CLICK_ADD_COMPONENT);
                    this.openAddNewComponent(0);
                  }}
                  type="primary"
                  className="js-intercom-tour__open_components_collection"
                  size="large"
                  icon="plus"
                >
                  Add component
                </Button>
                <div className="editor__add_button_info">
                  Browse the components gallery.
                </div>
              </div>
            </div>
          );
        } else {
          let componentsArray = this.currentPageComponents.data.schema;
          let components = componentsArray.map(
            (componentItem: any, componentKey: number) => {
              // we want to find the component category in the categories list to pass the category info (title)
              let categoriesArray = this.props.pageComponentCategories.items;
              let currentComponentCategoryId = componentItem.category;
              let currentComponentCategory = categoriesArray.find(
                (category: any) => {
                  return currentComponentCategoryId === category.id;
                }
              );

              // display the components
              const editorComponentKey = `${currentWebsitePageUrl || "home"}-${
                componentItem.id
              }`;
              return (
                <li className="editor__component" key={editorComponentKey}>
                  <PageComponent
                    toggleWaitingForCommonWebsiteUpdate={
                      this.props.toggleWaitingForCommonWebsiteUpdate
                    }
                    removeWebsiteFormIntegration={
                      this.props.removeWebsiteFormIntegration
                    }
                    openAddNewComponent={this.openAddNewComponent}
                    componentCategory={currentComponentCategory.title} // e.g. "header", "cta-button".
                    componentItem={componentItem}
                    componentKey={componentKey}
                    componentsArray={componentsArray}
                    websites={this.props.websites}
                    formIntegrations={this.props.formIntegrations}
                    auth={this.props.auth}
                    user={this.props.user}
                    history={this.props.history}
                    markFirstComponentWasEditedAsTrue={
                      this.markFirstComponentWasEditedAsTrue
                    }
                    changeWebsitePageComponentVisibility={
                      this.props.changeWebsitePageComponentVisibility
                    }
                    currentPageIndex={this.currentPageIndex}
                    currentWebsite={this.currentWebsite}
                    changeWebsitePageComponentBackgroundColor={
                      this.props.changeWebsitePageComponentBackgroundColor
                    }
                    changeWebsitePageComponentBackgroundImage={
                      this.props.changeWebsitePageComponentBackgroundImage
                    }
                    changeWebsitePageComponentBackgroundImageOverlayOpacity={
                      this.props
                        .changeWebsitePageComponentBackgroundImageOverlayOpacity
                    }
                    changeWebsitePageComponentPaddingTop={
                      this.props.changeWebsitePageComponentPaddingTop
                    }
                    changeWebsitePageComponentPaddingBottom={
                      this.props.changeWebsitePageComponentPaddingBottom
                    }
                    editWebsitePageSchemeToggleWaitingForServer={
                      this.props.editWebsitePageSchemeToggleWaitingForServer
                    }
                    toggleNewWebsitePageEditionsDetected={
                      this.props.toggleNewWebsitePageEditionsDetected
                    }
                    moveWebsitePageComponent={
                      this.props.moveWebsitePageComponent
                    }
                    deleteWebsitePageComponent={
                      this.props.deleteWebsitePageComponent
                    }
                    changeWebsitePageComponentContent={
                      this.props.changeWebsitePageComponentContent
                    }
                    toggleWaitingForConnectPaymentIntegration={
                      this.props.toggleWaitingForConnectPaymentIntegration
                    }
                    toggleWaitingForChangePaymentIntegrationProductsArray={
                      this.props
                        .toggleWaitingForChangePaymentIntegrationProductsArray
                    }
                    toggleWaitingForEditPaymentIntegrationProductDetails={
                      this.props
                        .toggleWaitingForEditPaymentIntegrationProductDetails
                    }
                    toggleWaitingForConfigurePaymentIntegration={
                      this.props.toggleWaitingForConfigurePaymentIntegration
                    }
                    saveWebsiteNewFormIntegrationInState={
                      this.props.saveWebsiteNewFormIntegrationInState
                    }
                    toggleWaitingForResponseOnWebsiteFormIntegrationAdd={
                      this.props
                        .toggleWaitingForResponseOnWebsiteFormIntegrationAdd
                    }
                    saveImageInfoInDB={this.props.saveImageInfoInDB}
                    removeImageFromDB={this.props.removeImageFromDB}
                    changeStripeSecretKey={this.props.changeStripeSecretKey}
                    changeStripePublicKey={this.props.changeStripePublicKey}
                    changeStripeCheckoutProductsArray={
                      this.props.changeStripeCheckoutProductsArray
                    }
                    saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                    toggleIsPageNavHidden={this.props.toggleIsPageNavHidden}
                    toggleIsPageFooterHidden={
                      this.props.toggleIsPageFooterHidden
                    }
                    currentWebsitePage={currentWebsitePage}
                    gptInputObject={gptInputObject}
                    gptPopoverObject={gptPopoverObject}
                    onGptClick={onGptClick}
                    gptAssistantComponentRef={this.gptAssistantComponentRef}
                    queueDataRef={this.queueDataRef}
                    intervalRef={this.intervalRef}
                    forceStopAllFlagRef={this.forceStopAllFlagRef}
                    abortControllersRef={this.abortControllersRef}
                    navHeight={this.state.navHeight}
                  />
                </li>
              );
            }
          );

          let customColorsActiveClassnames = applyCustomColorsClassnames(
            this.currentWebsite
          );

          return (
            // the .comps class is needed to increase the power of the page components styles. Ant.design styles sometime beat them.
            /*// The .style-blue-1 class is needed for styling. It will be dynamical when we create color tuning.*/
            <div>
              {
                <DisplayBadWebsiteOverlay
                  website={this.currentWebsite}
                  history={this.props.history}
                />
              }
              <div className="editor__wrapper" id={"capture_area"}>
                <div className="comps">
                  <div
                    className={
                      this.currentWebsite.color_classname +
                      " " +
                      customColorsActiveClassnames
                    }
                  >
                    <WebsiteNav
                      currentWebsite={this.currentWebsite}
                      componentsArray={componentsArray}
                      saveNavInServer={this.saveNavInServer}
                      isBlogNavEdited={false}
                      currentWebsitePage={currentWebsitePage}
                      navEditionFieldsVisible={
                        this.props.navEditionFieldsVisible
                      }
                      showNavEditionFieldsDrawer={
                        this.props.showNavEditionFieldsDrawer
                      }
                      hideNavEditionFieldsDrawer={
                        this.props.hideNavEditionFieldsDrawer
                      }
                      displayNavOrFooterEditionBox={
                        this.props.displayNavOrFooterEditionBox
                      }
                      blogs={this.props.blogs}
                      saveBlogNavInState={this.props.saveBlogNavInState}
                      saveWebsiteNavInState={this.props.saveWebsiteNavInState}
                      toggleBlogNavEditionsDetected={
                        this.props.toggleBlogNavEditionsDetected
                      }
                      toggleWebsiteNavEditionsDetected={
                        this.props.toggleWebsiteNavEditionsDetected
                      }
                      onGptClick={onGptClick}
                      isWebsiteNav={true}
                      gpt={this.props.gpt}
                      gptAssistantComponentRef={this.gptAssistantComponentRef}
                      user={this.props.user}
                      currentPageIndex={this.currentPageIndex}
                      queueDataRef={this.queueDataRef}
                      intervalRef={this.intervalRef}
                      forceStopAllFlagRef={this.forceStopAllFlagRef}
                      abortControllersRef={this.abortControllersRef}
                      setNavHeight={this.setNavHeight}
                    />

                    {/*// Render the page components*/}
                    <ul className="editor__components">
                      {components}
                      <PopupComponents
                        currentPageIndex={this.currentPageIndex}
                        saveImageInfoInDB={this.props.saveImageInfoInDB}
                        removeImageFromDB={this.props.removeImageFromDB}
                        saveWebsiteDataInStore={
                          this.props.saveWebsiteDataInStore
                        }
                      />
                    </ul>
                    <WebsiteFooter
                      currentWebsite={this.currentWebsite}
                      saveFooterInServer={this.saveFooterInServer}
                      isBlogFooterEdited={false}
                      blogs={this.props.blogs}
                      footerEditionFieldsVisible={
                        this.props.footerEditionFieldsVisible
                      }
                      saveBlogFooterInState={this.props.saveBlogFooterInState}
                      saveWebsiteFooterInState={
                        this.props.saveWebsiteFooterInState
                      }
                      showFooterEditionFieldsDrawer={
                        this.props.showFooterEditionFieldsDrawer
                      }
                      hideFooterEditionFieldsDrawer={
                        this.props.hideFooterEditionFieldsDrawer
                      }
                      displayNavOrFooterEditionBox={
                        this.props.displayNavOrFooterEditionBox
                      }
                      toggleBlogFooterEditionsDetected={
                        this.props.toggleBlogFooterEditionsDetected
                      }
                      toggleWebsiteFooterEditionsDetected={
                        this.props.toggleWebsiteFooterEditionsDetected
                      }
                      onGptClick={onGptClick}
                      isWebsiteFooter={true}
                      gpt={this.props.gpt}
                      gptAssistantComponentRef={this.gptAssistantComponentRef}
                      user={this.props.user}
                      currentWebsitePage={currentWebsitePage}
                      currentPageIndex={this.currentPageIndex}
                      queueDataRef={this.queueDataRef}
                      intervalRef={this.intervalRef}
                      forceStopAllFlagRef={this.forceStopAllFlagRef}
                      abortControllersRef={this.abortControllersRef}
                    />
                  </div>
                </div>
              </div>
              <div className="editor__designed_with">
                Designed with love <Icon type="heart" />
              </div>
              <PopupMask />
              <Modal
                title={
                  fillTemplateModalPage === "form"
                    ? "Tell AI something about your product"
                    : "Choose content type"
                }
                visible={this.state.isFillTemplateModalVisible}
                onOk={this.handleFillTemplateOk}
                onCancel={() => {
                  const templateSubdomain = _.get(
                    this.currentWebsite,
                    "fillTemplateData.templateSubdomain"
                  );
                  window.posthog.capture(
                    PosthogEvents.CLOSE_AI_TEMPLATE_MODAL,
                    {
                      website_id: this.currentWebsite.id,
                      template_subdomain: templateSubdomain,
                    }
                  );
                  this.handleFillTemplateCancel();
                }}
                centered
                width={"auto"}
                footer={
                  fillTemplateModalPage === "form" ? (
                    <GenerateButton
                      isLoading={this.state.isFillTemplateFormLoading}
                    >
                      Generate
                    </GenerateButton>
                  ) : null
                }
                maskClosable={false}
                zIndex={1000}
              >
                <FillTemplateModalContent
                  handleFillTemplateCancel={this.handleFillTemplateCancel}
                  queueDataRef={this.queueDataRef}
                  intervalRef={this.intervalRef}
                  forceStopAllFlagRef={this.forceStopAllFlagRef}
                  setIsFillTemplateFormLoading={
                    this.setIsFillTemplateFormLoading
                  }
                  isLoading={this.state.isFillTemplateFormLoading}
                  abortControllersRef={this.abortControllersRef}
                />
              </Modal>
            </div>
          );
        }
      }
    };

    let isSaveButtonActive: boolean = false;
    isSaveButtonActive =
      this.props.websitesPages.websitePageNewEditionsDetected ||
      this.props.websites.isFooterEditionsDetected ||
      this.props.websites.isNavEditionsDetected ||
      this.props.websites.isCaptchaEditionsDetected ||
      this.props.websites.isPopupEditionsDetected ||
      this.props.websitesPages.websitePageNewNavEditionsDetected;

    let isSaveButtonLoading: boolean = false;
    isSaveButtonLoading = this.props.websites.isWebsiteChangesWaitingForServer;

    // startOnboardingTour('add_component', this.props.user);
    const fillTemplateProgress = _.get(
      this.currentWebsite,
      "fillTemplateData.status"
    );
    const fillTemplateModalPage = _.get(
      this.currentWebsite,
      "fillTemplateData.modalPage"
    );

    const renderButton = () => {
      if (fillTemplateProgress === "inProgress") {
        return <FillTemplateProgressIndicator />;
      }
      return (
        <>
          {this.state.showSaveButtonSuccess && (
            <div className="editor__save_button_tick">
              <Icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </div>
          )}

          <Badge dot={isSaveButtonActive} title="Unsaved changes detected">
            <Button
              onClick={() => {
                this.saveAllChanges(
                  currentWebsiteSubdomain,
                  currentWebsitePageUrl
                );
                const hasUnsavedAiGeneratedContent = _.get(
                  this.currentWebsite,
                  "fillTemplateData.hasUnsavedAiGeneratedContent"
                );
                if (hasUnsavedAiGeneratedContent) {
                  const templateSubdomain = _.get(
                    this.currentWebsite,
                    "fillTemplateData.templateSubdomain"
                  );
                  window.posthog.capture(
                    PosthogEvents.SAVE_AI_GENERATED_CONTENT,
                    {
                      website_id: this.currentWebsite.id,
                      template_subdomain: templateSubdomain,
                    }
                  );
                  this.props.editFillTemplateData({
                    websiteId: this.currentWebsite.id,
                    newData: {
                      hasUnsavedAiGeneratedContent: false,
                    },
                  });
                }
              }}
              title={
                isSaveButtonActive
                  ? "Click to save the changes"
                  : "No changes to save"
              }
              disabled={!isSaveButtonActive}
              htmlType="button"
              type="primary"
              icon="save"
              size="large"
              className="js-tour__save-page"
              loading={isSaveButtonLoading}
            >
              Save changes
            </Button>
          </Badge>
        </>
      );
    };

    return (
      <div
        className={`editor ${getCustomStyleClassNames(
          this.props.currentWebsite
        )}`}
      >
        <Prompt
          when={isSaveButtonActive}
          message="You have unsaved page changes. Are you sure you want to leave?"
        />

        {displayPageScheme()}

        <AddPageComponent
          pageComponents={this.props.pageComponents}
          pageComponentCategories={this.props.pageComponentCategories}
          currentPageComponents={this.currentPageComponents}
          user={this.props.user}
          handleCloseAddComponent={this.handleCloseAddComponent}
          history={this.props.history}
          isAddComponentWindowVisible={this.state.addPageComponentWindowVisible}
          newComponentPosition={this.state.newComponentPosition}
          currentPageIndex={this.currentPageIndex}
          addWebsitePageComponent={this.props.addWebsitePageComponent}
          pasteCopiedWebsitePageComponent={
            this.props.pasteCopiedWebsitePageComponent
          }
          toggleNewWebsitePageEditionsDetected={
            this.props.toggleNewWebsitePageEditionsDetected
          }
          currentWebsiteSubdomain={currentWebsiteSubdomain}
          currentWebsitePageUrl={currentWebsitePageUrl}
          doesComponentClipboardHaveItem={
            this.state.doesComponentClipboardHaveItem
          }
          auth={this.props.auth}
          changeComponentGlobalData={this.props.changeComponentGlobalData}
          websitesPages={this.props.websitesPages}
          changeWebsitePagesStateGlobalData={
            this.props.changeWebsitePagesStateGlobalData
          }
        />

        <div
          className={
            "editor__save_button_box " +
            (isSaveButtonActive &&
            !isSaveButtonLoading &&
            fillTemplateProgress !== "inProgress"
              ? " editor__save_button_box--accent "
              : "")
          }
        >
          {renderButton()}
        </div>

        <CustomColorsStyles currentWebsite={this.currentWebsite} />
        <CustomFontsStyles currentWebsite={this.currentWebsite} />
        <CustomStyles currentWebsite={this.currentWebsite} />
      </div>
    );
  }
}

export default Editor;
