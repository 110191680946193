import React from "react";
import SpinnerBox from "../../SpinnerBox";
import WebsitePagesDownloadButtons from "./WebsitePagesDownloadButtons";
import BlogHomePageDownloadButton from "./BlogHomePageDownloadButton";
import BlogPostsDownloadButtons from "./BlogPostsDownloadButtons";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../../../store/websites/thunks";
import { getWebsiteInitialPagesLoadStatus } from "../../../../store/websitePages/thunks";
import { getInitialPostsStatus } from "../../../../store/blogPosts/thunks";
import { GetCurrentStoreData } from "../../../../store/websites/types";
import { LoadStatus } from "../../../../enums/enums";

interface Props {
  currentWebsitePages: any;
  currentWebsite: any;
  websitesPages: any;
  blogs: any;
  blogPosts: any;

  getInitialPostsStatus: any;
  getWebsiteInitialPagesLoadStatus: any;
  getCurrentStoreData: GetCurrentStoreData;
}

const DownloadButtons = (props: Props): JSX.Element => {
  const {
    currentWebsitePages,
    currentWebsite,
    websitesPages,
    blogs,
    blogPosts,
  } = props;
  const { currentBlog } = props.getCurrentStoreData();
  const pagesStatus = props.getWebsiteInitialPagesLoadStatus(currentWebsite);
  const postsStatus = props.getInitialPostsStatus(currentBlog);
  if (pagesStatus !== LoadStatus.LOADED || postsStatus !== LoadStatus.LOADED) {
    return <SpinnerBox text="Loading pages..." />;
  }
  

  return (
    <>
      <WebsitePagesDownloadButtons
        currentWebsitePages={currentWebsitePages}
        currentWebsite={currentWebsite}
        websitesPages={websitesPages}
      />
      <BlogHomePageDownloadButton
        blogs={blogs}
        currentWebsite={currentWebsite}
        websitesPages={websitesPages}
      />
      <BlogPostsDownloadButtons
        blogPosts={blogPosts}
        currentWebsite={currentWebsite}
        blogs={blogs}
        websitesPages={websitesPages}
      />
    </>
  );
};

export default connect(null, {
  getInitialPostsStatus,
  getWebsiteInitialPagesLoadStatus,
  getCurrentStoreData,
})(DownloadButtons);
