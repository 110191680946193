import { Button, Drawer, Icon, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { contentEditionDrawerWidth } from "../../data/settings";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import { checkIfComponentHasBrightCustomColor } from "../../helpers/editor/checkIfComponentHasBrightCustomColor";
import { checkIfCustomColorsActive } from "../../helpers/editor/checkIfCustomColorsActive";
import { checkIfNavFixed } from "../../helpers/editor/checkIfNavFixed";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { getCustomBackgroundColorData } from "../../helpers/getCustomBackgroundColorData";
import { openNotification } from "../../helpers/openNotification";
import capitalizeString from "../../helpers/strings/capitalizeString";
import { displayPlainText } from "../../helpers/content_displayers/displayPlainText";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import WebsiteCTA from "./WebsiteCTA";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { ReactComponent as MaterialWandSm } from "../../img/icons/material_wand_sm.svg";
import { GptState } from "../../store/gpt/types";
import classNames from "classnames";
import {
  AbortControllersRef,
  DropdownContainerRef,
  OnGptClick,
  QueueItem,
} from "../../components/editor/ai2/types";
import _ from "lodash";
import InputDropdown from "../../components/editor/ai2/contextual_input/InputDropdown";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { insertIdIntoCode } from "../../helpers/strings/insertIdIntoCode";
import { convertAttributeStringToObject } from "../../helpers/convertAttributeStringToObject";
import { getActualComponentName } from "../../helpers/getActualComponentName";
import { LoadStatus } from "../../enums/enums";

interface Props {
  user?: any;

  currentWebsite: any;
  isBlogNavEdited: boolean;
  componentsArray: any;
  currentWebsitePage?: any;
  navEditionFieldsVisible: boolean;

  saveNavInServer: any;
  showNavEditionFieldsDrawer: any;
  hideNavEditionFieldsDrawer: any;
  displayNavOrFooterEditionBox: any;

  blogs: any;
  saveBlogNavInState: any;
  saveWebsiteNavInState: any;
  toggleBlogNavEditionsDetected: any;
  toggleWebsiteNavEditionsDetected: any;
  onGptClick?: OnGptClick;
  isWebsiteNav?: boolean;
  gpt?: GptState;
  gptAssistantComponentRef?: any;
  currentPageIndex?: number;
  queueDataRef?: React.MutableRefObject<QueueItem[]>;
  intervalRef?: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef?: React.MutableRefObject<boolean>;
  abortControllersRef?: AbortControllersRef;
  setNavHeight?: any;

  getSingleBlogLoadStatus?: any;
}

const WebsiteNav = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    blogs,
    isBlogNavEdited,
    saveNavInServer,
    componentsArray,
    currentWebsitePage,
    showNavEditionFieldsDrawer,
    hideNavEditionFieldsDrawer,
    navEditionFieldsVisible,
    displayNavOrFooterEditionBox,
    onGptClick,
    isWebsiteNav,
    gpt,
    gptAssistantComponentRef,
    currentPageIndex,
    queueDataRef,
    intervalRef,
    forceStopAllFlagRef,
    setNavHeight,
  } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isInputDropdownVisible, setIsInputDropdownVisible] = useState(false);
  const [componentKey, setComponentKey] = useState(1);

  const gptInputTextAreaRef = useRef(null);
  const dropdownContainerRef: DropdownContainerRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current && setNavHeight) {
      setNavHeight(navRef.current.clientHeight);
    }
  }, [_.get(navRef, "current.clientHeight"), navEditionFieldsVisible]);

  const generator = new generateContentDataObject();

  // isBlogNavEdited is needed to detect when we call displayNav in the <BlogPostEditor>. We need another "save in state" function and we also need to get the nav object not from website but from blog model.
  // if editing blog nav - isBlogNavEdited=true
  // if editing website nav - isBlogNavEdited=false
  //currentWebsitePage is needed to change nav bar for a particular page. e.g. hide it. when editing Nav from blog posts, we do not pass the currentWebsitePage argument, so currentWebsitePage===undefined
  let navObject: any;
  let currentBlog = getCurrentBlog(blogs.items, currentWebsite);

  if (props.getSingleBlogLoadStatus)  {
    const status = props.getSingleBlogLoadStatus(currentBlog);
    if (status !== LoadStatus.LOADED) {
      return <div>Loading nav for blog...</div>
    }
  }

  // navObject contains links, buttons, logo and any other nav-related objects. We have different nav bars for website and blog.
  if (isBlogNavEdited) {
    navObject = currentBlog.nav;
  } else {
    navObject = currentWebsite.nav;
  }

  if (
    Object.getOwnPropertyNames(navObject).length === 0 ||
    navObject === undefined
  ) {
    // if the navObject is empty ({}) or undefined we fill it in with some default content
    // We set up default footer when a website is create, so this unlikely to happen.

    if (isBlogNavEdited) {
      if (currentBlog) {
        props.saveBlogNavInState({
          navObject: generator.setUpNav({
            companyTitle: capitalizeString(currentWebsite.subdomain) + " blog",
          }),
          currentBlogId: currentBlog.id,
        });
      } else {
        openNotification(
          "Blog not found",
          "We are sorry, but we failed to find your blog. Could you please report the issue via live chat? We will help.",
          "Meh",
          "error"
        );
      }
    } else {
      props.saveWebsiteNavInState({
        navObject: generator.setUpNav({
          companyTitle: capitalizeString(currentWebsite.subdomain),
        }),
        currentWebsiteSubdomain: currentWebsite.subdomain,
      });
    }

    // save this generated nav in server
    saveNavInServer(currentBlog);

    return null;
  } else {
    let isFirstComponentBgDark = false;

    let firstComponent = componentsArray[0]; // we pass [] as componentsArray when an entity does not have any components. E.g. when we edit a blog post.
    let firstComponentBgColor =
      firstComponent && firstComponent.settings.background.color;

    isFirstComponentBgDark =
      firstComponentBgColor === "black" ||
      firstComponentBgColor === "accent" ||
      firstComponentBgColor === "mature";

    if (
      firstComponent &&
      getActualComponentName(firstComponent).indexOf("text-01") === 0
    ) {
      //text-01 is a specific component. The BG of this component is always white, when changing the BG actually changes the text bg color, not the whole component bg color.
      isFirstComponentBgDark = false;
    }

    let editorNavElementPositioningClassname = "";
    let templatesNavElementPositioningClassname = "";

    let isNavFixed = checkIfNavFixed(navObject);

    const customBackgroundColorData =
      getCustomBackgroundColorData(firstComponent);

    if (
      customBackgroundColorData.isActive &&
      customBackgroundColorData.isDark
    ) {
      isFirstComponentBgDark = true;
    }

    if (isNavFixed) {
      editorNavElementPositioningClassname = " editor__templates_nav--sticky ";
      templatesNavElementPositioningClassname = " nav-02--sticky ";
    } else if (
      firstComponent &&
      getActualComponentName(firstComponent).indexOf("header") !== 0
    ) {
      /* Nav is static when the first component is not header. If header - is is position:absolute */
      isFirstComponentBgDark = false;
      editorNavElementPositioningClassname = "editor__templates_nav--static";
    }

    // If the custom color activated an this custom color is bright, we declare isFirstComponentBgDark as false;
    let ifComponentHasBrightCustomColor =
      checkIfComponentHasBrightCustomColor(currentWebsite);
    // But this only works for the components which has firstComponentBgColor == 'accent' as active.
    if (ifComponentHasBrightCustomColor && firstComponentBgColor === "accent") {
      isFirstComponentBgDark = false;
    }

    // Because style-yellow-1 accent background is too bright to have white text (need to set isFirstComponentBgDark to false to enable black text).
    // But only for the default 'style-yellow-1' palette is used (because yellow is too bright)
    // AND custom colors are not activated (because user can use another yellow - more darker - we detect the contrast level by JS and then decide)
    if (
      currentWebsite.color_classname === "style-yellow-1" &&
      firstComponentBgColor === "accent" &&
      checkIfCustomColorsActive(currentWebsite) === false
    ) {
      isFirstComponentBgDark = false;
    }

    if (isNavFixed) {
      if (isFirstComponentBgDark) {
        templatesNavElementPositioningClassname += " nav-02--sticky--black ";
      } else {
        templatesNavElementPositioningClassname += " nav-02--sticky--white ";
      }
    }

    let renderNavLinks = () => {
      // nav links can be one of these 2 types: 'list' (all websites before August 30 2020 have it) and 'navLinks'. 'navLinks' allows to add dropdowns to the nav menu.

      if (navObject.componentData.navLinks !== undefined) {
        let topLevelNavLinksArray =
          navObject.componentData.navLinks.content.topLevelItems;
        let dropdownNavLinksArray =
          navObject.componentData.navLinks.content.dropdownItems;

        let navLinksColorClassname = " button--black-outline ";
        if (isFirstComponentBgDark) {
          navLinksColorClassname = " button--white-outline ";
        }

        return topLevelNavLinksArray.map((item: any, key: number) => {
          // check if this is a dropdown holder
          let isCurrentLevelNavLinkHasSubmenuItems = false;

          let currentItemId = item.id;
          let currentItemDropdownArray =
            dropdownNavLinksArray[JSON.stringify(currentItemId)];
          if (currentItemDropdownArray !== undefined) {
            //means this nav link doesn't have any associated dropdown submenu items
            isCurrentLevelNavLinkHasSubmenuItems = true;
          }

          if (isCurrentLevelNavLinkHasSubmenuItems) {
            // usually an ID is always set, but let's add an extra check
            if (currentItemId !== undefined) {
              let currentItemDropdownArray =
                dropdownNavLinksArray[JSON.stringify(currentItemId)];

              const currentItemDropdownJSX = []; //an array of JSX components

              let componentInputsCount = currentItemDropdownArray.length;
              for (let i = 0; i < componentInputsCount; i++) {
                if (
                  currentItemDropdownArray[i].title &&
                  currentItemDropdownArray[i].title !== ""
                ) {
                  currentItemDropdownJSX.push(
                    <li
                      className="dropdown__item"
                      key={"drop-down-item-" + i + "-" + key}
                    >
                      <a
                        href={returnHref(currentItemDropdownArray[i].href)}
                        className={
                          "button button--empty button--black-outline "
                        }
                      >
                        {/*always black buttons because the .dropdown element has background white*/}
                        <span className="button__text">
                          {currentItemDropdownArray[i].title}
                        </span>
                      </a>
                    </li>
                  );
                }
              }

              return (
                <li className="nav-02__item" key={key}>
                  <button
                    className={
                      "button button--has-dropdown button--has-arrow button--empty js-toggle-dropdown " +
                      navLinksColorClassname
                    }
                  >
                    <span className="button__text">{item.title}</span>
                    <span className="dropdown">
                      <ul className="dropdown__list">
                        {currentItemDropdownJSX}
                      </ul>
                    </span>
                  </button>
                </li>
              );
            }
          } else if (item.title && item.title !== "") {
            return (
              <li className="nav-02__item" key={key}>
                <a
                  className={"button button--empty " + navLinksColorClassname}
                  href={returnHref(item.href)}
                >
                  <span className="button__text">{item.title}</span>
                </a>
              </li>
            );
          }
        });
      } else if (navObject.componentData.list !== undefined) {
        // Previously we had 'list' in the nav object. But then we created new object for navigation links - 'navLinks'. 'navLinks' allows to add dropdown submenu items. When a user with old nav (which contains 'list' in the nav object) renders their website, we convert 'list' to 'navLinks' below (see 'let updateToNavLinks').

        let navLinksArray = navObject.componentData.list.content.items;

        let navLinksColorClassname = " button--black-outline ";
        if (isFirstComponentBgDark) {
          navLinksColorClassname = " button--white-outline ";
        }

        return navLinksArray.map((item: any, key: number) => {
          if (item.title && item.title !== "") {
            return (
              <li className="nav-02__item" key={key}>
                <a
                  className={"button button--empty " + navLinksColorClassname}
                  href={returnHref(item.href)}
                >
                  <span className="button__text">{item.title}</span>
                </a>
              </li>
            );
          }
        });
      }
    };
    let logoContent = navObject.componentData.navLogo.content;

    let burgerColorClassname = " burger--black ";
    // if(this.isFirstComponentBgDark){
    //     burgerColorClassname = ' burger--white ';
    // }

    let mobileNavBackgroundColorClassname = "  ";
    if (isFirstComponentBgDark) {
      mobileNavBackgroundColorClassname = " nav-02__list--black ";
    }

    let textLogoColorClassname = "  ";
    if (isFirstComponentBgDark) {
      textLogoColorClassname = " text-white ";
    }

    // Previously we had 'list' in the nav object. But then we created new object for navigation links - 'navLinks'. 'navLinks' allows to add dropdown submenu items. When a user with old nav (which contains 'list' in the nav object) renders their website, we convert 'list' to 'navLinks' here.
    let updateToNavLinks = (navObject) => {
      if (navObject.componentData.navLinks === undefined) {
        let newNavObject = JSON.parse(JSON.stringify(navObject));
        newNavObject.componentData.navLinks = {
          ...newNavObject.componentData.list,
        };
        newNavObject.componentData.navLinks.content.topLevelItems = [
          ...newNavObject.componentData.list.content.items,
        ];
        newNavObject.componentData.navLinks.content.dropdownItems = {};

        delete newNavObject.componentData.list;

        for (
          let i = 0;
          i < newNavObject.componentData.navLinks.content.topLevelItems.length;
          i++
        ) {
          newNavObject.componentData.navLinks.content.topLevelItems[i].id = i;
        }
        return newNavObject;
      } else {
        return navObject;
      }
    };

    let isHiddenOnThisPage = false;
    // user can remove nav bar on a particular page. but not for blog posts.
    if (currentWebsitePage && currentWebsitePage.is_nav_hidden === true) {
      isHiddenOnThisPage = true;
    }

    const isBeingModifiedByAi = isWebsiteNav
      ? gpt.currentlyModifiedComponents.includes("navigation")
      : false;

    const fillTemplateStatus = _.get(currentWebsite, [
      "fillTemplateData",
      "status",
    ]);

    const displayText = () => {
      const version = _.get(navObject, "settings.version");
      if (version >= 2) {
        return (
          <span
            className={"content-text nav-02__logo_text " + textLogoColorClassname}
            dangerouslySetInnerHTML={{ __html: logoContent.companyTitle.text }}
          />
        );
      }
      return (
        <span className={"content-text nav-02__logo_text " + textLogoColorClassname}>
          {displayPlainText(logoContent.companyTitle.text)}
        </span>
      );
    };

    return (
      <>
        <div
          className={classNames(
            "editor__templates_nav",
            editorNavElementPositioningClassname,
            {
              "editor__templates_nav--force-visible-controls":
                isInputDropdownVisible,
            }
          )}
          style={{
            zIndex: 501 + componentsArray.length,
          }}
        >
          <div className="custom_fonts">
            <nav
              key={componentKey}
              ref={navRef}
              className={classNames(
                "nav-02 highlighted-on-focus",
                currentWebsite.active_font_classname,
                templatesNavElementPositioningClassname,
                {
                  "nav-02--hidden": isHiddenOnThisPage,
                  [navObject.customClasses]: !!navObject.customClasses,
                  "is-being-edited": navEditionFieldsVisible,
                }
              )}
              id="uni-navigation-bar"
              style={{
                position: isBeingModifiedByAi ? "relative" : undefined,
              }}
              {...convertAttributeStringToObject(navObject.customAttributes)}
            >
              {navObject.customJs &&
                !_.get(currentWebsitePage, "is_nav_hidden") && (
                  <Tooltip
                    placement="bottomLeft"
                    title={
                      "The navigation bar has custom JavaScript logic. Its appearance may be different on a real webpage comparing to the way it looks inside the builder."
                    }
                    mouseLeaveDelay={0}
                    overlayStyle={{ maxWidth: "300px" }}
                    arrowPointAtCenter
                  >
                    <Icon
                      type="warning"
                      style={{
                        color: isFirstComponentBgDark
                          ? "#fff"
                          : "var(--orange)",
                        fontSize: "16px",
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                      }}
                    />
                  </Tooltip>
                )}
              {fillTemplateStatus !== "inProgress" && (
                <div className="editor__nav_edit_control">
                  <ul className="page-component__component_controls_list">
                    {isWebsiteNav && (
                      <li
                        className="page-component__component_controls_item"
                        ref={dropdownContainerRef}
                      >
                        <InputDropdown
                          currentWebsitePage={currentWebsitePage}
                          currentPageIndex={currentPageIndex}
                          currentWebsite={currentWebsite}
                          componentIds={["navigation"]}
                          textAreaRef={gptInputTextAreaRef}
                          queueDataRef={queueDataRef}
                          intervalRef={intervalRef}
                          dropdownContainerRef={dropdownContainerRef}
                          isDropdownVisible={isInputDropdownVisible}
                          setIsDropdownVisible={setIsInputDropdownVisible}
                          forceStopAllFlagRef={forceStopAllFlagRef}
                          abortControllersRef={props.abortControllersRef}
                        >
                          <Button
                            size="small"
                            htmlType="button"
                            type="primary"
                            title="Edit the navigation with AI"
                            className="page-component__component_control white-border page-component__component_control--ai"
                            onClick={() => {
                              window.posthog.capture(
                                PosthogEvents.CLICK_ASK_AI,
                                {
                                  website_id: currentWebsite.id,
                                  component: "navigation",
                                }
                              );
                            }}
                          >
                            <MaterialWandSm className="gpt-assistant__icon" />{" "}
                            Ask AI
                          </Button>
                        </InputDropdown>
                      </li>
                    )}
                    <li className="page-component__component_controls_item">
                      <Button
                        onClick={() => {
                          window.posthog.capture(PosthogEvents.CLICK_EDIT, {
                            component: "navigation",
                          });
                          showNavEditionFieldsDrawer();
                          if (isBlogNavEdited) {
                            if (currentBlog) {
                              props.saveBlogNavInState({
                                navObject: updateToNavLinks(navObject),
                                currentBlogId: currentBlog.id,
                              });
                            } else {
                              openNotification(
                                "Blog not found",
                                "We are sorry, but we failed to find your blog. Could you please report the issue via live chat? We will help.",
                                "Whoa",
                                "error"
                              );
                            }
                          } else {
                            props.saveWebsiteNavInState({
                              navObject: updateToNavLinks(navObject),
                              currentWebsiteSubdomain: currentWebsite.subdomain,
                            });
                          }
                        }}
                        title="Edit nav bar"
                        size="small"
                        htmlType="button"
                        type="primary"
                        icon="edit"
                        className="white-border"
                        disabled={isBeingModifiedByAi}
                      >
                        Edit navigation
                      </Button>
                    </li>
                  </ul>
                </div>
              )}

              <div className="container container--large">
                <div className="nav-02__box">
                  <div className="nav-02__logo">
                    <a
                      className="nav-02__link"
                      href={
                        logoContent.href ? returnHref(logoContent.href) : "/"
                      }
                      target="_self"
                    >
                      {logoContent.src && logoContent.src !== "" && (
                        <img
                          className="nav-02__logo_img"
                          src={formatImageUrl(logoContent.src)}
                          height={logoContent.logoHeight || 30}
                          alt={logoContent.alt}
                        />
                      )}
                      {/*30 is the default logo height. used in ContentInput (changeNavLogoHeight), in the Editor (displayNav) and in the render nav.html*/}

                      {logoContent.companyTitle.text &&
                        logoContent.companyTitle.text !== "" &&
                        displayText()}
                    </a>
                  </div>
                  <div className="nav-02__links js-menu">
                    <ul className="nav-02__list">
                      {renderNavLinks()}

                      <li
                        className={
                          "nav-02__item " + mobileNavBackgroundColorClassname
                        }
                      >
                        <WebsiteCTA
                          componentItem={navObject}
                          isDarkBg={isFirstComponentBgDark}
                          isForcedWhiteButtonElements={isFirstComponentBgDark}
                        />
                      </li>
                    </ul>
                    <div className="nav-02__burger">
                      <button
                        className={
                          "burger js-open-menu " + burgerColorClassname
                        }
                        type="button"
                      >
                        <div className="burger__box">
                          <div className="burger__inner"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <Drawer
            title={
              isBlogNavEdited
                ? "Edit the blog navigation"
                : "Edit the website navigation"
            }
            placement="right"
            closable={false}
            onClose={hideNavEditionFieldsDrawer}
            visible={navEditionFieldsVisible}
            width={contentEditionDrawerWidth}
            maskStyle={{ cursor: "pointer" }}
            afterVisibleChange={(visible) => {
              if (visible) {
                document.body.style.removeProperty("overflow");
                document.body.style.removeProperty("touch-action");
              }
            }}
          >
            {displayNavOrFooterEditionBox(
              currentWebsite,
              navObject.componentData,
              isBlogNavEdited
                ? props.saveBlogNavInState
                : props.saveWebsiteNavInState,
              isBlogNavEdited
                ? props.toggleBlogNavEditionsDetected
                : props.toggleWebsiteNavEditionsDetected,
              hideNavEditionFieldsDrawer,
              "nav",
              isFirstComponentBgDark,
              isBlogNavEdited,
              () => {
                setComponentKey((componentKey) => componentKey + 1);
              }
            )}
          </Drawer>
        </div>
        {navObject.customCss && (
          <style>
            {insertIdIntoCode(navObject.customCss, "#uni-navigation-bar")}
          </style>
        )}
      </>
    );
  }
};

export default WebsiteNav;
