import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import SettingsBox from "./SettingsBox";
import { Button, Icon, Popover, Tabs, Empty, Divider } from "antd";
import SpinnerBox from "./SpinnerBox";
import { projectUrl } from "../../data/constants";
import UpdateBlogURLForm from "./UpdateBlogURLForm";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import { defaultBlogUrl } from "../../data/constants";
import { api, urls } from "../../data/urls";
import UpdateBlogMetaTitleForm from "../blog/UpdateBlogMetaTitleForm";
import UpdateBlogMetaDescriptionForm from "../blog/UpdateBlogMetaDescriptionForm";

import OpenGraphImageUploader from "./OpenGraphImageUploader";

import AddBlogCustomCodeForm from "../blog/AddBlogCustomCodeForm";
import FaviconUploader from "./FaviconUploader";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import WebhookMenu from "./WebhookMenu";
import { connect } from "react-redux";
import { toggleBlogPostChangeInProgress } from "../../store/blogPosts/actions";
import {
  blogCustomCodeUpdateWaitingForServer,
  blogCustomCodeUpdateToggleInvalidAttempt,
  blogCustomCodeUpdateErrorMessage,
  blogExtraCustomCodeUpdateWaitingForServer,
  blogExtraCustomCodeUpdateToggleInvalidAttempt,
  blogExtraCustomCodeUpdateErrorMessage,
  blogCustomCodePostsUpdateWaitingForServer,
  blogCustomCodePostsUpdateToggleInvalidAttempt,
  blogCustomCodePostsUpdateErrorMessage,
  blogExtraCustomCodePostsUpdateWaitingForServer,
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt,
  blogExtraCustomCodePostsUpdateErrorMessage,
  blogMetaTitleUpdateToggleInvalidAttempt,
  blogMetaTitleUpdateErrorMessage,
  blogMetaTitleUpdateWaitingForServer,
  blogMetaDescriptionUpdateToggleInvalidAttempt,
  blogMetaDescriptionUpdateErrorMessage,
  blogMetaDescriptionUpdateWaitingForServer,
  urlUpdateWaitingForServer,
  urlUpdateToggleInvalidAttempt,
  urlUpdateErrorMessage,
} from "../../store/blogs/actions";
import { ActiveMenu } from "./SettingsDrawerContent";
import { createAndSavePost } from "../../store/blogPosts/thunks";
import GooglePreview from "./GooglePreview";
import { getCurrentStoreData, saveWebsiteBackup } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";

const TabPane = Tabs.TabPane;

interface Props extends RouteComponentProps {
  history: any;

  auth: any;
  user: any;
  websites: any;
  blogs: any;
  blogPosts: any;
  blogCustomCodeUpdateWaitingForServer: any;
  blogCustomCodeUpdateToggleInvalidAttempt: any;
  blogCustomCodeUpdateErrorMessage: any;
  blogExtraCustomCodeUpdateWaitingForServer: any;
  blogExtraCustomCodeUpdateToggleInvalidAttempt: any;
  blogExtraCustomCodeUpdateErrorMessage: any;
  blogCustomCodePostsUpdateWaitingForServer: any;
  blogCustomCodePostsUpdateToggleInvalidAttempt: any;
  blogCustomCodePostsUpdateErrorMessage: any;
  blogExtraCustomCodePostsUpdateWaitingForServer: any;
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt: any;
  blogExtraCustomCodePostsUpdateErrorMessage: any;
  blogMetaTitleUpdateToggleInvalidAttempt: any;
  blogMetaTitleUpdateErrorMessage: any;
  blogMetaTitleUpdateWaitingForServer: any;
  blogMetaDescriptionUpdateToggleInvalidAttempt: any;
  blogMetaDescriptionUpdateErrorMessage: any;
  blogMetaDescriptionUpdateWaitingForServer: any;
  urlUpdateWaitingForServer: any;
  urlUpdateToggleInvalidAttempt: any;
  urlUpdateErrorMessage: any;
  toggleBlogPostChangeInProgress: any;
  createAndSavePost: () => any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveBlogPostDataInStore: any;
  saveBlogDataInStore: any;
  publishPost: any;
  currentMenuTitle: ActiveMenu;
  getCurrentStoreData: GetCurrentStoreData;
  saveWebsiteBackup: any;
}

interface State {
  blog_url: string;
  meta_title: string;
  meta_description: string;
}

class BlogSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentBlog } = this.props.getCurrentStoreData();

    this.state = {
      blog_url: _.get(currentBlog, "url", ""),
      meta_title: _.get(currentBlog, "meta_title", ""),
      meta_description: _.get(currentBlog, "meta_description", ""),
    };
  }

  setMetaTitle = (meta_title: string) => {
    this.setState({ meta_title });
  };
  setMetaDescription = (meta_description: string) => {
    this.setState({ meta_description });
  };
  setUrl = (blog_url: string) => {
    this.setState({ blog_url });
  };

  componentDidMount(): void {
    this.clearUpdateBlogUrlInput();
  }

  clearUpdateBlogUrlInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.urlUpdateToggleInvalidAttempt(false);
    this.props.urlUpdateErrorMessage(undefined);
  };

  clearUpdateBlogCustomCodeInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogCustomCodeUpdateToggleInvalidAttempt(false);
    this.props.blogCustomCodeUpdateErrorMessage(undefined);
  };

  clearUpdateBlogExtraCustomCodeInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogExtraCustomCodeUpdateToggleInvalidAttempt(false);
    this.props.blogExtraCustomCodeUpdateErrorMessage(undefined);
  };

  clearUpdateBlogCustomCodePostsInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogCustomCodePostsUpdateToggleInvalidAttempt(false);
    this.props.blogCustomCodePostsUpdateErrorMessage(undefined);
  };

  clearUpdateBlogExtraCustomCodePostsInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogExtraCustomCodePostsUpdateToggleInvalidAttempt(false);
    this.props.blogExtraCustomCodePostsUpdateErrorMessage(undefined);
  };

  clearUpdateBlogMetaTitle = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogMetaTitleUpdateToggleInvalidAttempt(false);
    this.props.blogMetaTitleUpdateErrorMessage(undefined);
  };

  clearUpdateBlogMetaDescription = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.blogMetaDescriptionUpdateToggleInvalidAttempt(false);
    this.props.blogMetaDescriptionUpdateErrorMessage(undefined);
  };

  displaySeobotIntegration = () => {
    return (
      <div className="settings-box__input">
        <div className="settings-box__input_label">
          <div>
            <Popover
              content={
                <div style={{ width: 300 }}>
                  Copy this API key and paste it into{" "}
                  <a
                    className="underlined_link"
                    href={urls.seobotEditor}
                    target="_blank"
                  >
                    SEObot
                  </a>
                  . Once integrated, SEObot will automatically generate and
                  publish SEO-optimized posts for your blog. Please don't expose
                  this API key to anyone.
                </div>
              }
            >
              <Icon type="info-circle" />
            </Popover>
          </div>
          <div className="settings-box__input_info_text">
            SEObot integration:
          </div>
        </div>
        <WebhookMenu webhook="seobot" setBlogUrl={this.setUrl} />
      </div>
    );
  };

  render() {
    if (
      this.props.blogs.dataFetched &&
      this.props.websites.dataFetched
    ) {
      let currentWebsite = getCurrentWebsite(this.props.websites.items);
      let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);

      if (!currentBlog) {
        const handleOnCreateArticle = () => {
          this.props.createAndSavePost().then(({ postData, currentBlog }) => {
            this.props.history.push(
              `/${currentWebsite.subdomain}/blog/${postData.id}`
            );
          });
        };
        return (
          <div className="settings__container">
            <SettingsBox titleText="Blog settings">
              {this.displaySeobotIntegration()}
              <Divider className="settings__divider" />
              <div className="settings-box__input">
                <Empty description="You don't have any blog posts yet">
                  <Button
                    type="primary"
                    onClick={handleOnCreateArticle}
                    loading={
                      this.props.blogPosts.isWaitingForCreateBlogPostResponse
                    }
                  >
                    Start writing
                  </Button>
                </Empty>
              </div>
            </SettingsBox>
          </div>
        );
      }

      let currentBlogUrl = currentBlog.url;

      let currentWebsiteUrl = "https://";
      if (currentWebsite.is_custom_domain_active) {
        currentWebsiteUrl = currentWebsiteUrl + currentWebsite.custom_domain;
      } else {
        currentWebsiteUrl =
          currentWebsiteUrl + currentWebsite.subdomain + "." + projectUrl;
      }

      const { currentMenuTitle } = this.props;
      if (currentMenuTitle === "blogSettings") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Blog settings">
              {this.displaySeobotIntegration()}

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Set up another URL for the main page of the blog{" "}
                          <br /> instead of {currentWebsiteUrl}/
                          <b>{currentBlogUrl}</b>
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Blog home page URL:
                  </div>
                </div>
                <UpdateBlogURLForm
                  currentBlogUrl={currentBlog.url}
                  websites={this.props.websites}
                  blogs={this.props.blogs}
                  blogPosts={this.props.blogPosts}
                  clearUpdateBlogUrlInput={this.clearUpdateBlogUrlInput}
                  auth={this.props.auth}
                  history={this.props.history}
                  urlUpdateWaitingForServer={
                    this.props.urlUpdateWaitingForServer
                  }
                  urlUpdateToggleInvalidAttempt={
                    this.props.urlUpdateToggleInvalidAttempt
                  }
                  urlUpdateErrorMessage={this.props.urlUpdateErrorMessage}
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  blog_url={this.state.blog_url}
                  setUrl={this.setUrl}
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta title of the blog home page.
                          <br />
                          Optimal length is 50-60 characters.
                          <br />
                          {this.state.meta_title
                            ? ` Yours is ${this.state.meta_title.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Title:</div>
                </div>

                <UpdateBlogMetaTitleForm
                  currentWebsite={currentWebsite}
                  blogs={this.props.blogs}
                  currentBlog={currentBlog}
                  websites={this.props.websites}
                  clearUpdateBlogMetaTitle={this.clearUpdateBlogMetaTitle}
                  auth={this.props.auth}
                  blogPosts={this.props.blogPosts}
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  blogMetaTitleUpdateWaitingForServer={
                    this.props.blogMetaTitleUpdateWaitingForServer
                  }
                  blogMetaTitleUpdateToggleInvalidAttempt={
                    this.props.blogMetaTitleUpdateToggleInvalidAttempt
                  }
                  blogMetaTitleUpdateErrorMessage={
                    this.props.blogMetaTitleUpdateErrorMessage
                  }
                  meta_title={this.state.meta_title}
                  setMetaTitle={this.setMetaTitle}
                />
              </div>

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta description of the blog home page.
                          <br />
                          Optimal length is 120-158 characters.
                          <br />
                          {this.state.meta_description
                            ? ` Yours is ${this.state.meta_description.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Description:
                  </div>
                </div>

                <UpdateBlogMetaDescriptionForm
                  currentWebsite={currentWebsite}
                  currentBlog={currentBlog}
                  websites={this.props.websites}
                  blogs={this.props.blogs}
                  clearUpdateBlogMetaDescription={
                    this.clearUpdateBlogMetaDescription
                  }
                  auth={this.props.auth}
                  blogPosts={this.props.blogPosts}
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  blogMetaDescriptionUpdateWaitingForServer={
                    this.props.blogMetaDescriptionUpdateWaitingForServer
                  }
                  blogMetaDescriptionUpdateToggleInvalidAttempt={
                    this.props.blogMetaDescriptionUpdateToggleInvalidAttempt
                  }
                  blogMetaDescriptionUpdateErrorMessage={
                    this.props.blogMetaDescriptionUpdateErrorMessage
                  }
                  meta_description={this.state.meta_description}
                  setMetaDescription={this.setMetaDescription}
                />
              </div>

              <GooglePreview
                urlsArray={[this.state.blog_url]}
                overrideTitle={this.state.meta_title}
                overrideDescription={this.state.meta_description}
                type="blog"
                disableLiveUpdate
              />

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Favicon is a small icon placed <br />
                          in top of a browser tab.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Favicon:</div>
                </div>

                <FaviconUploader
                  settingsType="blog"
                  saveDataInStore={this.props.saveBlogDataInStore}
                  auth={this.props.auth}
                  apiUrl={
                    api.blogs.updateBlog.prefix +
                    currentBlog.id +
                    api.blogs.updateBlog.postfix
                  }
                  currentWebsite={currentWebsite}
                  currentBlog={currentBlog}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div style={{ width: 308 }}>
                          Displayed when your blog is shared on social media.
                          Default for each blog post, unless overridden.
                          Recommended size: 1200x630px.
                          <br />
                          <br />
                          If you don't specify any image, we'll look for one in
                          your site's content.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Blog social image:{" "}
                    <span className="settings-box__input_info_subtext">
                      a.k.a. Open Graph image
                    </span>
                  </div>
                </div>

                <OpenGraphImageUploader
                  settingsType="blog"
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  auth={this.props.auth}
                  currentBlog={currentBlog}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                  apiUrl={
                    api.blogs.updateBlog.prefix +
                    currentBlog.id +
                    api.blogs.updateBlog.postfix
                  }
                  currentMetaTitle={this.state.meta_title}
                  currentMetaDescription={this.state.meta_description}
                  disableLiveUpdate
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code to your blog <code>&lt;head&gt;</code>
                          . You can <br />
                          also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Example use cases: Google Domain Verification process,{" "}
                          <br />
                          Google Analytics.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Blog home page <code>&lt;head&gt;</code> code:
                  </div>
                </div>

                <AddBlogCustomCodeForm
                  blogs={this.props.blogs}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"custom_html_code"}
                  waitingForServer={
                    this.props.blogCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.blogCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={this.props.blogCustomCodeUpdateErrorMessage}
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  currentBlog={currentBlog}
                  clearInput={this.clearUpdateBlogCustomCodeInput}
                  isWaitingForServer={
                    this.props.blogs.isWaitingForBlogCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code at the bottom, right before the closing{" "}
                          <code>&lt;/body&gt;</code> tag.
                          <br />
                          You can also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Use it for Intercom or other widgets.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Blog home page <code>&lt;/body&gt;</code> code:
                  </div>
                </div>

                <AddBlogCustomCodeForm
                  blogs={this.props.blogs}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"extra_custom_html_code"}
                  waitingForServer={
                    this.props.blogExtraCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.blogExtraCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={
                    this.props.blogExtraCustomCodeUpdateErrorMessage
                  }
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  currentBlog={currentBlog}
                  clearInput={this.clearUpdateBlogExtraCustomCodeInput}
                  isWaitingForServer={
                    this.props.blogs
                      .isWaitingForBlogExtraCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code to each post of the blog in{" "}
                          <code>&lt;head&gt;</code>. You can <br />
                          also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Example use cases: Google Domain Verification process,{" "}
                          <br />
                          Google Analytics.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Each blog post <code>&lt;head&gt;</code> code:
                  </div>
                </div>

                <AddBlogCustomCodeForm
                  blogs={this.props.blogs}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"custom_html_code_posts"}
                  waitingForServer={
                    this.props.blogCustomCodePostsUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.blogCustomCodePostsUpdateToggleInvalidAttempt
                  }
                  errorMessage={
                    this.props.blogCustomCodePostsUpdateErrorMessage
                  }
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  currentBlog={currentBlog}
                  clearInput={this.clearUpdateBlogCustomCodePostsInput}
                  isWaitingForServer={
                    this.props.blogs
                      .isWaitingForBlogCustomCodePostsUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code at the bottom to every post of the blog,
                          right before the closing <code>&lt;/body&gt;</code>{" "}
                          tag.
                          <br />
                          You can also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Use it for Google Analytics, Intercom or other
                          widgets.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Each blog post <code>&lt;/body&gt;</code> code:
                  </div>
                </div>

                <AddBlogCustomCodeForm
                  blogs={this.props.blogs}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"extra_custom_html_code_posts"}
                  waitingForServer={
                    this.props.blogExtraCustomCodePostsUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props
                      .blogExtraCustomCodePostsUpdateToggleInvalidAttempt
                  }
                  errorMessage={
                    this.props.blogExtraCustomCodePostsUpdateErrorMessage
                  }
                  saveBlogDataInStore={this.props.saveBlogDataInStore}
                  currentBlog={currentBlog}
                  clearInput={this.clearUpdateBlogExtraCustomCodePostsInput}
                  isWaitingForServer={
                    this.props.blogs
                      .isWaitingForBlogExtraCustomCodePostsUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>
            </SettingsBox>
          </div>
        );
      }

      return null;
    } else {
      return <SpinnerBox text="Blog settings are loading..." />;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    user: state.user,
    websites: state.websites,
    blogs: state.blogs,
    blogPosts: state.blogPosts,
  };
};
export default connect(mapStateToProps, {
  blogCustomCodeUpdateWaitingForServer,
  blogCustomCodeUpdateToggleInvalidAttempt,
  blogCustomCodeUpdateErrorMessage,
  blogExtraCustomCodeUpdateWaitingForServer,
  blogExtraCustomCodeUpdateToggleInvalidAttempt,
  blogExtraCustomCodeUpdateErrorMessage,
  blogCustomCodePostsUpdateWaitingForServer,
  blogCustomCodePostsUpdateToggleInvalidAttempt,
  blogCustomCodePostsUpdateErrorMessage,
  blogExtraCustomCodePostsUpdateWaitingForServer,
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt,
  blogExtraCustomCodePostsUpdateErrorMessage,
  blogMetaTitleUpdateToggleInvalidAttempt,
  blogMetaTitleUpdateErrorMessage,
  blogMetaTitleUpdateWaitingForServer,
  blogMetaDescriptionUpdateToggleInvalidAttempt,
  blogMetaDescriptionUpdateErrorMessage,
  blogMetaDescriptionUpdateWaitingForServer,
  urlUpdateWaitingForServer,
  urlUpdateToggleInvalidAttempt,
  urlUpdateErrorMessage,
  toggleBlogPostChangeInProgress,
  createAndSavePost,
  getCurrentStoreData,
  saveWebsiteBackup,
})(withRouter(BlogSettings));
