import axios from "axios";
import { api, apiUrlBase } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {
  ChangePopupBackgroundActiveOptionType,
  ChangePopupBackgroundColorType,
  ChangePopupBackgroundImageType,
  ChangePopupBackgroundOverlayOpacityType,
  ChangePopupBackgroundVideoType,
  ChangePopupContentType,
  DeletePopupPayloadType,
  SaveNewPopupInStatePayloadType,
} from "../../helpers/types/popupTypes";
import {
  ChangePopupCustomBackgroundColor,
  ChangePopupData,
  ChangeWebsiteItemGlobalData,
  ChangeWebsiteNavIntegrationId,
  ChangeWebsitesStateGlobalData,
  EditCustomStyles,
  EditFillTemplateData,
  ToggleIsCreateWebhookWaitingForServer,
  ToggleLoadedWebsitesData,
} from "./types";
import { DEFAULT_ERROR_TITLE, notificationDuration } from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";

const actionsNames = {
  SAVE_WEBSITES_DATA: "SAVE_WEBSITES_DATA" as string,
  WEBSITES_DATA_FETCHED: "WEBSITES_DATA_FETCHED" as string,
  SUBDOMAIN_UPDATE_WAITING_FOR_SERVER:
    "SUBDOMAIN_UPDATE_WAITING_FOR_SERVER" as string,
  SUBDOMAIN_UPDATE_TOGGLE_INVALID_UPDATE:
    "SUBDOMAIN_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  SUBDOMAIN_UPDATE_ERROR_MESSAGE: "SUBDOMAIN_UPDATE_ERROR_MESSAGE" as string,

  CUSTOM_DOMAIN_UPDATE_WAITING_FOR_SERVER:
    "CUSTOM_DOMAIN_UPDATE_WAITING_FOR_SERVER" as string,
  CUSTOM_DOMAIN_UPDATE_TOGGLE_INVALID_UPDATE:
    "CUSTOM_DOMAIN_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE:
    "CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE" as string,

  WEBSITE_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
    "WEBSITE_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER" as string,
  WEBSITE_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE:
    "WEBSITE_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  WEBSITE_CUSTOM_CODE_UPDATE_ERROR_MESSAGE:
    "WEBSITE_CUSTOM_CODE_UPDATE_ERROR_MESSAGE" as string,

  WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
    "WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER" as string,
  WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE:
    "WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE" as string,
  WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE:
    "WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE" as string,

  CUSTOM_DOMAIN_ACTIVATION_WAITING_FOR_SERVER:
    "CUSTOM_DOMAIN_ACTIVATION_WAITING_FOR_SERVER" as string,
  ENABLE_BRANDING_ACTIVATION_WAITING_FOR_SERVER:
    "ENABLE_BRANDING_ACTIVATION_WAITING_FOR_SERVER" as string,

  CREATE_WEBSITE_WAITING_FOR_SERVER:
    "CREATE_WEBSITE_WAITING_FOR_SERVER" as string,
  CREATE_WEBSITE_ERROR_MESSAGE: "CREATE_WEBSITE_ERROR_MESSAGE" as string,
  ADD_SINGLE_WEBSITE_DATA: "ADD_SINGLE_WEBSITE_DATA" as string,

  CLONE_WEBSITE_WAITING_FOR_SERVER:
    "CLONE_WEBSITE_WAITING_FOR_SERVER" as string,

  DELETE_WEBSITE_WAITING_FOR_SERVER:
    "DELETE_WEBSITE_WAITING_FOR_SERVER" as string,

  REMOVE_WEBSITE_FROM_STORE: "REMOVE_WEBSITE_FROM_STORE" as string,

  SAVE_WEBSITE_NAV_IN_STATE: "SAVE_WEBSITE_NAV_IN_STATE" as string,
  SAVE_WEBSITE_FOOTER_IN_STATE: "SAVE_WEBSITE_FOOTER_IN_STATE" as string,
  SAVE_WEBSITE_CAPTCHA_IN_STATE: "SAVE_WEBSITE_CAPTCHA_IN_STATE" as string,

  TOGGLE_DETECTED_NAV_CHANGES: "TOGGLE_DETECTED_NAV_CHANGES" as string,
  TOGGLE_DETECTED_FOOTER_CHANGES: "TOGGLE_DETECTED_FOOTER_CHANGES" as string,
  TOGGLE_DETECTED_CAPTCHA_CHANGES: "TOGGLE_DETECTED_CAPTCHA_CHANGES" as string,
  TOGGLE_DETECTED_POPUP_CHANGES: "TOGGLE_DETECTED_POPUP_CHANGES" as string,

  NAV_CHANGES_WAITING_FOR_SERVER: "NAV_CHANGES_WAITING_FOR_SERVER" as string,
  FOOTER_CHANGES_WAITING_FOR_SERVER:
    "FOOTER_CHANGES_WAITING_FOR_SERVER" as string,
  CAPTCHA_CHANGES_WAITING_FOR_SERVER:
    "CAPTCHA_CHANGES_WAITING_FOR_SERVER" as string,
  POPUP_CHANGES_WAITING_FOR_SERVER:
    "POPUP_CHANGES_WAITING_FOR_SERVER" as string,

  TOGGLE_ADD_NEW_FORM_INTEGRATION_WAITING_FOR_SERVER:
    "TOGGLE_ADD_NEW_FORM_INTEGRATION_WAITING_FOR_SERVER" as string,
  ADD_NEW_FORM_INTEGRATION: "ADD_NEW_FORM_INTEGRATION" as string,
  REMOVE_FORM_INTEGRATION: "REMOVE_FORM_INTEGRATION" as string,

  TOGGLE_CONNECT_PAYMENT_INTEGRATION_WAITING_FOR_SERVER:
    "TOGGLE_CONNECT_PAYMENT_INTEGRATION_WAITING_FOR_SERVER" as string,
  TOGGLE_CHANGE_PAYMENT_INTEGRATION_PRODUCT_ARRAY_WAITING_FOR_SERVER:
    "TOGGLE_CHANGE_PAYMENT_INTEGRATION_PRODUCT_ARRAY_WAITING_FOR_SERVER" as string,
  TOGGLE_EDIT_PAYMENT_INTEGRATION_PRODUCT_DETAILS_WAITING_FOR_SERVER:
    "TOGGLE_EDIT_PAYMENT_INTEGRATION_PRODUCT_DETAILS_WAITING_FOR_SERVER" as string,
  TOGGLE_CONFIGURE_PAYMENT_INTEGRATION_WAITING_FOR_SERVER:
    "TOGGLE_CONFIGURE_PAYMENT_INTEGRATION_WAITING_FOR_SERVER" as string,

  // START OF: Stripe actions =====
  CHANGE_STRIPE_SECRET_KEY: "CHANGE_STRIPE_SECRET_KEY" as string,
  CHANGE_STRIPE_PUBLIC_KEY: "CHANGE_STRIPE_PUBLIC_KEY" as string,
  CHANGE_STRIPE_CHECKOUT_PRODUCT_ARRAY:
    "CHANGE_STRIPE_CHECKOUT_PRODUCT_ARRAY" as string, // This includes 'add product', 'delete product' and 'change product'. We send an updated array as payload.
  // ===== END OF: Stripe actions

  TOGGLE_CHANGE_COLOR_PALETTE_WAITING_FOR_SERVER:
    "TOGGLE_CHANGE_COLOR_PALETTE_WAITING_FOR_SERVER" as string,
  TOGGLE_CHANGE_FONT_WAITING_FOR_SERVER:
    "TOGGLE_CHANGE_FONT_WAITING_FOR_SERVER" as string,
  CHANGE_WEBSITE_NAV_COLOR: "CHANGE_WEBSITE_NAV_COLOR" as string,

  SSL_ACTIVATION_WAITING_FOR_SERVER:
    "SSL_ACTIVATION_WAITING_FOR_SERVER" as string,

  COMMON_WEBSITE_UPDATE_WAITING_FOR_SERVER:
    "COMMON_WEBSITE_UPDATE_WAITING_FOR_SERVER" as string,

  // START OF: UI state
  UPDATE_ACTIVE_DASHBOARD_TAB: "UPDATE_ACTIVE_DASHBOARD_TAB" as string,
  // END OF: UI state

  CLOUDFLARE_PROTECTION__WAITING_FOR_SERVER:
    "CLOUDFLARE_PROTECTION__WAITING_FOR_SERVER" as string,
  TOGGLE_CHANGE_LANGUAGES_WAITING_FOR_SERVER:
    "TOGGLE_CHANGE_LANGUAGES_WAITING_FOR_SERVER" as string,

  TOGGLE_ENABLE_CAPTCHA_WAITING_FOR_SERVER:
    "TOGGLE_ENABLE_CAPTCHA_WAITING_FOR_SERVER" as string,
  TOGGLE_CAPTCHA_SITEKEY_WAITING_FOR_SERVER:
    "TOGGLE_CAPTCHA_SITEKEY_WAITING_FOR_SERVER" as string,

  ADD_NEW_POPUP: "ADD_NEW_POPUP" as string,
  DELETE_POPUP: "DELETE_POPUP" as string,
  CHANGE_POPUP_CONTENT: "CHANGE_POPUP_CONTENT" as string,
  CHANGE_POPUP_BACKGROUND_COLOR: "CHANGE_POPUP_BACKGROUND_COLOR" as string,
  CHANGE_POPUP_BACKGROUND_IMAGE: "CHANGE_POPUP_BACKGROUND_IMAGE" as string,
  CHANGE_POPUP_BACKGROUND_VIDEO: "CHANGE_POPUP_BACKGROUND_VIDEO" as string,
  CHANGE_POPUP_BACKGROUND_ACTIVE_OPTION:
    "CHANGE_POPUP_BACKGROUND_ACTIVE_OPTION" as string,
  CHANGE_POPUP_BACKGROUND_OVERLAY_OPACITY:
    "CHANGE_POPUP_BACKGROUND_OVERLAY_OPACITY" as string,
  CHANGE_POPUP_CUSTOM_BACKGROUND_COLOR: "CHANGE_POPUP_CUSTOM_BACKGROUND_COLOR",
  CHANGE_POPUP_DATA: "CHANGE_POPUP_DATA",

  TOGGLE_LOADED_WEBSITES_DATA: "TOGGLE_LOADED_WEBSITES_DATA",
  EDIT_FILL_TEMPLATE_DATA: "EDIT_FILL_TEMPLATE_DATA",

  TOGGLE_IS_WEBSITE_FROM_TEMPLATE_CREATED:
    "TOGGLE_IS_WEBSITE_FROM_TEMPLATE_CREATED",

  TOGGLE_IS_CREATE_WEBHOOK_WAITING_FOR_SERVER:
    "TOGGLE_IS_CREATE_WEBHOOK_WAITING_FOR_SERVER",

  CHANGE_WEBSITES_STATE_GLOBAL_DATA: "CHANGE_WEBSITES_STATE_GLOBAL_DATA",

  EDIT_CUSTOM_STYLES: "EDIT_CUSTOM_STYLES",

  CHANGE_WEBSITE_ITEM_GLOBAL_DATA: "CHANGE_WEBSITE_ITEM_GLOBAL_DATA",

  CHANGE_WEBSITE_NAV_INTEGRATION_ID: "CHANGE_WEBSITE_NAV_INTEGRATION_ID",

  SET_FULL_WEBSITE_LOAD_STATUS: "SET_FULL_WEBSITE_LOAD_STATUS",
  SAVE_FULL_WEBSITE_TO_STORE: "SAVE_FULL_WEBSITE_TO_STORE",
};

export const fetchWebsites = (successCallback?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(toggleWebsitesFetched(false));
    let accessToken = getState().auth.accessToken;
    const apiUrl = apiUrlBase + `/api/v1/websites/fetch_initial_websites`;
    axios
      .get(apiUrl, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        dispatch(saveSingleWebsiteInState(response.data));
        dispatch(toggleWebsitesFetched(true));

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          "Couldn't fetch websites. Please contact us.",
          "OK",
          "error",
          notificationDuration.long
        );
      })
      .then((response) => {});
  };
};

export const saveWebsitesInState = (data) => {
  return {
    type: actionsNames.SAVE_WEBSITES_DATA as string,
    payload: data as string,
  };
};
export const saveSingleWebsiteInState = (data: any) => {
  return {
    type: actionsNames.ADD_SINGLE_WEBSITE_DATA as string,
    payload: data as string,
  };
};

export const toggleWebsitesFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.WEBSITES_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const subdomainUpdateWaitingForServer = (
  isWaitingForSubdomainUpdateResponse: boolean
) => {
  return {
    type: actionsNames.SUBDOMAIN_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForSubdomainUpdateResponse as boolean,
  };
};
export const subdomainUpdateToggleInvalidAttempt = (
  isInvalidSubdomainUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.SUBDOMAIN_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidSubdomainUpdateAttempt as boolean,
  };
};
export const subdomainUpdateErrorMessage = (
  subdomainUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.SUBDOMAIN_UPDATE_ERROR_MESSAGE as string,
    payload: subdomainUpdateErrorMessage as string | undefined,
  };
};

export const customDomainUpdateWaitingForServer = (
  isWaitingForCustomDomainUpdateResponse: boolean
) => {
  return {
    type: actionsNames.CUSTOM_DOMAIN_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForCustomDomainUpdateResponse as boolean,
  };
};
export const customDomainUpdateToggleInvalidAttempt = (
  isInvalidCustomDomainUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.CUSTOM_DOMAIN_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidCustomDomainUpdateAttempt as boolean,
  };
};
export const customDomainUpdateErrorMessage = (
  customDomainUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.CUSTOM_DOMAIN_UPDATE_ERROR_MESSAGE as string,
    payload: customDomainUpdateErrorMessage as string | undefined,
  };
};

export const websiteCustomCodeUpdateWaitingForServer = (
  isWaitingForWebsiteCustomCodeUpdateResponse: boolean
) => {
  return {
    type: actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForWebsiteCustomCodeUpdateResponse as boolean,
  };
};
export const websiteCustomCodeUpdateToggleInvalidAttempt = (
  isInvalidWebsiteCustomCodeUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidWebsiteCustomCodeUpdateAttempt as boolean,
  };
};
export const websiteCustomCodeUpdateErrorMessage = (
  websiteCustomCodeUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.WEBSITE_CUSTOM_CODE_UPDATE_ERROR_MESSAGE as string,
    payload: websiteCustomCodeUpdateErrorMessage as string | undefined,
  };
};

export const websiteExtraCustomCodeUpdateWaitingForServer = (
  isWaitingForWebsiteExtraCustomCodeUpdateResponse: boolean
) => {
  return {
    type: actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForWebsiteExtraCustomCodeUpdateResponse as boolean,
  };
};
export const websiteExtraCustomCodeUpdateToggleInvalidAttempt = (
  isInvalidWebsiteCustomCodeUpdateAttempt: boolean
) => {
  return {
    type: actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_TOGGLE_INVALID_UPDATE as string,
    payload: isInvalidWebsiteCustomCodeUpdateAttempt as boolean,
  };
};
export const websiteExtraCustomCodeUpdateErrorMessage = (
  websiteCustomCodeUpdateErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.WEBSITE_EXTRA_CUSTOM_CODE_UPDATE_ERROR_MESSAGE as string,
    payload: websiteCustomCodeUpdateErrorMessage as string | undefined,
  };
};

export const customDomainToggleActivationWaitingForServer = (
  isWaitingForCustomDomainActivationResponse: boolean
) => {
  return {
    type: actionsNames.CUSTOM_DOMAIN_ACTIVATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForCustomDomainActivationResponse as boolean,
  };
};
export const sslToggleActivationWaitingForServer = (
  isWaitingForSSLActivationResponse: boolean
) => {
  return {
    type: actionsNames.SSL_ACTIVATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForSSLActivationResponse as boolean,
  };
};
export const toggleCloudflareProtectionWaitingForServer = (
  isWaitingForCloudflareActivationResponse: boolean
) => {
  return {
    type: actionsNames.CLOUDFLARE_PROTECTION__WAITING_FOR_SERVER as string,
    payload: isWaitingForCloudflareActivationResponse as boolean,
  };
};

export const enableBrandingWaitingForServer = (
  isWaitingForEnableBrandingActivationResponse: boolean
) => {
  return {
    type: actionsNames.CUSTOM_DOMAIN_ACTIVATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForEnableBrandingActivationResponse as boolean,
  };
};

export const enableCaptchaWaitingForServer = (
  isWaitingForEnableCaptchaServerResponse: boolean
) => {
  return {
    type: actionsNames.TOGGLE_ENABLE_CAPTCHA_WAITING_FOR_SERVER as string,
    payload: isWaitingForEnableCaptchaServerResponse as boolean,
  };
};

export const enableCaptchaSitekeyWaitingForServer = (
  isWaitingForCaptchaSitekeyServerResponse: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CAPTCHA_SITEKEY_WAITING_FOR_SERVER as string,
    payload: isWaitingForCaptchaSitekeyServerResponse as boolean,
  };
};

export const createWebsiteWaitingForServer = (
  isWaitingForCreateWebsiteResponse: boolean
) => {
  return {
    type: actionsNames.CREATE_WEBSITE_WAITING_FOR_SERVER as string,
    payload: isWaitingForCreateWebsiteResponse as boolean,
  };
};
export const createWebsiteErrorMessage = (
  createWebsiteErrorMessage: string | undefined
) => {
  return {
    type: actionsNames.CREATE_WEBSITE_ERROR_MESSAGE as string,
    payload: createWebsiteErrorMessage as string | undefined,
  };
};

export const cloneWebsiteWaitingForServer = (
  isWaitingForCloneWebsiteResponse: boolean
) => {
  return {
    type: actionsNames.CLONE_WEBSITE_WAITING_FOR_SERVER as string,
    payload: isWaitingForCloneWebsiteResponse as boolean,
  };
};
export const deleteWebsiteWaitingForServer = (
  isWaitingForDeleteWebsiteResponse: boolean
) => {
  return {
    type: actionsNames.DELETE_WEBSITE_WAITING_FOR_SERVER as string,
    payload: isWaitingForDeleteWebsiteResponse as boolean,
  };
};

export const removeWebsiteFromStore = (subdomain: string) => {
  return {
    type: actionsNames.REMOVE_WEBSITE_FROM_STORE as string,
    payload: subdomain as string,
  };
};

export const saveWebsiteNavInState = (settings: any) => {
  return {
    type: actionsNames.SAVE_WEBSITE_NAV_IN_STATE as string,
    payload: settings as any,
  };
};
export const changeWebsiteNavColor = (settings: any) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_NAV_COLOR as string,
    payload: settings as any,
  };
};
export const saveWebsiteFooterInState = (settings: any) => {
  return {
    type: actionsNames.SAVE_WEBSITE_FOOTER_IN_STATE as string,
    payload: settings as any,
  };
};
export const saveWebsiteCaptchaInState = (settings: any) => {
  return {
    type: actionsNames.SAVE_WEBSITE_CAPTCHA_IN_STATE as string,
    payload: settings as any,
  };
};

export const toggleWebsiteNavEditionsDetected = (
  websiteNavEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_NAV_CHANGES as string,
    payload: websiteNavEditionsExist as boolean,
  };
};
export const toggleWebsiteFooterEditionsDetected = (
  websiteFooterEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_FOOTER_CHANGES as string,
    payload: websiteFooterEditionsExist as boolean,
  };
};
export const toggleWebsiteCaptchaEditionsDetected = (
  websiteCaptchaEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_CAPTCHA_CHANGES as string,
    payload: websiteCaptchaEditionsExist as boolean,
  };
};
export const toggleWebsitePopupEditionsDetected = (
  websitePopupEditionsExist: boolean
) => {
  return {
    type: actionsNames.TOGGLE_DETECTED_POPUP_CHANGES as string,
    payload: websitePopupEditionsExist as boolean,
  };
};

export const toggleWaitingForResponseOnWebsiteNavEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.NAV_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForResponseOnWebsiteFooterEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.FOOTER_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForResponseOnWebsiteCaptchaEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.CAPTCHA_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForResponseOnWebsitePopupEditions = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.POPUP_CHANGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const toggleWaitingForResponseOnWebsiteFormIntegrationAdd = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_ADD_NEW_FORM_INTEGRATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

// START OF: payment integrations  =====
export const toggleWaitingForConnectPaymentIntegration = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CONNECT_PAYMENT_INTEGRATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForChangePaymentIntegrationProductsArray = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CHANGE_PAYMENT_INTEGRATION_PRODUCT_ARRAY_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForEditPaymentIntegrationProductDetails = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_EDIT_PAYMENT_INTEGRATION_PRODUCT_DETAILS_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
export const toggleWaitingForConfigurePaymentIntegration = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CONFIGURE_PAYMENT_INTEGRATION_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};
// ===== END OF: payment integrations

// START OF: Stripe actions =====
export const changeStripeSecretKey = (payload: any) => {
  return {
    type: actionsNames.CHANGE_STRIPE_SECRET_KEY as string,
    payload: payload as any,
  };
};
export const changeStripePublicKey = (payload: any) => {
  return {
    type: actionsNames.CHANGE_STRIPE_PUBLIC_KEY as string,
    payload: payload as any,
  };
};
export const changeStripeCheckoutProductsArray = (payload: any) => {
  return {
    type: actionsNames.CHANGE_STRIPE_CHECKOUT_PRODUCT_ARRAY as string,
    payload: payload as any,
  };
};
// ===== END OF: Stripe actions

export const saveWebsiteNewFormIntegrationInState = (settings: any) => {
  return {
    type: actionsNames.ADD_NEW_FORM_INTEGRATION as string,
    payload: settings as any,
  };
};
export const removeWebsiteFormIntegration = (settings: any) => {
  return {
    type: actionsNames.REMOVE_FORM_INTEGRATION as string,
    payload: settings as any,
  };
};

export const toggleWaitingForResponseOnColorPaletteChange = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CHANGE_COLOR_PALETTE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const toggleWaitingForResponseOnFontChange = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CHANGE_FONT_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const toggleWaitingForCommonWebsiteUpdate = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.COMMON_WEBSITE_UPDATE_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

// START OF: UI state
export const updateActiveDashboardTab = (activeDashboardTab: string) => {
  return {
    type: actionsNames.UPDATE_ACTIVE_DASHBOARD_TAB as string,
    payload: activeDashboardTab as string,
  };
};
// END OF: UI state

export const toggleWaitingForWebsiteLanguagesUpdate = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CHANGE_LANGUAGES_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export const saveNewPopupInState = (
  payload: SaveNewPopupInStatePayloadType
) => {
  return {
    type: actionsNames.ADD_NEW_POPUP as string,
    payload,
  };
};
export const deletePopup = (payload: DeletePopupPayloadType) => {
  return {
    type: actionsNames.DELETE_POPUP as string,
    payload,
  };
};
export const changePopupContent = (payload: ChangePopupContentType) => {
  return {
    type: actionsNames.CHANGE_POPUP_CONTENT as string,
    payload,
  };
};
export const changePopupBackgroundColor = (
  payload: ChangePopupBackgroundColorType
) => {
  return {
    type: actionsNames.CHANGE_POPUP_BACKGROUND_COLOR as string,
    payload,
  };
};
export const changePopupBackgroundImage = (
  payload: ChangePopupBackgroundImageType
) => {
  return {
    type: actionsNames.CHANGE_POPUP_BACKGROUND_IMAGE as string,
    payload,
  };
};
export const changePopupBackgroundActiveOption = (
  payload: ChangePopupBackgroundActiveOptionType
) => {
  return {
    type: actionsNames.CHANGE_POPUP_BACKGROUND_ACTIVE_OPTION as string,
    payload,
  };
};
export const changePopupBackgroundVideo = (
  payload: ChangePopupBackgroundVideoType
) => {
  return {
    type: actionsNames.CHANGE_POPUP_BACKGROUND_VIDEO as string,
    payload,
  };
};
export const changePopupBackgroundOverlayOpacity = (
  payload: ChangePopupBackgroundOverlayOpacityType
) => {
  return {
    type: actionsNames.CHANGE_POPUP_BACKGROUND_OVERLAY_OPACITY as string,
    payload,
  };
};
export const changePopupCustomBackgroundColor: ChangePopupCustomBackgroundColor =
  (payload) => {
    return {
      type: actionsNames.CHANGE_POPUP_CUSTOM_BACKGROUND_COLOR,
      payload,
    };
  };
export const changePopupData: ChangePopupData = (payload) => {
  return {
    type: actionsNames.CHANGE_POPUP_DATA,
    payload,
  };
};

export const toggleLoadedWebsitesData: ToggleLoadedWebsitesData = (data) => {
  return {
    type: actionsNames.TOGGLE_LOADED_WEBSITES_DATA,
    payload: data,
  };
};

export const editFillTemplateData: EditFillTemplateData = (payload) => {
  return {
    type: actionsNames.EDIT_FILL_TEMPLATE_DATA,
    payload,
  };
};

export const toggleIsWebsiteFromTemplateCreated = (payload) => {
  return {
    type: actionsNames.TOGGLE_IS_WEBSITE_FROM_TEMPLATE_CREATED,
    payload,
  };
};

export const toggleIsCreateWebhookWaitingForServer: ToggleIsCreateWebhookWaitingForServer =
  (payload) => {
    return {
      type: actionsNames.TOGGLE_IS_CREATE_WEBHOOK_WAITING_FOR_SERVER,
      payload,
    };
  };

export const changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData = (
  payload
) => {
  return {
    type: actionsNames.CHANGE_WEBSITES_STATE_GLOBAL_DATA,
    payload,
  };
};

export const editCustomStyles: EditCustomStyles = (payload) => {
  return {
    type: actionsNames.EDIT_CUSTOM_STYLES,
    payload,
  };
};

export const changeWebsiteItemGlobalData: ChangeWebsiteItemGlobalData = (
  payload
) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_ITEM_GLOBAL_DATA,
    payload,
  };
};

export const changeWebsiteNavIntegrationId: ChangeWebsiteNavIntegrationId = (
  payload
) => {
  return {
    type: actionsNames.CHANGE_WEBSITE_NAV_INTEGRATION_ID,
    payload,
  };
};

export const setFullWebsiteLoadStatus = (payload: any) => {
  return {
    type: actionsNames.SET_FULL_WEBSITE_LOAD_STATUS,
    payload,
  };
}
export const saveFullWebsiteToStore = (payload: any) => {
  return {
    type: actionsNames.SAVE_FULL_WEBSITE_TO_STORE,
    payload,
  };
}

export default actionsNames;
