import { WebsitesState } from "../store/websites/types";

interface Payload {
  path: string;
  websites: WebsitesState;
}
export const validatePath = (payload: Payload) => {
  const { path, websites } = payload;
  let pathString = path;
  if (!pathString || typeof pathString !== "string") {
    return false;
  }
  if (pathString.startsWith("/")) {
    pathString = pathString.slice(1);
  }
  let pathArray = pathString.split("/");
  if (pathArray.length < 3) {
    return false;
  }
  if (pathArray[1] !== "pages" && pathArray[1] !== "blog") {
    return false;
  }
  if (isNaN(parseInt(pathArray[2]))) {
    return false;
  }
  const subdomain = pathArray[0];
  const websiteExists = websites.items.some((website) => {
    return website.subdomain === subdomain;
  });
  if (!websiteExists) {
    return false;
  }
  return true;
};
