import React, { useEffect, useRef, useState } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import _ from "lodash";
import axios from "axios";
import classNames from "classnames";
import { isKeyValue } from "../../helpers/isKeyValue";
import { lowerCaseKeys } from "../../helpers/lowercaseKeys";
import { GetCurrentStoreData } from "../../store/websites/types";
import { apiUrlBase } from "../../data/urls";
import {
  DEFAULT_DIRECTORY_2_ITEM_HTML,
  DEFAULT_DIRECTORY_3_ITEM_HTML,
  DEFAULT_DIRECTORY_ITEM_HTML,
} from "../../data/constants";
import { convertToNumber } from "../../helpers/convertToNumber";
import { sortPrimitivesFn } from "../../helpers/sortPrimitivesFn";
import { unescapeCommas } from "../../helpers/unescapeCommas";
import { checkIfStringContainsProtocol } from "../../helpers/checkIfStringContainsProtocol";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { slugifyNew } from "../../helpers/strings/slugifyNew";
import DebouncedInput from "../../components/DebouncedInput";

const DEBOUNCE_ROWS_COUNT = 100

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
  getCurrentStoreData: GetCurrentStoreData;
}

interface ItemLoadingProps extends Props {}
const ItemLoading = (props: ItemLoadingProps) => {
  return <li className="directory-01__loading-item" />;
};

interface TagsProps extends Props {
  category: string;
  selectedDesplayedCategory: string;
  setCategory: (category: string) => void;
  setIsShowMoreActive: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
}
const Tag = (props: TagsProps) => {
  const {
    isDarkBg,
    category,
    selectedDesplayedCategory,
    setCategory,
    setIsShowMoreActive,
    searchValue,
  } = props;
  if (
    searchValue &&
    !category.toString().toLowerCase().includes(searchValue.toLowerCase())
  ) {
    return null;
  }

  return (
    <button
      className={classNames("directory-01__tag-button content-text def-14", {
        "is-selected": category === selectedDesplayedCategory,
      })}
      onClick={() => {
        setCategory(category);
        setIsShowMoreActive(true);
      }}
    >
      {category}
    </button>
  );
};

interface SearchBarProps extends Props {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setIsShowMoreActive: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: (category: string) => void;
  placeholder: string;
  rows: any[];
}
const SearchBar = (props: SearchBarProps) => {
  const {
    isDarkBg,
    searchValue,
    setSearchValue,
    setIsShowMoreActive,
    setCategory,
    placeholder,
    rows
  } = props;
  return (
    <DebouncedInput
      debounceDelay={rows.length > DEBOUNCE_ROWS_COUNT ? 300 : 1}
      placeholder={placeholder}
      className={classNames("directory-01__search-input text-input", {
        "text-input--white": isDarkBg,
      })}
      defaultValue={searchValue}
      onChange={(value) => {
        setSearchValue(value);
        setIsShowMoreActive(true);
        setCategory("All");
      }}
    />
  );
};

interface ShowMoreButtonProps extends Props {
  isShowMoreActive: boolean;
  setIsShowMoreActive: React.Dispatch<React.SetStateAction<boolean>>;
  showMoreButtonText: string;
}
const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const {
    isDarkBg,
    setIsShowMoreActive,
    isShowMoreActive,
    showMoreButtonText,
  } = props;
  if (isShowMoreActive) return null;
  return (
    <a
      className={classNames("button", {
        "button--white-bg": isDarkBg,
        "button--black-bg": !isDarkBg,
      })}
      onClick={(e) => {
        e.preventDefault();
        setIsShowMoreActive(true);
      }}
    >
      <span className="button__text">{showMoreButtonText || "Show all"}</span>
    </a>
  );
};

interface DirectoryItemProps extends Props {
  row: any;
  selectedCategory: string;
  isShowMoreHidden: boolean;
  searchValue: string;
  itemHtml: string;
  categoryColumn: string;
  dynamicUrl: string;
  dynamicTarget: string;
  dynamicUrlPrefix: string;
}
const DirectoryItem = (props: DirectoryItemProps) => {
  const {
    selectedCategory,
    isShowMoreHidden,
    searchValue,
    itemHtml,
    row,
    categoryColumn,
    dynamicUrl,
    dynamicTarget,
    dynamicUrlPrefix,
    getCurrentStoreData,
  } = props;
  const category = row[categoryColumn];
  const itemRef = useRef(null);
  const { currentWebsite } = getCurrentStoreData();

  function replaceWithObjectValues(html) {
    return html.replace(/\{\{(\w+)\}\}/g, function (match, p1) {
      let field = p1.toLowerCase();
      const isDynamicUrl = field === "dynamicurl";
      const isDynamicTarget = field === "dynamictarget";

      if (isDynamicUrl) {
        field = dynamicUrl;
      }

      let value = row[field];

      if (isDynamicUrl && !checkIfStringContainsProtocol(value)) {
        value = slugifyNew(value);
        if (!value.startsWith("/")) {
          value = `/${value}`;
        }
        if (dynamicUrlPrefix && dynamicUrlPrefix !== "no_prefix") {
          value = `/${dynamicUrlPrefix}${value}`;
        }
        value = "https://" + getActiveDomain(currentWebsite) + value;
        if (
          (field || "").toString().startsWith("{{") ||
          (dynamicUrlPrefix || "").toString().startsWith("{{")
        ) {
          value = "https://" + getActiveDomain(currentWebsite);
        }
      }

      if (isDynamicTarget) {
        value = "_blank";
        if (dynamicTarget === "same_tab") {
          value = "_self";
        }
      }

      return value;
    });
  }

  const parentClassName = Object.keys(row).reduce((acc, key) => {
    const rowValue = (row[key] || "").toString().trim();
    if (!!rowValue && rowValue.toString().toLowerCase() !== "false") {
      return `${acc} dir-has-${key.toString().trim().replace(/ /g, "-")}`;
    }
    return acc;
  }, "directory-item-parent");

  const getIsItemHidden = () => {
    if (!itemRef.current) return false;
    if (
      selectedCategory !== "All" &&
      categoryColumn !== "no_tags" &&
      !category
        .toString()
        .split(";")
        .map((t) => t.trim())
        .includes(selectedCategory)
    ) {
      return true;
    }

    if (
      searchValue &&
      itemRef.current.textContent &&
      itemRef.current.textContent.toLowerCase &&
      !itemRef.current.textContent
        .toLowerCase()
        .includes(searchValue.toLowerCase()) &&
      (categoryColumn === "no_tags" ||
        !category ||
        !category.toString().toLowerCase().includes(searchValue.toLowerCase()))
    ) {
      return true;
    }
    return false;
  };

  return (
    <li
      style={{
        display: isShowMoreHidden || getIsItemHidden() ? "none" : "block",
      }}
      ref={itemRef}
      dangerouslySetInnerHTML={{
        __html: replaceWithObjectValues(itemHtml),
      }}
      className={parentClassName}
      data-category={categoryColumn === "no_tags" ? "No tags" : category}
    />
  );
};

const Directory01 = (props: Props) => {
  const {
    componentItem,
    checkIsEmptyContent,
    saveContentInStore,
    isDarkBg,
    getCurrentStoreData,
  } = props;
  const generator = new generateContentDataObject();

  const isMountedRef = useRef(false);
  const fullRowsRef = useRef([]);
  const fullTagsRef = useRef([]);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [isShowMoreActive, setIsShowMoreActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedDesplayedCategory, setSelectedDisplayedCategory] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [tags, setTags] = useState([]);
  const [randomizedRows, setRandomizedRows] = useState([]);

  const setCategory = (category: string) => {
    setSelectedDisplayedCategory(category);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setSelectedCategory(category);
    }, rows.length > DEBOUNCE_ROWS_COUNT ? 500 : 1);
  };

  if (!isMountedRef.current) {
    let defaultHtml = DEFAULT_DIRECTORY_ITEM_HTML;
    if (componentItem.title === "02") {
      defaultHtml = DEFAULT_DIRECTORY_2_ITEM_HTML;
    }
    if (componentItem.title === "03") {
      defaultHtml = DEFAULT_DIRECTORY_3_ITEM_HTML;
    }
    const { isDefaultEmpty } = componentItem;
    if (checkIsEmptyContent()) {
      saveContentInStore(generator.setUpTitle({ isDefaultEmpty }));
      saveContentInStore(generator.setUpSubtitleText({ isDefaultEmpty }));
      saveContentInStore(
        generator.setUpDirectory({
          isDefaultEmpty,
          directoryItemHtml: defaultHtml,
        })
      );
    }
  }

  const replaceUrlVariables = (text: string) => {
    if (typeof text !== "string") return text;
    const { currentWebsite } = getCurrentStoreData();
    const variables = {
      "{{subdomain}}": _.get(currentWebsite, "subdomain"),
      "{{apiBase}}": apiUrlBase,
      "{{website_id}}": _.get(currentWebsite, "id"),
      "{{website_domain}}": getActiveDomain(currentWebsite),
    };
    return text.replace(/{{subdomain}}|{{apiBase}}|{{website_id}}|{{website_domain}}/g, (matched) => {
      return variables[matched];
    });
  };

  const title = displayTitleMarkupText(
    componentItem.componentData.title.content.markup
  );
  const subtitleText = displaySubtitleMarkupText(
    componentItem.componentData.subtitleText.content.markup
  );
  const cmsUrl = _.get(
    componentItem,
    "componentData.directory.content.cmsUrl",
    ""
  );
  const maxItems = parseInt(
    _.get(componentItem, "componentData.directory.content.maxItems") || 4
  );
  const showMoreButtonText = _.get(
    componentItem,
    "componentData.directory.content.showMoreButtonText",
    ""
  );
  const itemHtml = _.get(
    componentItem,
    "componentData.directory.content.directoryItemHtml",
    ""
  );
  const sortingField = _.get(
    componentItem,
    "componentData.directory.content.sortingField",
    ""
  );
  const searchPlaceholder = _.get(
    componentItem,
    "componentData.directory.content.searchPlaceholder",
    ""
  );
  const filterFields = _.get(
    componentItem,
    "componentData.directory.content.filterFields",
    ""
  );
  const filteredTags = _.get(
    componentItem,
    "componentData.directory.content.filteredTags",
    ""
  )
    .split(";")
    .map((t) => t.trim())
    .filter((tag) => tag);
  const categoryColumn = _.get(
    componentItem,
    "componentData.directory.content.categoryColumn",
    "category"
  );
  const filteredTagsMode = _.get(
    componentItem,
    "componentData.directory.content.filteredTagsMode",
    "all"
  );
  const filterFieldsMode = _.get(
    componentItem,
    "componentData.directory.content.filterFieldsMode",
    "all"
  );
  const sortingOrder = _.get(
    componentItem,
    "componentData.directory.content.sortingOrder",
    "ascending"
  );
  const isTagsHidden = _.get(
    componentItem,
    "componentData.directory.content.isTagsHidden",
    false
  );
  const hasFeatureHideButton =
    _.get(
      componentItem,
      "componentData.directory.content.versions.button",
      1
    ) >= 2;
  const dynamicUrl = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrl"
  );
  const dynamicTarget = _.get(
    componentItem,
    "componentData.directory.content.dynamicTarget"
  );
  const dynamicUrlPrefix = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrlPrefix"
  );

  const getSearchBarPlaceholder = () => {
    if (searchPlaceholder)
      return searchPlaceholder.replace(/\{\{amount\}\}/g, rows.length);
    return "";
  };

  const formatGoogleSheetUrl = (url: string) => {
    if (typeof url !== "string") {
      return url;
    }
    if (!url.startsWith("https://docs.google.com/spreadsheets/")) {
      return url;
    }
    const outputParam = "output=csv";
    return url
      .replace("pubhtml", `pub?${outputParam}`)
      .replace("output=tsv", outputParam)
      .replace("output=pdf", outputParam)
      .replace("output=xlsx", outputParam)
      .replace("output=ods", outputParam);
  };

  const filterRowsByCategoryFn = (row) => {
    const displayedFilteredTags = filteredTags.filter(
      (tag) => !tag.toString().startsWith("{{$")
    );
    if (categoryColumn === "no_tags") return true;
    if (displayedFilteredTags.length === 0) return true;
    const category = row[categoryColumn];
    if (!category) {
      return false;
    }
    const categoryArray = category
      .toString()
      .split(";")
      .map((t) => t.trim());

    if (filteredTagsMode === "all") {
      return displayedFilteredTags.every((t) => {
        return categoryArray.includes(t);
      });
    }

    return categoryArray.some((t) => {
      return displayedFilteredTags.includes(t);
    });
  };

  const filterRowsByFieldsFn = (row) => {
    const checkFilterFieldsFn = (filterField) => {
      let isNegative = false;
      let field = filterField;
      if (filterField.startsWith("-")) {
        field = filterField.slice(1);
        isNegative = true;
      }
      if (!(field in row)) return true;
      const value = (row[field] || "").toString().trim();
      if (!value) return isNegative;
      if (value.toLowerCase() === "false") return isNegative;
      return !isNegative;
    };
    if (typeof filterFields !== "string" || !filterFields.trim()) {
      return true;
    }
    const filterFieldsArray = filterFields.split(";").map((t) => t.trim());
    if (filterFieldsMode === "any") {
      return filterFieldsArray.some(checkFilterFieldsFn);
    }
    return filterFieldsArray.every(checkFilterFieldsFn);
  };

  const filterRowsGeneralFn = (row) => {
    return filterRowsByCategoryFn(row) && filterRowsByFieldsFn(row);
  };

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  useEffect(() => {
    setIsShowMoreActive(false);
    setCategory("All");
    setSearchValue("");
  }, [maxItems, showMoreButtonText]);

  useEffect(() => {
    setRows([...fullRowsRef.current].filter(filterRowsGeneralFn));
    setCategory("All");
  }, [filterFields, filteredTagsMode, filterFieldsMode]);

  useEffect(() => {
    setIsShowMoreActive(false);
    let categories = [];
    setRows([]);
    fullRowsRef.current = [];
    setIsLoading(false);
    const formattedCmsUrl = formatGoogleSheetUrl(replaceUrlVariables(cmsUrl));
    if (
      !cmsUrl ||
      !(
        formattedCmsUrl.startsWith("https://") ||
        formattedCmsUrl.startsWith("http://")
      )
    ) {
      return;
    }
    setIsLoading(true);
    axios
      .get(formattedCmsUrl)
      .then((response) => {
        let rows = [];

        let type = "text";
        if (Array.isArray(response.data)) {
          type = "jsonArray";
        }
        if (isKeyValue(response.data)) {
          type = "jsonObject";
        }

        let data = response.data;

        if (type === "jsonArray") {
          rows = data.map((row) => {
            return lowerCaseKeys(row);
          });
        } else if (type === "jsonObject") {
          rows = [lowerCaseKeys(data)];
        } else {
          rows = window.Papa.parse(data, {
            header: true,
          }).data.map((row) => {
            return lowerCaseKeys(unescapeCommas(row));
          });
        }

        rows.forEach((row) => {
          if ("title" in row) {
            row.slugified_title = slugifyNew(row.title);
          }
        });

        fullRowsRef.current = rows;

        saveContentInStore(
          generator.changeDirectoryAvailableColumns(
            componentItem.componentData,
            Object.keys(rows[0] || {})
          )
        );

        const categoriesArray = [];
        if (categoryColumn !== "no_tags") {
          fullRowsRef.current.forEach((row, i) => {
            const category = row[categoryColumn];
            if (!category) return;
            categoriesArray.push(
              ...category
                .toString()
                .split(";")
                .map((t) => t.trim())
            );
          });
        }
        categories = [...[...new Set(categoriesArray)].sort(sortPrimitivesFn)];
        fullTagsRef.current = categories;

        saveContentInStore(
          generator.changeDirectoryFilteredTags(
            componentItem.componentData,
            filteredTags
          )
        );
        saveContentInStore(
          generator.changeDirectoryTags(componentItem.componentData, categories)
        );
        if (filteredTags.length > 0) {
          categories = categories.filter((tag) => filteredTags.includes(tag));
        }

        rows = rows.filter(filterRowsGeneralFn);
        setTags(categories);
        setRows(rows);
        setIsLoading(false);
        saveContentInStore(
          generator.changeDirectoryCurrentRowsSample(
            componentItem.componentData,
            rows.slice(0, 10)
          )
        );
      })
      .catch((error) => {
        console.error(error);
        saveContentInStore(
          generator.changeDirectoryFilteredTags(componentItem.componentData, [])
        );
        setRows([]);
      });
  }, [cmsUrl]);

  useEffect(() => {
    setCategory("All");
    if (filteredTags.length === 0) {
      setRows(fullRowsRef.current.filter(filterRowsGeneralFn));
      setTags(fullTagsRef.current);
      return;
    }
    const categories = fullTagsRef.current.filter((tag) => {
      return filteredTags.includes(tag);
    });
    setTags(categories);
    setRows(fullRowsRef.current.filter(filterRowsGeneralFn));
  }, [filteredTags.length]);

  useEffect(() => {
    if (fullRowsRef.current.length === 0) return;

    const categoriesArray = [];
    if (categoryColumn !== "no_tags") {
      fullRowsRef.current.forEach((row, i) => {
        const category = row[categoryColumn];
        if (!category) return;
        categoriesArray.push(
          ...category
            .toString()
            .split(";")
            .map((t) => t.trim())
        );
      });
    }
    const categories = [
      ...[...new Set(categoriesArray)].sort(sortPrimitivesFn),
    ];
    fullTagsRef.current = categories;
    setTags(categories);
    saveContentInStore(
      generator.changeDirectoryTags(componentItem.componentData, categories)
    );
    setCategory("All");
  }, [categoryColumn]);

  useEffect(() => {
    setRandomizedRows([...rows].sort(() => Math.random() - 0.5));
  }, [rows.length]);

  const usedTagsCount =
    categoryColumn === "no_tags"
      ? 0
      : tags.filter((tag) => {
          return rows.some((row) => {
            if (!row[categoryColumn]) return false;
            return row[categoryColumn]
              .toString()
              .split(";")
              .map((t) => t.trim())
              .includes(tag);
          });
        }).length;

  return (
    <div
      className={classNames("directory-01", {
        "dir-is-loading": isLoading,
        "directory-02": componentItem.title === "02",
        "directory-03": componentItem.title === "03",
        "directory-01--tags-hidden":
          isTagsHidden ||
          tags.length <= 0 ||
          rows.length <= 0 ||
          usedTagsCount <= 1,
        "directory-01--button-hidden":
          !showMoreButtonText && hasFeatureHideButton,
        "directory-01--search-hidden": !searchPlaceholder,
      })}
    >
      <div className="directory-01__box">
        <div className="container container--large directory-01__container">
          <div className="directory-01__text_box title-box title-box--center">
            {title.length > 0 && (
              <h1
                className={
                  "title-text heading heading--accent directory-01__heading " +
                  (isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {subtitleText.length > 0 && (
              <div
                className={
                  "subtitle-text directory-01__subtitle content_box title-box__text " +
                  (isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: subtitleText }}
              />
            )}
          </div>

          <div className="directory-01__parent-container">
            <div className="directory-01__search-container">
              {rows.length > 0 && getSearchBarPlaceholder() && (
                <div className="directory-01__search-box">
                  <SearchBar
                    {...props}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setIsShowMoreActive={setIsShowMoreActive}
                    setCategory={setCategory}
                    placeholder={getSearchBarPlaceholder()}
                    rows={rows}
                  />
                </div>
              )}
              {!isTagsHidden && rows.length > 0 && usedTagsCount > 1 && (
                <div className="directory-01__tags-box">
                  <Tag
                    category={"All"}
                    {...props}
                    key={"all"}
                    selectedDesplayedCategory={selectedDesplayedCategory}
                    setCategory={setCategory}
                    setIsShowMoreActive={setIsShowMoreActive}
                    searchValue={searchValue}
                  />
                  {categoryColumn !== "no_tags" &&
                    tags.map((category, i) => {
                      if (
                        !rows.some(
                          (row) =>
                            row[categoryColumn] &&
                            row[categoryColumn]
                              .toString()
                              .split(";")
                              .map((t) => t.trim())
                              .includes(category)
                        )
                      ) {
                        return null;
                      }
                      return (
                        <Tag
                          category={category}
                          {...props}
                          key={i}
                          selectedDesplayedCategory={selectedDesplayedCategory}
                          setCategory={setCategory}
                          setIsShowMoreActive={setIsShowMoreActive}
                          searchValue={searchValue}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            <div className="directory-01__bottom-container">
              {isLoading && (
                <ul className="directory-01__items">
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                  <ItemLoading {...props} />
                </ul>
              )}
              {rows.length > 0 && (
                <ul className="directory-01__items">
                  {[
                    ...(sortingField === "random_sorting"
                      ? randomizedRows
                      : rows),
                  ]
                    .sort((a, b) => {
                      if (!sortingField) return 0;
                      if (
                        sortingField === "no_sorting" ||
                        sortingField === "random_sorting"
                      ) {
                        return 0;
                      }
                      if (!(typeof sortingField === "string")) return 0;
                      let field = sortingField.toLowerCase();
                      let isNegative = false;
                      if (sortingField.startsWith("-")) {
                        field = sortingField.slice(1).toLowerCase();
                        isNegative = true;
                      }
                      if (sortingOrder === "descending") {
                        isNegative = !isNegative;
                      }
                      if (!Object.keys(a).includes(field)) return 0;
                      if (!Object.keys(b).includes(field)) return 0;
                      let aValue = a[field];
                      let bValue = b[field];
                      const isNumbers =
                        !isNaN(convertToNumber(aValue)) &&
                        !isNaN(convertToNumber(bValue));

                      if (isNumbers) {
                        aValue = convertToNumber(aValue);
                        bValue = convertToNumber(bValue);
                        if (isNegative) {
                          return bValue - aValue;
                        }
                        return aValue - bValue;
                      }
                      if (isNegative) {
                        return bValue.toString().localeCompare(aValue);
                      }
                      return aValue.toString().localeCompare(bValue);
                    })
                    .map((row, i) => {
                      let isShowMoreHidden = false;
                      if (
                        i >= (isNaN(maxItems) ? 4 : maxItems) &&
                        !isShowMoreActive
                      ) {
                        isShowMoreHidden = true;
                      }
                      return (
                        <DirectoryItem
                          {...props}
                          key={i}
                          row={row}
                          selectedCategory={selectedCategory}
                          isShowMoreHidden={isShowMoreHidden}
                          searchValue={searchValue}
                          itemHtml={itemHtml}
                          categoryColumn={categoryColumn}
                          dynamicUrl={dynamicUrl}
                          dynamicTarget={dynamicTarget}
                          dynamicUrlPrefix={dynamicUrlPrefix}
                        />
                      );
                    })}
                </ul>
              )}
              {rows.length > 0 &&
                (showMoreButtonText || !hasFeatureHideButton) &&
                !isShowMoreActive &&
                (isNaN(maxItems) ? 4 : maxItems) < rows.length && (
                  <div className="directory-01__show-more-box">
                    <ShowMoreButton
                      {...props}
                      isShowMoreActive={isShowMoreActive}
                      setIsShowMoreActive={setIsShowMoreActive}
                      showMoreButtonText={showMoreButtonText}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directory01;
