import React from "react";
import "./SkeletonLoader.scss";

interface Props {
  width?: string;
  height?: string;
}

const SkeletonLoader = (props: Props): JSX.Element => {
  const {} = props;

  return (
    <div
      className="skeleton-loader"
      style={{
        width: props.width || "100%",
        height: props.height || "10px",
      }}
    />
  );
};

export default SkeletonLoader;
