import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";
const { TextArea } = Input;

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    user: any,
    auth: any,
    toggleWaitingForCommonBlogPostUpdate: any,
    currentWebsite: any,
    currentBlog: any,
    currentBlogPost:any,
    saveBlogPostDataInStore: any
    saveWebsiteBackup: any;
}
type MyState = { current_custom_html_code: string };
class AddPageHeadCustomCodeForm extends Component<Props, MyState>{
    componentWillMount(): void {
        // We want to set up an initial value of a website custom_head_html_code.
        this.setState({current_custom_html_code: this.props.currentBlogPost.custom_head_html_code});
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or blog or blog post.
        // We want to set up an initial value of a new picked blog post.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain)
        ||
        (this.props.currentBlog.id !== nextProps.currentBlog.id)
        ||
        (this.props.currentBlogPost.url !== nextProps.currentBlogPost.url)
        ){
            this.setState({current_custom_html_code: nextProps.currentBlogPost.custom_head_html_code});
        }
    }

    handlePageHeadCustomCodeUpdate = async (e:any) => {
        e.preventDefault();

        this.props.toggleWaitingForCommonBlogPostUpdate(true);

        // try {
        //   await this.props.saveWebsiteBackup({ is_blog: true, has_blog_post_head_code: true, blog_post_id: _.get(this.props.currentBlogPost, "id") });
        // } catch (e) {
        //   console.error(_.get(e, "response.data"));
        // }


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newPostCustomCode = this.state.current_custom_html_code;

        let blogPostUrlToServer = this.props.currentBlogPost.url;

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogPosts.updateBlogPost.prefix + this.props.currentWebsite.subdomain + '/' + blogPostUrlToServer + api.blogPosts.updateBlogPost.postfix;
        axios.patch(apiUrl, {custom_head_html_code: newPostCustomCode, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
                // Server gives the object of the saved website page. Throw it in the store.
                this.props.saveBlogPostDataInStore(response, this.props.currentBlog, this.props.blogPosts.items, this.props.currentBlogPost.url);

                let successMessage = <span> <code>{trimStringTo(newPostCustomCode, 50)}</code> added to the {blogPostUrlToServer} post.</span>;
                if(newPostCustomCode === ''){
                    successMessage = <span>Successfully removed the code.</span>;
                }
                
                fireAnalyticsEvent.fireCrisp(CrispEvents.changePostHeadCustomCode, {
                  subdomain: this.props.currentWebsite.subdomain,
                  post_url: response.data.url,
                  code: trimStringTo(newPostCustomCode, 100),
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePostHeadCustomCodeError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          page_url: blogPostUrlToServer,
                          code: trimStringTo(newPostCustomCode, 100),
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.current_custom_html_code !== undefined){
                        let message = errorData.current_custom_html_code.join(' ');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleWaitingForCommonBlogPostUpdate(false);
            });
    };

    handleInputChange = (e:any) => {
        this.setState({current_custom_html_code: e.target.value});
    };

    render(){
        let returnCustomCodeField = () => {
            // Only pro users can upgrade to custom domains
            // if(this.props.user.isPro){
            return (
                <div className="settings-box__input_box settings-box__input_box--large">
                        <TextArea
                            name="custom_head_html_code"
                            id="custom_head_html_code"
                            required={false}
                            value={this.state.current_custom_html_code}
                            placeholder='<!-- Event snippet for Submit lead form conversion page --> <script> gtag("event", "conversion", {"send_to": "AW-996845408/few_CA6e1w2BENAgrLAz"}); </script>'
                            onChange={this.handleInputChange}
                            autosize={{ minRows: 4, maxRows: 30 }}
                            style={{fontFamily: 'monospace'}}
                        />
                    <div className="settings-box__error_message" style={{opacity: this.props.blogs.errorMessage === undefined ? 0 : 1}}>
                        {this.props.blogPosts.errorMessage}
                    </div>
                </div>
            );
            // }
            // else{
            //     return (
            {/*<Button onClick={this.handleUpgradeToUnlock} icon='star' type="primary" size="large" htmlType="button">Upgrade to unlock</Button>*/}
            // )
            // }
        };

        // We hide the save button in 2 cases:
        // 1) when the form has not yet received its initial value (and the initial value === undefined)
        // 2) when the initial value is the same as the current value (e.g. nothing to save)
        let isSaveButtonVisible = this.state.current_custom_html_code !== undefined && (compareEditedValueWithCurrent(this.props.currentBlogPost.custom_head_html_code, this.state.current_custom_html_code) === false);

        return (
            <form onSubmit={this.handlePageHeadCustomCodeUpdate} className="settings-box__form">
                {returnCustomCodeField()}
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogPosts.isWaitingForCommonBlogPostUpdateResponse}
                    disabled={this.props.blogPosts.isWaitingForCommonBlogPostUpdateResponse}
                    htmlType="submit"
                    hidden={!isSaveButtonVisible}
                />
            </form>
        )
    }
}

export default AddPageHeadCustomCodeForm;
